import React, {useState} from 'react';
import {useLoadsData} from "../../../../Hooks/cleverTms/LoadController";
import LoadControllerTable from "./LoadControllerTable/LoadControllerTable";
import {SearchResultForFindDriverName} from "../UtilsTMS/SearchResults";
import SearchOptions from "../../../../Utils/SearchOptions";
import dayjs from "dayjs";
import {Button, DatePicker} from "antd";
import {Link} from "react-router-dom";

type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
}
const {RangePicker} = DatePicker;
const LoadController = () => {

    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date().setHours(24),
            key: "selection"

        }
    ]);
    const [skip, setSkip] = useState<string | number>(1);
    const [orderNumber, setOrderNumber] = useState<number | string | undefined>('');

    const {data}: Data = useLoadsData(orderNumber, date, skip)

    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)));
    }

    return (
        <div>
             <span style={{display: 'flex', alignItems: 'center'}}>
            <SearchOptions SearchResult={SearchResultForFindDriverName}
                           onSelect={(value: any, {valId}: { valId: number | undefined }) => {
                               setOrderNumber(valId);
                           }} placeholder="Order Number Search" value={''}/>
            <RangePicker onChange={(value: any) => {
                setDate(value.map((item: any) => {
                    return dayjs(item).format('MM-DD-YYYY')
                }))

            }}/>
                 <div  style={{marginLeft: 'auto'}}>
                         <Link to={'add-load'}>
                    <Button type="primary" style={{float: "right"}} size={'large'}
                    >
                        ADD Load
                    </Button>
                </Link>
                 </div>

             </span>

            <LoadControllerTable controller={data?.data} total={data?.count} onChange={onChange} skip={skip}/>
        </div>
    );
};

export default LoadController;