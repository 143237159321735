import moment from "moment";
import createDecorator from 'final-form-calculate';
import {notification} from "antd";

export const usStates: { id: string, name: string }[] = [
    {id: "Alabama", name: "Alabama"},
    {id: "Alaska", name: "Alaska"},
    {id: "Arizona", name: "Arizona"},
    {id: "Arkansas", name: "Arkansas"},
    {id: "California", name: "California"},
    {id: "Colorado", name: "Colorado"},
    {id: "Connecticut", name: "Connecticut"},
    {id: "Delaware", name: "Delaware"},
    {id: "Florida", name: "Florida"},
    {id: "Georgia", name: "Georgia"},
    {id: "Hawaii", name: "Hawaii"},
    {id: "Idaho", name: "Idaho"},
    {id: "Illinois", name: "Illinois"},
    {id: "Indiana", name: "Indiana"},
    {id: "Iowa", name: "Iowa"},
    {id: "Kansas", name: "Kansas"},
    {id: "Kentucky", name: "Kentucky"},
    {id: "Louisiana", name: "Louisiana"},
    {id: "Maine", name: "Maine"},
    {id: "Maryland", name: "Maryland"},
    {id: "Massachusetts", name: "Massachusetts"},
    {id: "Michigan", name: "Michigan"},
    {id: "Minnesota", name: "Minnesota"},
    {id: "Mississippi", name: "Mississippi"},
    {id: "Missouri", name: "Missouri"},
    {id: "Montana", name: "Montana"},
    {id: "Nebraska", name: "Nebraska"},
    {id: "Nevada", name: "Nevada"},
    {id: "New Hampshire", name: "New Hampshire"},
    {id: "New Jersey", name: "New Jersey"},
    {id: "New Mexico", name: "New Mexico"},
    {id: "New York", name: "New York"},
    {id: "North Carolina", name: "North Carolina"},
    {id: "North Dakota", name: "North Dakota"},
    {id: "Ohio", name: "Ohio"},
    {id: "Oklahoma", name: "Oklahoma"},
    {id: "Oregon", name: "Oregon"},
    {id: "Pennsylvania", name: "Pennsylvania"},
    {id: "Rhode Island", name: "Rhode Island"},
    {id: "South Carolina", name: "South Carolina"},
    {id: "South Dakota", name: "South Dakota"},
    {id: "Tennessee", name: "Tennessee"},
    {id: "Texas", name: "Texas"},
    {id: "Utah", name: "Utah"},
    {id: "Vermont", name: "Vermont"},
    {id: "Virginia", name: "Virginia"},
    {id: "Washington", name: "Washington"},
    {id: "West Virginia", name: "West Virginia"},
    {id: "Wisconsin", name: "Wisconsin"},
    {id: "Wyoming", name: "Wyoming"},
];
export const configs: { label: string, case: string, title: string }[] = [
    {label: 'pro_features', case: 'dispatcher', title: '',},
    {label: 'report_features', case: 'dispatcher', title: '',},
    {label: 'safe_pro_features', case: 'dispatcher', title: '',},
    {label: 'pro_features_info', case: 'dispatcher', title: '',},
    {label: 'offline_disabled', case: 'dispatcher', title: '',},
    {label: 'allow_correction', case: 'dispatcher', title: '',},
    {label: 'allow_normalize', case: 'dispatcher', title: '',},
    {label: 'allow_normalize_new', case: 'dispatcher', title: '',},
    {label: 'allow_direct_edit', case: 'dispatcher', title: '',},
    {label: 'log_note_required', case: 'dispatcher', title: '',},
    {label: 'enable_errors', case: 'dispatcher', title: '',},
    {label: 'enable_edit_info', case: 'dispatcher', title: '',},
    {label: 'show_offline_accept_dialog', case: 'dispatcher', title: '',},
    {label: 'reassign', case: 'dispatcher', title: '',},
    {label: 'transfer_to_current', case: 'dispatcher', title: '',},
    {label: 'check_dot', case: 'dispatcher', title: '',},
    {label: 'allow_dispatcher_accept', case: 'dispatcher', title: '',},
    {label: 'swap_transfer', case: 'dispatcher', title: '',},

    {label: 'sidebar_chat', case: 'dispatcher', title: '',},
    {label: 'sidebar_ifta_report', case: 'dispatcher', title: '',},
    {label: 'sidebar_fmcsa_report', case: 'dispatcher', title: '',},
    {label: 'fmcsa_new_report_button', case: 'dispatcher', title: '',},
    {label: 'sidebar_activity_report', case: 'dispatcher', title: '',},
    {label: 'sidebar_driver_report', case: 'dispatcher', title: '',},
    {label: 'sidebar_users', case: 'dispatcher', title: '',},
    {label: 'sidebar_trackings', case: 'dispatcher', title: '',},
    {label: 'log_swdr', case: 'dispatcher', title: '',},
    {label: 'normalize_engine_hours', case: 'dispatcher', title: '',},
    {label: 'subscription', case: 'dispatcher', title: '',},
    {label: 'ifta_warning', case: 'dispatcher', title: '',},
    {label: 'team_ehf', case: 'dispatcher', title: 'Team ehf',},
    {label: 'fmcsa_view', case: 'dispatcher', title: 'FMCSA view',},

    {label: 'appversion_exception', case: 'driver', title: '',},
    {label: 'intermediate_worker', case: 'driver', title: '',},
    {label: 'intermediate_worker_disabled', case: 'driver', title: '',},
    {label: 'intermediate_periodic_disabled', case: 'driver', title: '',},
    {label: 'unidentified_enabled', case: 'driver', title: '',},
    {label: 'eld_location_enabled', case: 'driver', title: '',},
    {label: 'eld_location_enabled2', case: 'driver', title: '',},
    {label: 'firmware_update_enabled', case: 'driver', title: '',},
    {label: 'app_update_enabled', case: 'driver', title: '',},
    {label: 'telegram_logging', case: 'driver', title: '',},
    {label: 'driving_speed_reset_eld', case: 'driver', title: '',},
    {label: 'odometer_reset_eld', case: 'driver', title: '',},
    {label: 'engine_reset_eld', case: 'driver', title: '',},
    {label: 'location_reset_eld', case: 'driver', title: '',},
    {label: 'gps_speed_enabled', case: 'driver', title: '',},
    {label: 'smart_switch_coordinate', case: 'driver', title: '',},
    {label: 'pt_gps_speed_enabled', case: 'driver', title: '',},
    {label: 'log_note_not_required', case: 'driver', title: '',},
    {label: 'log_edit_disabled_new', case: 'driver', title: '',},
    {label: 'google_geocode_enabled', case: 'driver', title: '',},
    {label: 'unidentified', case: 'driver', title: 'to send unidentified logs to the server',},
    {label: 'geo_code', case: 'driver', title: 'address name from local database',},
    {label: 'eld_fuel_record', case: 'driver', title: '',},
    {label: 'engine_parameters', case: 'driver', title: '',},
    {label: 'auto_engine', case: 'driver', title: '',},


    {label: 'enabledDTC', case: 'driver', title: '',},
    {label: 'disabledFuel', case: 'driver', title: '',},
    {label: 'disabledDiagnosticParam', case: 'driver', title: '',},
    {label: 'disabledDriverBehavior', case: 'driver', title: '',},
    {label: 'disabledEngineParam', case: 'driver', title: '',},
    {label: 'disabledEmission', case: 'driver', title: '',},
    {label: 'disabledTransmissionParam', case: 'driver', title: '',},
    {label: 'rotation_enabled', case: 'driver', title: '',},
    {label: 'disabledOffline', case: 'driver', title: '',},
    {label: 'serverUpdate', case: 'driver', title: '',},
    {label: 'useCache', case: 'driver', title: 'App refresh date brings last log',},
    {
        label: 'online_time',
        case: 'driver',
        title: 'Logs and timers on HomePage are displayed online when there is internet. If there is any error from the internet or the server, it will show the user by counting the timer with the logs in the database.',
    },
    {
        label: 'online_log',
        case: 'driver',
        title: 'Logs and timers on HomePage are displayed online when there is internet. If there is any error from the internet or the server, it will show the user by counting the timer with the logs in the database.',
    },
]

export const certifyStatus = [
    {id: "regular", name: "Regular"},
    {id: "certify_on_pti", name: "Certify on PTI"},
    {id: "force_certify_on_pti", name: "Force certify on PTI"},
]

export const coordinatesSource = [
    {id: "fused", name: "Fused"},
    {id: "eld", name: "ELD"},
    {id: "gps", name: "GPS"},
];

export const configNumberInputs = [
    'tracking_frequency',
    'event_filtering_time',
    'allocated_space_device',
    'stationary_time',
    'countdown_time',
    'gps_tracking_frequency',
    'zero_speed_time',
    'update_distance',
    'chat_update',
    'min_distance',
    'networkCheckTime',
    'lastAppUsedCheckTime'
]
export const inputNumber = [
    {
        label: 'getLastLog',
        title: 'Getlastlog sends a request from getlastlog at the specified time to check for updates from this server, if the refresh date in getlastlog is greater than the last received refresh date, it downloads logs from the server.',
        defaultValue: 30
    },
    {
        label: 'intermediate_time',
        title: 'Intermediate time determines how often this intermediate will drop, for example, 3600 summer intermediate will drop every 1 hour. If you put 1800, it will cost every 30 minutes, this is for feature testers',
        defaultValue: ''
    },
    {label: 'logLimit', title: 'Sends offline logs separately', defaultValue: 20},
    {label: 'driving_speed', title: '', defaultValue: ''},
]


export const plainOptions: any = [
    // 'enabledDTC',
    // 'enabledFuel',
    // 'enabledDiagnosticParam',
    // 'enabledDriverBehavior',
    // 'enabledEngineParam',
    // 'enabledEmission',
    // 'enabledTransmissionParam',
    // 'enabledAllParam',
    {label: 'enabledDTC', value: 'enabledDTC'},
    {label: 'enabledFuel', value: 'enabledFuel'},
    {label: 'enabledDiagnosticParam', value: 'enabledDiagnosticParam'},
    {label: 'enabledDriverBehavior', value: 'enabledDriverBehavior'},
    {label: 'enabledEngineParam', value: 'enabledEngineParam'},
    {label: 'enabledEmission', value: 'enabledEmission'},
    {label: 'enabledTransmissionParam', value: 'enabledTransmissionParam'},
    {label: 'enabledAllParam', value: 'enabledAllParam'},

];

export const getStatus = (event_code: number, event_type: number): string | null => {
    const code = +event_code;
    const type = +event_type;
    if (type === 1 && code === 1) {
        return 'off';
    }
    if (type === 1 && code === 2) {
        return 'sleep';
    }
    if (type === 1 && code === 3) {
        return 'driving';
    }
    if (type === 1 && code === 4) {
        return 'on';
    }
    if (type === 2 && code === 1) {
        return 'intermediate';
    }
    if (type === 2 && code === 2) {
        return 'intermediate';
    }
    if (type === 3 && code === 1) {
        return 'personal';
    }
    if (type === 3 && code === 2) {
        return 'yard';
    }
    if (type === 5 && code === 1) {
        return 'login';
    }
    if (type === 5 && code === 2) {
        return 'logout';
    }
    if (type === 6 && code === 1) {
        return 'poweron';
    }
    if (type === 6 && code === 2) {
        return 'poweron';
    }
    if (type === 6 && code === 3) {
        return 'poweroff';
    }
    if (type === 6 && code === 4) {
        return 'poweroff';
    }
    if (type === 11 && code === 0) {
        return 'adverse_driving';
    }
    if (type === 22 && code === 1) {
        return 'short_haul';
    }
    if (type === 7 && code === 1) {
        return 'malfunction_1_7';
    }
    if (type === 7 && code === 2) {
        return 'malfunction_2_7';
    }
    if (type === 7 && code === 3) {
        return 'malfunction_3_7';
    }
    if (type === 7 && code === 4) {
        return 'malfunction_4_7';
    }
    if (type === 7 && code === 5) {
        return 'malfunction_5_7';
    }
    if (type === 7 && code === 6) {
        return 'malfunction_6_7';
    }
    if (type === 4 && code >= 1 && code <= 9) {
        return 'certify'
    }
    return null;
}

export const getDuration = (start_date: string, end_date: string | undefined): string => {
    let start = moment(start_date);
    let end = moment(end_date);
    const time = moment.duration(end.diff(start));
    let h: number | string = time.hours();
    let m: number | string = time.minutes();
    let s: number | string = time.seconds();
    h = h > 0 ? `${h}h${m > 0 || s > 0 ? ':' : ''}` : '';
    m = m > 0 ? `${m}m${s > 0 ? ':' : ''}` : '';
    s = s > 0 ? `${s}s` : '';

    return h + m + s
}

export const statusList = [
    {id: 'off', name: 'off'},
    {id: 'sleep', name: 'sleep'},
    {id: 'driving', name: 'driving'},
    {id: 'on', name: 'on'},

    {id: 'intermediate conventional', name: 'intermediate conventional'},
    {id: 'intermediate reduced', name: 'intermediate reduced'},

    {id: 'personal', name: 'personal'},
    {id: 'yard', name: 'yard'},

    {id: 'login', name: 'login'},
    {id: 'logout', name: 'logout'},

    {id: 'poweron conventional', name: 'poweron conventional'},
    {id: 'poweron reduced', name: 'poweron reduced'},
    {id: 'poweroff conventional', name: 'poweroff conventional'},
    {id: 'poweroff reduced', name: 'poweroff reduced'},

    {id: 'malfunction logged', name: 'malfunction logged'},
    {id: 'malfunction cleared', name: 'malfunction cleared'},
    {id: 'diagnostic logged', name: 'diagnostic logged'},
    {id: 'diagnostic cleared', name: 'diagnostic cleared'},
];

export const getEvCodeType = (status: string): { event_type: number, event_code: number } | null => {
    switch (status) {
        case 'off':
            return {event_type: 1, event_code: 1}
        case 'sleep':
            return {event_type: 1, event_code: 2}
        case 'driving':
            return {event_type: 1, event_code: 3}
        case 'on':
            return {event_type: 1, event_code: 4}

        case 'intermediate conventional':
            return {event_type: 2, event_code: 1}
        case 'intermediate reduced':
            return {event_type: 2, event_code: 2}

        case 'personal':
            return {event_type: 3, event_code: 1}
        case 'yard':
            return {event_type: 3, event_code: 2}

        case 'login':
            return {event_type: 5, event_code: 1}
        case 'logout':
            return {event_type: 5, event_code: 2}

        case 'poweron conventional':
            return {event_type: 6, event_code: 1}
        case 'poweron reduced':
            return {event_type: 6, event_code: 2}
        case 'poweroff conventional':
            return {event_type: 6, event_code: 3}
        case 'poweroff reduced':
            return {event_type: 6, event_code: 4}

        case 'malfunction logged':
            return {event_type: 7, event_code: 1}
        case 'malfunction cleared':
            return {event_type: 7, event_code: 2}
        case 'diagnostic logged':
            return {event_type: 7, event_code: 3}
        case 'diagnostic cleared':
            return {event_type: 7, event_code: 4}
        default:
            return null;
    }
}

interface AllFields {
    units?: number | string | any;
    eta?: string;
    total?: number;
    cards?: Array<any>; // Замените any на более конкретный тип, если это возможно
    card?: string;
    price_payments?: any; // Замените any на более конкретный тип, если это возможно
    price?: number;
}

export const calculator = createDecorator(
    {
        field: 'units',
        updates: {
            total: (val, all: any) => {
                let units = (all && typeof +all.units === 'number' && +all.units) ? +all.units : 0;
                // return units * (all.eta ? 105 : 95);
                return units * 105
            },
        }
    },
    {
        field: 'eta',
        updates: {
            total: (val, all: any) => {
                let units = (all && typeof +all.units === 'number' && +all.units) ? +all.units : 0;
                // return units * (val ? 105 : 95);
                return units * 105
            },
        }
    },
    {
        field: 'total',
        updates: {
            total: (val, all: any) => {
                let units = (all && typeof +all.units === 'number' && +all.units) ? +all.units : 0;
                // let type = all.cards?.find((card:any) => card.paymentMethodId === all.card)?.isBank;
                let price = all?.price_payments?.card_price;
                // return units * (all.eta ? 105 : 95);
                return units * (price || all.price || 105);
            },
        }
    },
    {
        field: 'card',
        updates: {
            total: (val, all: any) => {
                let units = (all && typeof +all.units === 'number' && +all.units) ? +all.units : 0;
                // let type = all.cards?.find((card:any) => card.paymentMethodId === all.card)?.isBank;
                let price = all?.price_payments?.card_price;
                // return units * (all.eta ? 105 : 95);
                return '$' + units * (price || all.price || 105);
            },
        }
    },
)

export const clear_local_storage = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('expires');
    localStorage.removeItem('fmcsa_count');
    localStorage.removeItem('user_count');
    localStorage.removeItem('theme');
    document.location.replace('/')
}

export const DustRedList = [
    {
        label: "#FFF1F0",
        value: "#fff1f0"
    },
    {
        label: "#FFCCC7",
        value: "#ffccc7",
    },
    {
        label: "#FFA39E",
        value: "#ffa39e",
    },
    {
        label: "#FF7875",
        value: "#FF7875",
    },
    {
        label: "#FF4d4f",
        value: "#ff4d4f",
    },
    {
        label: "#F5222D",
        value: "#f5222d",
    },
    {
        label: "#CF1322",
        value: "#cf1322",
    },
    {
        label: "#A8071A",
        value: "#a8071a",
    },
    {
        label: "#820014",
        value: "#820014",
    },
    {
        label: "#5C0011",
        value: "#5c0011",
    },

]
export const VolcanoList = [
    {
        label: "#fff2e8",
        value: "#fff2e8"
    }, {
        label: "#ffd8bf",
        value: "#ffd8bf"
    }, {
        label: "#ffbb96",
        value: "#ffbb96"
    }, {
        label: "#ff9c6e",
        value: "#ff9c6e"
    }, {
        label: "#ff7a45",
        value: "#ff7a45"
    }, {
        label: "#fa541c",
        value: "#fa541c"
    }, {
        label: "#d4380d",
        value: "#d4380d"
    }, {
        label: "#ad2102",
        value: "#ad2102"
    }, {
        label: "#871400",
        value: "#871400"
    }, {
        label: "#610b00",
        value: "#610b00"
    },


]
export const SunsetOrangeList = [
    {
        label: "#fff7e6",
        value: "#fff7e6"
    }, {
        label: "#ffe7ba",
        value: "#ffe7ba"
    }, {
        label: "#ffd591",
        value: "#ffd591"
    }, {
        label: "#ffc069",
        value: "#ffc069"
    }, {
        label: "#ffa940",
        value: "#ffa940"
    }, {
        label: "#fa8c16",
        value: "#fa8c16"
    }, {
        label: "#d46b08",
        value: "#d46b08"
    }, {
        label: "#ad4e00",
        value: "#ad4e00"
    }, {
        label: "#873800",
        value: "#873800"
    }, {
        label: "#612500",
        value: "#612500"
    },
]
export const CalendulaGold = [
    {
        label: "#fffbe6",
        value: "#fffbe6"
    }, {
        label: "#fff1b8",
        value: "#fff1b8"
    }, {
        label: "#ffe58f",
        value: "#ffe58f"
    }, {
        label: "#ffd666",
        value: "#ffd666"
    }, {
        label: "#ffc53d",
        value: "#ffc53d"
    }, {
        label: "#faad14",
        value: "#faad14"
    }, {
        label: "#d48806",
        value: "#d48806"
    }, {
        label: "#ad6800",
        value: "#ad6800"
    }, {
        label: "#874d00",
        value: "#874d00"
    }, {
        label: "#613400",
        value: "#613400"
    },
]
export const SunriseYellow = [
    {
        label: "#feffe6",
        value: "#feffe6"
    },
    {
        label: "#ffffb8",
        value: "#ffffb8"
    }, {
        label: "#fffb8f",
        value: "#fffb8f"
    }, {
        label: "#fff566",
        value: "#fff566"
    }, {
        label: "#ffec3d",
        value: "#ffec3d"
    }, {
        label: "#fadb14",
        value: "#fadb14"
    }, {
        label: "#d4b106",
        value: "#d4b106"
    }, {
        label: "#ad8b00",
        value: "#ad8b00"
    }, {
        label: "#876800",
        value: "#876800"
    }, {
        label: "#614700",
        value: "#614700"
    },
]
export const LimeList = [
    {
        label: "#fcffe6",
        value: "#fcffe6"
    }, {
        label: "#f4ffb8",
        value: "#f4ffb8"
    }, {
        label: "#eaff8f",
        value: "#eaff8f"
    }, {
        label: "#d3f261",
        value: "#d3f261"
    }, {
        label: "#bae637",
        value: "#bae637"
    }, {
        label: "#a0d911",
        value: "#a0d911"
    }, {
        label: "#7cb305",
        value: "#7cb305"
    }, {
        label: "#5b8c00",
        value: "#5b8c00"
    }, {
        label: "#3f6600",
        value: "#3f6600"
    }, {
        label: "#254000",
        value: "#254000"
    },
]
export const PolarGreen = [
    {
        label: "#f6ffed",
        value: "#f6ffed"
    }, {
        label: "#d9f7be",
        value: "#d9f7be"
    }, {
        label: "#b7eb8f",
        value: "#b7eb8f"
    }, {
        label: "#95de64",
        value: "#95de64"
    }, {
        label: "#73d13d",
        value: "#73d13d"
    }, {
        label: "#52c41a",
        value: "#52c41a"
    }, {
        label: "#389e0d",
        value: "#389e0d"
    }, {
        label: "#237804",
        value: "#237804"
    }, {
        label: "#135200",
        value: "#135200"
    }, {
        label: "#092b00",
        value: "#092b00"
    },
]
export const CyanList = [
    {
        label: "#e6fffb",
        value: "#e6fffb"
    }, {
        label: "#b5f5ec",
        value: "#b5f5ec"
    }, {
        label: "#87e8de",
        value: "#87e8de"
    }, {
        label: "#5cdbd3",
        value: "#5cdbd3"
    }, {
        label: "#36cfc9",
        value: "#36cfc9"
    }, {
        label: "#13c2c2",
        value: "#13c2c2"
    }, {
        label: "#08979c",
        value: "#08979c"
    }, {
        label: "#006d75",
        value: "#006d75"
    }, {
        label: "#00474f",
        value: "#00474f"
    }, {
        label: "#002329",
        value: "#002329"
    },
]
export const DaybreakBlue = [
    {
        label: "#e6f4ff",
        value: "#e6f4ff"
    }, {
        label: "#bae0ff",
        value: "#bae0ff"
    }, {
        label: "#91caff",
        value: "#91caff"
    }, {
        label: "#69b1ff",
        value: "#69b1ff"
    }, {
        label: "#4096ff",
        value: "#4096ff"
    }, {
        label: "#1677ff",
        value: "#1677ff"
    }, {
        label: "#0958d9",
        value: "#0958d9"
    }, {
        label: "#003eb3",
        value: "#003eb3"
    }, {
        label: "#002c8c",
        value: "#002c8c"
    }, {
        label: "#001d66",
        value: "#001d66"
    },
]
export const GeekBlue = [
    {
        label: "#f0f5ff",
        value: "#f0f5ff"
    }, {
        label: "#d6e4ff",
        value: "#d6e4ff"
    }, {
        label: "#adc6ff",
        value: "#adc6ff"
    }, {
        label: "#85a5ff",
        value: "#85a5ff"
    }, {
        label: "#597ef7",
        value: "#597ef7"
    }, {
        label: "#2f54eb",
        value: "#2f54eb"
    }, {
        label: "#1d39c4",
        value: "#1d39c4"
    }, {
        label: "#10239e",
        value: "#10239e"
    }, {
        label: "#061178",
        value: "#061178"
    }, {
        label: "#030852",
        value: "#030852"
    },
]
export const GoldenPurple = [
    {
        label: "#f9f0ff",
        value: "#f9f0ff"
    }, {
        label: "#efdbff",
        value: "#efdbff"
    }, {
        label: "#d3adf7",
        value: "#d3adf7"
    }, {
        label: "#b37feb",
        value: "#b37feb"
    }, {
        label: "#9254de",
        value: "#9254de"
    }, {
        label: "#722ed1",
        value: "#722ed1"
    }, {
        label: "#531dab",
        value: "#531dab"
    }, {
        label: "#391085",
        value: "#391085"
    }, {
        label: "#22075e",
        value: "#22075e"
    }, {
        label: "#120338",
        value: "#120338"
    },
]
export const Magenta = [
    {
        label: "#fff0f6",
        value: "#fff0f6"
    }, {
        label: "#ffd6e7",
        value: "#ffd6e7"
    }, {
        label: "#ffadd2",
        value: "#ffadd2"
    }, {
        label: "#ff85c0",
        value: "#ff85c0"
    }, {
        label: "#f759ab",
        value: "#f759ab"
    }, {
        label: "#eb2f96",
        value: "#eb2f96"
    }, {
        label: "#c41d7f",
        value: "#c41d7f"
    }, {
        label: "#9e1068",
        value: "#9e1068"
    }, {
        label: "#780650",
        value: "#780650"
    }, {
        label: "#520339",
        value: "#520339"
    },
]

export const ErrorNotification = ({status, message}: { status: number | string, message: string }) => {
    notification.error({
        message: 'Error ' + status,
        description: message,
        style: {backgroundColor: "#FFF2F0", border: "1px solid #ffccc7"},
        duration: 3,
    });
    const audio = new Audio('/bmw_warning.mp3');
    audio.play();
    console.error(message);
    throw new Error("Something went wrong");
}