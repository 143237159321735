//version 8

// PERMISSION CONSTANTS

const CHANGE_LOAD_AFTER_COMPLETED = 'edit-load-completed'
const READ_LOAD_PRICE = 'load-price'
const LOAD = 'load'
const ADD_LOAD = 'add-load'
const DELETE_LOAD = 'deleted-load'
const LOAD_ACTIVITY = 'load-activity'
const LOAD_BY_ID = 'load-by-id'

// PERMISSION CONSTANTS BY LOG
const LOG = 'log'

// PERMISSION CONSTANTS BY DRIVER
const DRIVER = "driver"

// PERMISSION CONSTANTS BY VEHICLE
const VEHICLE = 'vehicle'

const UNITS = 'units'

const USERS = 'users'

const TAGS = 'tags'

// PERMISSION  CONSTANTS BY ACCOUNTING
const ACCOUNTING = "accounting"

const API_KEYS = "api-keys"

// PERMISSION CONSTANTS BY OTHER FEATURES
const SHOW_PHONE_NUMBER = 'show-phone-number'
const REPORT_IFTA = 'report-ifta'
const REPORT_FMCSA = 'report-fmcsa'
const REPORT_ACTIVITY = 'report-activity'
const REPORT_DVIR = 'report-dvir'
const MAINTANENCE = 'maintanence'
const CHAT = 'chat'
const COMPANY = 'company'
const SUBSCRIPTION = 'subscription'

// ROLE CONSTANTS
const ROLE_LOAD_ONLY = 'load-only'
const ROLE_LOAD_VIEW = 'load-view'
const ROLE_DISPATCHER = 'dispatcher'
const ROLE_LOGBOOK = 'logbook'
const ROLE_OWNER = 'owner'
const ROLE_SUPER_DISPATCHER = 'super-dispatcher'
const ROLE_ACCOUNTING = 'accounting'
const SUPPORT_USER = 'support-user'

const PERMISSIONS = {
    [ROLE_LOAD_VIEW]: {
        label: "Loads viewer (read only)",
        read: [LOAD, LOAD_BY_ID, LOAD_ACTIVITY, CHAT, UNITS, TAGS],
        write: [],
    },
    [ROLE_LOAD_ONLY]: {
        label: "Default dispatcher",
        read: [READ_LOAD_PRICE, LOAD_BY_ID, LOAD_ACTIVITY, LOAD, MAINTANENCE, UNITS, SHOW_PHONE_NUMBER, TAGS],
        write: [ADD_LOAD, DELETE_LOAD, LOAD, UNITS]
    },
    [ROLE_LOGBOOK]: {
        label: "Logbook",
        read: [LOG, REPORT_FMCSA, REPORT_IFTA, UNITS, DRIVER, VEHICLE, TAGS, REPORT_DVIR, REPORT_ACTIVITY, ],
        write: [CHAT, LOG, DRIVER, VEHICLE,]
    },
    [ROLE_DISPATCHER]: {
        label: "Dispatcher with logbook access",
        read: [LOAD, READ_LOAD_PRICE, LOAD_BY_ID, LOAD_ACTIVITY, LOG, SHOW_PHONE_NUMBER, UNITS, TAGS, ACCOUNTING, REPORT_FMCSA,REPORT_DVIR, REPORT_ACTIVITY,],
        write: [LOAD, ADD_LOAD, CHAT, LOG, UNITS, ACCOUNTING]
    },
    [ROLE_SUPER_DISPATCHER]: {
        label: "Super Dispatcher",
        read: [
            READ_LOAD_PRICE, LOAD_BY_ID, LOAD_ACTIVITY, LOAD, LOG,
            DRIVER, VEHICLE,
            COMPANY, SHOW_PHONE_NUMBER,
            REPORT_IFTA,
            UNITS, SUBSCRIPTION, API_KEYS, TAGS,
            USERS,
            REPORT_FMCSA,
            REPORT_DVIR,
            REPORT_ACTIVITY,
        ],
        write: [
            ADD_LOAD, DELETE_LOAD, LOAD, LOG,
            DRIVER,
            VEHICLE, CHAT, CHANGE_LOAD_AFTER_COMPLETED, UNITS,
            REPORT_IFTA,
            COMPANY, SUBSCRIPTION, API_KEYS, TAGS,
            USERS
        ]
    },
    [ROLE_ACCOUNTING]: {
        label: "Accounting",
        read: [
            READ_LOAD_PRICE, LOAD_BY_ID, LOAD_ACTIVITY, LOAD, LOG,
            DRIVER, VEHICLE,
            COMPANY, SHOW_PHONE_NUMBER, ACCOUNTING,
            REPORT_IFTA,
            MAINTANENCE, UNITS, SUBSCRIPTION, API_KEYS, TAGS,
            USERS,
            REPORT_ACTIVITY,
        ],
        write: [
            ADD_LOAD, DELETE_LOAD, LOAD,
            DRIVER,
            VEHICLE, COMPANY, CHAT, CHANGE_LOAD_AFTER_COMPLETED,
            REPORT_IFTA, ACCOUNTING,
            MAINTANENCE, UNITS, SUBSCRIPTION, API_KEYS, TAGS,
            USERS
        ]
    },
    [ROLE_OWNER]: {
        label: "Owner",
        read: [
            READ_LOAD_PRICE, LOAD_BY_ID, LOAD_ACTIVITY, LOAD, LOG,
            DRIVER, VEHICLE,
            COMPANY, SHOW_PHONE_NUMBER, ACCOUNTING,
            MAINTANENCE, UNITS, SUBSCRIPTION, API_KEYS,
            REPORT_IFTA,
            TAGS,
            USERS,
            REPORT_FMCSA,
            REPORT_DVIR,
            REPORT_ACTIVITY,
        ],
        write: [
            ADD_LOAD, DELETE_LOAD, LOAD, LOG,
            DRIVER,
            VEHICLE, COMPANY,
            CHANGE_LOAD_AFTER_COMPLETED, CHAT,
            MAINTANENCE, UNITS, SUBSCRIPTION, API_KEYS,
            REPORT_IFTA, ACCOUNTING,
            TAGS,
            USERS
        ]
    },
    [SUPPORT_USER]: {
        label: "Support User",
        read: [
            READ_LOAD_PRICE, LOAD_BY_ID, LOAD_ACTIVITY, LOAD, LOG,
            DRIVER, VEHICLE, CHANGE_LOAD_AFTER_COMPLETED, UNITS, API_KEYS,
            REPORT_IFTA,TAGS,
            REPORT_FMCSA,
        ],
        write: [
            ADD_LOAD, DELETE_LOAD, LOAD, LOG,
            DRIVER,
            VEHICLE, COMPANY,
            REPORT_IFTA,
            CHANGE_LOAD_AFTER_COMPLETED, CHAT, UNITS, API_KEYS
        ]
    }
}
const ROLELIST = [
    {
        label: "Loads viewer (read only)",
        value: ROLE_LOAD_VIEW,
        title: 'Role that only has permission to view the loads.'
    },
    {
        label: "Loads only",
        value: ROLE_LOAD_ONLY,
        title: 'Allow certain users to update the status of loads, such as marking them as delivered or adding notes about the shipment.'
    },
    {
        label: "Logbook and Loads",
        value: ROLE_DISPATCHER,
        title: "Allow certain users to edit existing logbook and loads entries."
    },
    {
        label: "Logbook only",
        value: ROLE_LOGBOOK,
        title: "Role that only has permission to view the logs."
    },
    {
        label: "Accounting",
        value: ROLE_ACCOUNTING,
        title: "Accounting"
    },
    {
        label: "Super Dispatcher",
        value: ROLE_SUPER_DISPATCHER,
        title: "The Super Dispatcher has additional access and permissions in the system, such as the ability to override load assignments, change load priorities, and modify delivery schedules."
    },
    {
        label: "Owner",
        is_owner: true, //SKIP OWNER ROLE WHILE ADDING NEW USER
        value: ROLE_OWNER,
        title: "The owner of the resource has full control over the resource, including the ability to read, write, edit, or delete it. They can also grant permission to other users to access or modify the resource."
    }
]
export {
    PERMISSIONS,
    ROLELIST,

    CHANGE_LOAD_AFTER_COMPLETED,
    READ_LOAD_PRICE,
    LOAD,
    ADD_LOAD,
    DELETE_LOAD,
    LOAD_ACTIVITY,
    LOG,
    DRIVER,
    VEHICLE,
    COMPANY,
    ACCOUNTING,
    SHOW_PHONE_NUMBER,
    REPORT_FMCSA,
    REPORT_IFTA,
    REPORT_ACTIVITY,
    REPORT_DVIR,
    CHAT,
    UNITS,
    ROLE_LOAD_ONLY,
    ROLE_LOAD_VIEW,
    ROLE_DISPATCHER,
    ROLE_OWNER,
    ROLE_SUPER_DISPATCHER,
    ROLE_ACCOUNTING,
    SUPPORT_USER,
}


