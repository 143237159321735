import React, { useEffect, useState } from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Input, Row, Select, Space, Spin, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {AppleOutlined} from "@ant-design/icons";
import {  useCategoryOneData } from '../../../../../Hooks/cleverTms/Categoty';
import { categoryController } from '../../../../../API/Tms/category';

const TabPane = Tabs.TabPane;

type Data = {
  data?: {
      data: Array<any>,
      count: number
  }
}
type params = {
  readonly id: any
}
type numStr = number | string;

interface Fields {
    no: numStr,
    name: numStr,
    type: numStr,
    updatedAt: numStr,
    createdAt: numStr,
    isActive: boolean;
    companyId: numStr
    action: string;

}
const {Option} = Select;
const CategoryEdit = () => {

  const [companyData, setCompanyData] = useState<{ data: Array<Fields>, count: number }>();
  const {id} = useParams<params>();
  const {data}: Data = useCategoryOneData(id)
  let navigate = useNavigate();

  useEffect(() => {
      if (data) {
          setCompanyData(data)
      }
  }, [data])

  const onSubmit = async (value: any) => {
      await categoryController.categoryPatch(value)
      navigate(-1)
  };

     const type = [
        { id: "driver", name: "driver" },
        { id: "broker", name: "broker" },
    ]

  return (
    <div>
        <Spin size="large" spinning={!data}>
    {companyData && <Form onSubmit={onSubmit}
                          initialValues={{...companyData,}}
                          render={({handleSubmit, values}) => (
                              <form onSubmit={handleSubmit}>
                                  <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                      <Tabs defaultActiveKey="1">
                                          <TabPane
                                              tab={
                                                  <span>
                              <AppleOutlined rev={undefined}/>
                              MAIN FIELDS
                               </span>
                                              }
                                              key="1"
                                          >
                                              <Space direction="vertical" size="middle"
                                                     style={{display: 'flex',}}>
                                                  <Row gutter={[16, 20]}>

                                                           <Col className="gutter-row" span={6}>
                                                              <label>name: </label>
                                                              <Field
                                                                  name='name'
                                                                  render={({input}: { input: any }) => (
                                                                      <Input  placeholder='name'
                                                                              type="text" {...input} />
                                                                  )}
                                                              />
                                                          </Col>
                                                      <Col className="gutter-row" span={6}>
                                                          <label>status:</label>
                                                          <Field
                                                              name="type"
                                                              render={({input}: { input: any }) => (
                                                                  <Select defaultValue={input.value}
                                                                          onChange={(value, option) => {
                                                                              input.onChange(value)
                                                                          }} style={{width: '100%'}}>
                                                                      {type.map(data => <Option
                                                                          key={data.id} >{data.name}</Option>)}
                                                                  </Select>
                                                              )}
                                                          /></Col>

                                                  </Row>
                                                  {/* <div>
                                                      <label>isActive: </label>
                                                      <Field
                                                          name="isActive"
                                                          render={({input}: { input: any }) => (
                                                              <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                                          )}
                                                      />
                                                  </div> */}
                                                  <div className="buttons">
                                                      <Button type="primary" htmlType="submit">
                                                          Submit
                                                      </Button>
                                                  </div>
                                              </Space>
                                          </TabPane>
                                      </Tabs>
                                  </Space>
                              </form>
                          )
                          }
    />
    }
</Spin>

</div>
  )
}

export default CategoryEdit
