import React from 'react'
import {Button, Modal, Space, Spin, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import { tagController } from '../../../../../API/Tms/tagController';
import {useQueryClient} from "react-query";
import {useTagData} from "../../../../../Hooks/cleverTms/TagController";
type numStr = number | string;

interface tagSource {
    no: numStr;
    text: numStr,
    usersCount: numStr,
    unitsCount:numStr,
    updatedAt: numStr,
    createAt: numStr,
    companyId: numStr
    isActive: boolean;
    action: { id: numStr, queryClient: any,};
    key: React.Key;
}

const columns: object[] = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        sorter: {
            compare: (a:any, b:any) => a.no - b.no,
            multiple: 3,
        },
    },
    {
        title: 'text',
        dataIndex: 'text',
        key: 'text',
        sorter: (a:any, b:any) => a.text.length - b.text.length,

    }, {
        title: 'usersCount',
        dataIndex: 'usersCount',
        key: 'usersCount',
        sorter: {
            compare: (a:any, b:any) => a.usersCount - b.usersCount,
            multiple: 3,
        },
    },{
        title: 'unitsCount',
        dataIndex: 'unitsCount',
        key: 'unitsCount',
        sorter: {
            compare: (a:any, b:any) => a.unitsCount - b.unitsCount,
            multiple: 3,
        },

    },{
        title: 'updatedAt',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: (a:any, b:any) => a.updatedAt.length - b.updatedAt.length,

    },{
        title: 'createAt',
        dataIndex: 'createAt',
        key: 'createAt',
    },
    {
        title: 'companyId',
        dataIndex: 'companyId',
        key: 'companyId',
        sorter: {
            compare: (a:any, b:any) => a.companyId - b.companyId,
            multiple: 3,
        },
    },
    {
        title: 'Is Active',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (tag: boolean) => (
            <Tag color={tag ? 'geekblue' : 'red'}>
                {tag ? 'True' : 'False'}
            </Tag>
        ),
        filters: [
            {
                text: 'True',
                value: true,
            },
            {
                text: 'False',
                value: false,
            },

        ],
        onFilter: (value: any, record:any) => {
            return record.isActive === value

        },
    },
    {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        render: ({id, queryClient} : {id: string | number,queryClient:any }) =>
            <Space>
                <Link to={`${id}`}><Button>Edit</Button></Link>
                <Button  onClick={() => {
                    Modal.confirm({
                            title: 'LoadController',
                            icon: <ExclamationCircleOutlined rev={undefined}/>,
                            content: 'Do you want to delete this load ?',
                            okText: 'Ok',
                            cancelText: 'cancel',
                            onOk: async () => {
                               await tagController.deleteTagController(id)
                                queryClient.refetchQueries(useTagData);

                            }
                        }
                    );
                }
                }
                >Delete
                </Button>
            </Space>

    },
]
const TagControllerTable = ({tag = [], total = 0, onChange,isLoading= true} : {tag: Array<any>| undefined, total: number | undefined, onChange(current: any): void ,isLoading: boolean | undefined}) => {
    const queryClient = useQueryClient();

    return (
    <div>
        
        <Spin size='large' spinning={isLoading}>


<Table onChange={onChange} dataSource={tag?.map((u: any, i: number): tagSource => {
    const obj: tagSource = {
        no: i + 1,
        text: u.text,
        usersCount: u.usersCount,
        unitsCount :u.unitsCount,
        updatedAt: u.updatedAt,
        createAt: u.createAt,
        companyId: u.companyId,
        isActive: u.isActive,
        action: {id: u.id, queryClient},
        key: u.id
    }
    return obj
})} columns={columns} pagination={{total: total, pageSizeOptions: [10,50,100]}}

/>
</Spin>
    </div>
  )
}

export default TagControllerTable