import SearchOptions from "../../../../Utils/SearchOptions";
import React, { useState } from 'react'
import { useTagData } from '../../../../Hooks/cleverTms/TagController';
import TagControllerTable from './TagControllerTable/TagControllerTable';
import { SearchResultForTag } from "../UtilsTMS/SearchResults";
import {SearchResultForCompany} from "../../../../Utils/SearchResults";
import {Button} from "antd";
import {Link} from "react-router-dom";


type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading? :boolean
}
 const Tag: React.FC = () => {


    const [text, setText] = useState<string | number | undefined>('')
    const [companyId, setCompanyId] = useState<undefined | number | string>('');

    const {data, isLoading}: Data = useTagData(text,companyId);
    const onChange = (query: any) => {
        // setSkip(10 * (parseInt(query.current) - 1));
    }
  return (
    <div className='mainPage'>
        <SearchOptions SearchResult={SearchResultForCompany}
                onSelect={(value: any, {valId}: {valId: number | undefined}) =>
                {setCompanyId(valId);}}
                placeholder={'Company Search'}  value={''}/>

        <SearchOptions SearchResult={SearchResultForTag}
                    onSelect={(value: any, {valId}: { valId: string | number | undefined }) => {
                        setText(valId);
                    }} placeholder="Text Search" value={''}/>
        <Link to={'add-tag'}>
            <Button type="primary" style={{float: "right"}} size={'large'}>
                ADD Tag
            </Button>

        </Link>
        <TagControllerTable tag={data?.data} total={data?.count} onChange={onChange} isLoading={isLoading}/>
    </div>
  )
}
export default Tag