import instance from "../api";
import {message} from "antd";
import moment from "moment";
import {
    getStatus
} from "../../Component/features/TT_ELD_Admin/CompanyErrors/CompanyErrors/SupportHistory/SupportErrors/SupportErrors";
import {ErrorNotification} from "../../Utils/data";

export const supports = {
    async read(companyId: number | undefined, sort: boolean | undefined, skip: number | undefined) {
        let filter = {
            "where": companyId ? {id: companyId} : {},
            "order": sort ? ["id DESC"] : ["id ASC"],
            "limit": 500,
            "skip": skip
        };
        let filterForCount = companyId ? {id: companyId} : {};

        const {data}: { data: object } = await instance(`users?filter=${(JSON.stringify(filter))}`);

        const getCount = async () => {
            if (!companyId) {
                const count = await instance(`users/count?where=${JSON.stringify(filterForCount)}`);
                return count.data.count;
            }
            return 0;
        }
        const count = await getCount();
        return {data, count: count};
    },
    async supportPatchData(companyId: any) {
        const key = 'updatable';
        message.loading({content: 'Loading...', key});
        try{
            const {data}: { data: any } = await instance(`userCompanies/create`, {
                method: 'POST',
                data: companyId,
            }).then(u => {
                setTimeout(() => {
                    message.success({content: 'Loaded!', key, duration: 2});
                }, 1000);
                return u;
            });
            return data;
        } catch (error: any){
            if (error.response && error.response.status ) {
                ErrorNotification({ status: error.response.status , message: error.response.data.error.message });
            }
        }
    },

    async userFinder(name: string ) {
        const { data }: any = await instance(`userCompanies/users?searchTypes=${JSON.stringify(['name'])}&search=${name}&isSupport=true`);
        return data?.data;
    },
    async readSupportVue(id: undefined | number, skip: undefined | string ,) {

        let where = {};
        where = id ? {...where, id} : where;
        const {data}: any = await instance(`/supports/getcompanies?page=${skip}`)

        const getCount = async () => {
            const count = await instance(`supports/count?where=${JSON.stringify(where)}`);
            return count.data.count;
        }
        const count = await getCount();
        return {
            data: data.data,
            count: count
        };
    },
    async readSupportFilter(companyId: undefined | number, id: undefined | number, skip: undefined | number = 0,) {
        let where = {};
        where = companyId ? {...where, companyId} : where;
        where = id ? {...where, id} : where;

        let filter = {
            "order": "createdAt DESC",
            "where": where,
            "limit": 100,
            "include": ["company", "user"]
        };
        const {data}: { data: object } = await instance(`supports?filter=${JSON.stringify(filter)}`);
        const getCount = async () => {
            const count = await instance(`supports/count?where=${JSON.stringify(where)}`);
            return count.data.count;
        }

        const count = await getCount();
        return {data, count}

    },

    async getCompanyById(companyId: undefined | number,) {
        if (companyId) {
            const {data}: { data: any } = await instance(`companies/find/${companyId}`);
            return data;
        }
    },

    async getCompanyHistory(companyId: string | number | undefined) {

        const {data}: any = await instance(`supports/${companyId}`);
        return data?.data;
    },
    async postNoteSupports(companyId: any) {
        // message.loading({content: 'Loading...', key:companyId});
        const {data}: { data: any } = await instance(`/supports/start`, {
            method: 'POST',
            data: {
                companyId
            }
        }).then(u => {
            // setTimeout(() => {
            //     message.success({content: 'Loaded!', key:companyId, duration: 2});
            // }, 1000);
            return u;
        });
        return data;

    },
    async checkedCompanies(dataObj: any, id: any) {
        message.loading({content: 'Loading...', key: dataObj});
        const {data}: { data: any } = await instance(`/supports/checked/${id}`, {
            method: 'POST',
            data: {
                ...dataObj,
            }
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key: dataObj, duration: 2});
            }, 1000);
            return u;
        });
        return data;

    },
    async getActiveDrivers(companyId: string | number, skip:string |number = 1) {
        const {data}:any = await instance( `users/searching-list?perPage=100000&companyId=${companyId}&status=true&role=driver`)
        return {
            data: data.data,
            count: data.count
        };
    },

    async getLogsById(companyId: string | number, dateStart: string | undefined, tomorrowDate: string | undefined) {
        const {data}: { data: any } = await instance(`/logs/get-logs-with-range/${companyId}/${dateStart}/${tomorrowDate}`);
        return data;
        const getCount = async () => {
            return 0;
        }
        const count = await getCount();
        return {
            data: data.data,
            count: count
        };
    },

    async getMalfunctions(startDate: any,companyId:string , tz: any) {
        const newDate = moment.tz(startDate, tz).format('DD-MM-YYYY');
        const {data} = await instance(`logs/v2/get-malfunction-logs?date=${newDate}&companyId=${companyId}`);
        return data.logs;
    },


    async loginDispatcher(dispatcherId: string | number | undefined) {
        if(dispatcherId){
            const {data}: { data: any } = await instance(`/dashboards/v2/getcompanies/${dispatcherId}`);
            return data;
            const getCount = async () => {
                return 0;
            }
            const count = await getCount();
            return {
                data: data.data,
                count: count
            };
        }

    },
    // async getFindOne(companyId: string | number, dispatcherId: number | string) {
    //     let where = {};
    //     // where = companyId ? {...where, companyId} : where;
    //     let filter = { "where": companyId ? {companyId: companyId , "userId": dispatcherId} : {},};
    //     const {data}: { data: object } = await instance(`/userCompanies/findOne?filter=${JSON.stringify(filter)}`);
    //     return data
    // },

    async deleteCompany(id: number | string) {
        message.loading({content: 'Loading...', key: id});
        let res;
        let error = "";
        try {
            const {data} = await instance(`/userCompanies/${id}`, {
                method: 'DELETE',
            }).then(u => {
                setTimeout(() => {
                    message.success({content: 'Deleted!', key: id, duration: 2});
                }, 1000);
                return u;
            });
            res = data;
        } catch (err) {
            error = "Oops something went wrong!";
        }
        return {data: res, error};
    },


    async logsList(companyId:string, todayDate:string,cursor:number|string) {
        const { data } = await instance(`logs/v2/logslist?date=${todayDate}&companyId=${companyId}&all=true`);
        let newData:Array<any> = [];
        data.data
            .forEach(({ driver, violations, timers, warnings }:any, i:string) => {
                if (driver) {
                    newData.push({
                        id: driver.id,
                        driver: driver.first_name + ' ' + driver.second_name,
                        driverData: driver,
                        tracking: driver.tracking,
                        is_active: driver.is_active,
                        truckNo: driver.vehicle && driver.vehicle.truck_number,
                        status: driver.status && getStatus(driver.status),
                        lastPosition: {
                            place: driver.status && driver.status.address,
                            time: driver.status && driver.status.start_date
                        },
                        lastLocation: {
                            place: driver.tracking && driver.tracking.address,
                            time: driver.tracking && driver.tracking.date
                        },
                        violations: violations ? violations.map((el:any) => el.value) : [],
                        warnings: warnings ? warnings.map((el:any) => el.value) : [],
                        break: timers && +timers.break,
                        drive: timers && +timers.driving,
                        shift: timers && +timers.shift,
                        cycle: timers && +timers.cycle,
                        recap: timers && +timers.recap,
                    })
                }
            });
        return { data: newData, total: data.total };
    },

}

