import instance from "../../api";
import {message, notification} from "antd";

export const deviceVersion = {
    async read() {
        try {
            const response = await instance(`users/logout/users/device-version/list`);
            if (response.data) {
                return {
                    data: response.data.data,
                    count: response.data.count
                };
            } else {
                return {
                    data: [],
                    count: 0
                };
            }
        } catch (error:any) {
            if (error.response && error.response.status === 404) {
                notification.error({
                    message: "Error 400",
                    description:error.response.data.error.message,
                    style: {backgroundColor: "#FFF2F0", border: "1px solid #ffccc7"},
                    duration: 3,
                });
                return {
                    data: [],
                    count: 0
                };
            } else {
                throw error;
            }
        }
    },
    async deviceVersionPost(limit: any) {
        const key = 'updatable';
        try {
            if(limit){
                message.loading({content: 'Loading...', key});
                const { data }: { data: any } = await instance(`users/logout/users/device-version?versioncode=${limit?.versioncode}`, {
                    method: 'POST',
                });
                message.success({content: 'Loaded!', key, duration: 2});
                return data;
            }

        } catch (error:any) {
            console.error("An error occurred:", error);
            if (error.response.data.error.statusCode === 400) {
                notification.error({
                    message: "Error 400",
                    description: error.response.data.error.message,
                    style: { backgroundColor: "#FFF2F0" ,  border: "1px solid #ffccc7"},
                    duration: 3,
                });
            }
            if (error.response.data.error.statusCode === 500) {
                notification.error({
                    message: "Error 500",
                    description: error.response.data.error.message,
                    style: { backgroundColor: "#FFF2F0" ,  border: "1px solid #ffccc7"},
                    duration: 3,
                });
            }
            throw error;
        }
    },

    async deviceCancel(versionCode: string | number) {
        try {
            const {data}: any = await instance(`users/logout/users/device-version/cancel?versioncode=${versionCode}`,{
                method: 'POST',
            });
            return data?.data;
        }catch (error:any) {
            console.error("An error occurred:", error);
            if (error.response.data.error.statusCode === 404) {
                notification.error({
                    message: "Error 400",
                    description: error.response.data.error.message,
                    style: { backgroundColor: "#FFF2F0" ,  border: "1px solid #ffccc7"},
                    duration: 3,
                });
            }
            if (error.response.data.error.statusCode === 500) {
                notification.error({
                    message: "Error 500",
                    description: error.response.data.error.message,
                    style: { backgroundColor: "#FFF2F0" ,  border: "1px solid #ffccc7"},
                    duration: 3,
                });
            }
            throw error;
        }

    },
}