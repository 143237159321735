import React, {useState} from 'react';
import {useTopErrorsData} from "../../../../../Hooks/TT_ELD/TopErrorsCompany";
import {SupportData} from "../../../../../Utils/quicktype";
import SupportLayout from "../CompanyErrors/SupportTable/SupportLayout";

const TopErrors = () => {

    const [companyId, setCompanyId] = useState<any>(1);
    const [skip, setSkip] = useState(1);
    const [innerRelation, setInnerRelation] = useState<any>();
    const {data ,isFetching, isLoading} : SupportData = useTopErrorsData(companyId,innerRelation, skip);
    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current) ));
    }
    return (
        <div className='mainPage'>
            <SupportLayout company={data?.data}  isFetchingCMP={isFetching} isLoadingCMP={isLoading} onChangeCMP={onChange} total={2} skip2=''/>
        </div>
    );
};

export default TopErrors;