import React, {useState} from 'react'
import {Space, Spin, Tabs} from "antd";
import {SecurityScanOutlined, WarningOutlined,HistoryOutlined } from "@ant-design/icons";
import {useParams} from "react-router-dom";
import { useCompanyHistory} from "../../../../../../Hooks/TT_ELD/Supports";
import SupportError from "./SupportError";
import SupportNote from "./SupportNote";
import SupportHistory from "./SupportHistory";
const TabPane = Tabs.TabPane;

export const Upp = ()=>{
    type params = {
        readonly id: any;
    };
    const {id} = useParams<params>();
let Ids:any = id
    if(!!Number(Ids)) {
        return +Ids;
    }
}

interface Data {
    data?: {
        data: Array<any>,
        count: number
    };
    isLoading?: any,
    refetch?: any,
    isFetching?: any
}

type params = {
    readonly id: any;
};

const SupportMain = () => {

    const {id} = useParams<params>();
    const {data, isLoading, isFetching ,refetch}: Data = useCompanyHistory(id)
    const [skip, setSkip] = useState(0);
    const onChange = (query: any) => {
        setSkip(10 * (parseInt(query.current) - 1));
    }
    return (
        <div className='mainPage'>
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={
                            <span>
                <WarningOutlined rev={undefined}/>
                                          Errors
              </span>
                        } key="1">
                            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                <SupportError/>
                            </Space>
                        </TabPane>
                        <TabPane tab={
                            <span>
                <HistoryOutlined rev={undefined}/>
                                          History
              </span>
                        } key="2">
                            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                <SupportHistory support={data} total={data?.count} isLoading={isLoading} isFetching={isFetching} onChange={onChange}/>
                            </Space>
                        </TabPane>
                        <TabPane tab={
                            <span>
                <SecurityScanOutlined rev={undefined}/>
                                          Support
              </span>
                        } key="3">
                            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                <SupportNote support={data} refetch={refetch}/>
                            </Space>
                        </TabPane>
                    </Tabs>
                </Space>
        </div>
    )
}

export default SupportMain