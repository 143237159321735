import React, { useState} from 'react';
import {
    Col,
    DatePicker,
    Input,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Badge,
    Button,
    Tooltip, Avatar, Card,
} from "antd";
import {Field, Form} from "react-final-form";
import SearchOptions from "../../../../../../Utils/SearchOptions";
import {SearchResultForCompany} from "../../../../../../Utils/SearchResults";
import {usStates} from "../../../../../../Utils/data";
import moment from 'moment';
import dayjs from "dayjs";
import {useVehicleSearch} from "../../../../../../Hooks/TT_ELD/Logs";
import {useUserData} from "../../../../../../Hooks/TT_ELD/Users";
import {useParams} from "react-router-dom";
import {users} from "../../../../../../API/TT_ELD/Users/user";
import {sendEvent} from "../../../../../../Utils/analytics";
import LogoLight from "../../../../../assets/logo/logoLight.svg";
import LogoDark from "../../../../../assets/logo/logoDark.svg";
import EVO from "../../../../../assets/logo/evodark.svg";
import {useSelector} from "react-redux";
import _debounce from "lodash/debounce";
import {useAssignLogBook} from "../../../../../../Hooks/TT_ELD/LogBook";
import {logbook} from "../../../../../../API/TT_ELD/logbook";


type MyObjectType = {
    [key: string | number]: any; // Индексная подпись с параметром типа 'string'
};
const {Option} = Select;

interface Params {
    [key: string]: string | undefined;
    id: string | undefined;
}

type Data = {
    data: any
    isLoading?: boolean,
    refetch(): void,
}

const MainFields = () => {
    const {id} = useParams<Params>();
    const {data, isLoading, refetch}: Data = useUserData(id);
    const { theme } = useSelector((state: any) => state.global);

    const [logId, setLogId] = useState<undefined | number | string>('');

    const assignLogBook : any = useAssignLogBook(logId)
    const onSearchDebouncedLogBook = _debounce((searchValue) => {
        setLogId(searchValue);
    }, 1000);


    const handleButtonClick = () => {
        enterLoading(0)
        sendEvent('Users', 'User changed', 'MainFields Click Submit', 1)
    };
    const [check, setCheck] = useState(false);
    const defaultValue: any = dayjs();
    const vehicleData: any = useVehicleSearch("", data?.companyId);
    const roleList = [
        {
            label: "Accepted",
            value: "accepted",
        },
        {
            label: "Rejected ",
            value: "rejected",
        },
    ];
    const [loadings, setLoadings] = useState<boolean[]>([]);
    const enterLoading = (index: number) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });

        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };


    const onSubmit = async (values:any) => {
        let body: MyObjectType = {};
        let key: string | number;
        let key2: string | number;
        for (key in values) {
            if (
                typeof data[key] === "object" &&
                !Array.isArray(data[key]) &&
                data[key] != null
            ) {
                for (key2 in values[key]) {
                    if (data[key][key2] != values[key][key2]) {
                        body[key] = values[key];
                        break;
                    }
                }
            } else if (data[key] != values[key]) {
                body[key] = values[key];
            }
        }
        if(body?.logbookId){
            let  logbookData = {
                logbookId : body?.logbookId,
                userId : Number(id)
            }
            await logbook.assignLogbook(logbookData)
        }
        await users.userDataPatch(id, body);
        refetch();
    };


    return data ? (
        <Card>
            <Form
                onSubmit={onSubmit}
                initialValues={{...data}}
                render={({handleSubmit, values, submitting}) => (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{display: "flex"}}
                            >
                                <Row gutter={16}>
                                    <Col span={5}>
                                        <div>
                                            <label>Id: </label>
                                            <Field
                                                name="id"
                                                render={({input}: { input: any }) => (
                                                    // <Input disabled type="text" {...input} />
                                                    <h3 style={{textTransform: "uppercase"}}>
                                                        <Badge status="processing"/> {input.value}
                                                    </h3>
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div>
                                            <label>Company:</label>
                                            <br/>
                                            <Field
                                                name="companyId"
                                                render={({input}: { input: any }) => (
                                                    <>
                                                        {check || !data?.company?.name ? (
                                                            <SearchOptions
                                                                SearchResult={SearchResultForCompany}
                                                                onSelect={(
                                                                    value: any,
                                                                    {valId}: { valId: number | undefined }
                                                                ) => {
                                                                    input.onChange(valId);
                                                                }}
                                                                placeholder={"Companies"}
                                                                defaultValue={data?.company?.name}
                                                                value={''}
                                                            />
                                                        ) : (
                                                            <div>
                                                                <h3>
                                                                    <Badge status="warning"/> {data?.company?.name + ' : ' + data?.companyId}
                                                                </h3>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div>
                                            <label>Role:</label>
                                            <br/>
                                            <Field
                                                name="role"
                                                render={({input}: { input: any }) => (
                                                    <h3 style={{textTransform: "uppercase"}}>
                                                        <Badge status="error"/> <span>{input.value}</span>
                                                    </h3>
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    { data?.refresh_date && <Col span={5}>
                                        <div>
                                            <label>Refresh Date:</label>
                                            <br/>
                                            <Field
                                                name="refresh_date"
                                                render={({input}: { input: any }) => (
                                                    <>
                                                        <h4 style={{textTransform: "uppercase"}}>
                                                            <Badge status="default"/>{" "}
                                                            {moment(input.value).format("YYYY-MM-DD HH:mm")}
                                                        </h4>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </Col>}
                                    <Col span={4}>
                                        <div>
                                            <label>Created At:</label>
                                            <br/>
                                            <Field
                                                name="createdAt"
                                                render={({input}: { input: any }) => (
                                                    <>
                                                        <h4 style={{textTransform: "uppercase"}}>
                                                            <Badge status="success"/>{" "}
                                                            {moment(input.value).format("YYYY-MM-DD HH:mm")}
                                                        </h4>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </Col>

                                </Row>
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <div>
                                            <label>First Name: </label>
                                            <Field
                                                name="first_name"
                                                render={({input}: { input: any }) => (
                                                    <Input
                                                        placeholder="First Name"
                                                        type="text"
                                                        {...input}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div>
                                            <label>Second Name: </label>
                                            <Field
                                                name="second_name"
                                                render={({input}: { input: any }) => (
                                                    <Input
                                                        placeholder="Second Name"
                                                        type="text"
                                                        {...input}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Col>

                                    {data.role !== "dispatcher" && (
                                        <Col span={6}>
                                            <div>
                                                <label>Username: </label>
                                                <Field
                                                    name="username"
                                                    render={({input}: { input: any }) => (
                                                        <Input
                                                            placeholder="Second Name"
                                                            type="text"
                                                            {...input}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row gutter={16}>
                                    {data.role !== "dispatcher" && (
                                        <Col span={6}>
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                <label>Vehicle: </label>
                                                <Field
                                                    name="vehicleId"
                                                    render={({input}: { input: any }) => (
                                                        <Select
                                                            defaultValue={data?.vehicle?.truck_number || ""}
                                                            showSearch
                                                            placeholder="Select a Vehicle"
                                                            optionFilterProp="children"
                                                            onChange={(value, option) => {
                                                                input.onChange(value);
                                                            }}
                                                            filterOption={(input, option: any) =>
                                                                (option?.label ?? "")
                                                                    .toLowerCase()
                                                                    .includes(input.toLowerCase())
                                                            }
                                                            options={vehicleData?.data?.data?.map(
                                                                (option: any) => ({
                                                                    value: option.id,
                                                                    label: option.truck_number,
                                                                })
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    )}
                                    {data.role === "dispatcher" && ( <Col span={6}>
                                        <div>
                                            <label>Whichservice</label>
                                            <Field
                                                name="whichservice"
                                                render={({input}: { input: any }) => (
                                                    <Select
                                                        defaultValue={input.value}
                                                        onChange={(value, option) => {
                                                            input.onChange(value);
                                                        }}
                                                        style={{width: "100%"}}
                                                    >
                                                        <Option value='zeelog'>Zeelog</Option>
                                                        <Option value='evoeld'>EVO ELD</Option>
                                                    </Select>
                                                )}
                                            />
                                        </div>
                                    </Col>)}
                                    {data.role === "dispatcher" && (
                                        <Col span={6}>
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                <label> Logbook </label>
                                                <Field
                                                    name="logbookId"
                                                    render={({input}: { input: any }) => (
                                                        <Select
                                                            defaultValue={data?.logbook?.name || ""}
                                                            showSearch
                                                            placeholder="Select a logbook"
                                                            optionFilterProp="children"
                                                            onSearch={onSearchDebouncedLogBook}
                                                            onChange={(value, option) => {
                                                                input.onChange(value);
                                                            }}
                                                            filterOption={(input, option:any) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={assignLogBook?.data?.data?.map((option:any) => ({
                                                                value: option.id,
                                                                label: option.name,
                                                            }))}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    )}
                                    {/*{data.role === "dispatcher" && (*/}
                                    {/*    <Col span={6}>*/}
                                    {/*        <div>*/}
                                    {/*            <label>LogBook: </label>*/}
                                    {/*            <Field*/}
                                    {/*                name="logbook"*/}
                                    {/*                render={({input}: { input: any }) => (*/}
                                    {/*                    <Input*/}
                                    {/*                        disabled={true}*/}
                                    {/*                        placeholder="Second Name"*/}
                                    {/*                        type="text"*/}
                                    {/*                        {...input}*/}
                                    {/*                    />*/}
                                    {/*                )}*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*    </Col>*/}
                                    {/*)}*/}

                                </Row>
                                {/*<div>*/}
                                {/*    <label>Driver: </label>*/}
                                {/*    <Field*/}
                                {/*        name="driverId"*/}
                                {/*        render={({input}: { input: any }) => (*/}
                                {/*            <Input placeholder="Second Name" type="text" {...input} />*/}
                                {/*        )}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <label>Notes: </label>
                                        <Field
                                            name="notes"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="Notes" type="text" {...input} />
                                            )}
                                        />
                                    </Col>

                                    {data.role !== "dispatcher" && (
                                        <Col span={6}>
                                            <div>
                                                <label>License Number: </label>
                                                <Field
                                                    name="license_number"
                                                    render={({input}: { input: any }) => (
                                                        <Input
                                                            placeholder="Second Name"
                                                            type="text"
                                                            {...input}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    )}

                                    {data.role !== "dispatcher" && (
                                        <Col span={6}>
                                            <div>
                                                <label>License State:</label>
                                                <br/>
                                                <Field
                                                    name="license_state"
                                                    render={({input}: { input: any }) => (
                                                        <Select
                                                            defaultValue={input.value}
                                                            onChange={(value, option) => {
                                                                input.onChange(value);
                                                            }}
                                                            style={{width: "100%"}}
                                                        >
                                                            {usStates.map((u) => (
                                                                <Option key={u.id}>{u.name}</Option>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    {data?.role === "driver" && (
                                        <Col span={4}>
                                            <label>Activated: </label>
                                            <br/>
                                            <Field
                                                name="activated"
                                                render={({input}: { input: any }) => {
                                                    return (
                                                        <DatePicker
                                                            defaultValue={defaultValue}
                                                            format={"YYYY-MM-DD"}
                                                            disabledDate={(current) => {
                                                                let customDate = moment()
                                                                    .add(1, "d")
                                                                    .format("YYYY-MM-DD");
                                                                return (
                                                                    current &&
                                                                    current > moment(customDate, "YYYY-MM-DD")
                                                                );
                                                            }}
                                                            onChange={(date: any, dateString: any) => {
                                                                input.onChange(
                                                                    moment
                                                                        .utc(dateString, "YYYY-MM-DD")
                                                                        .add(11, "hour")
                                                                        .toISOString()
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Col>
                                    )}
                                    <Col span={4}>
                                        <div style={{marginTop: 25}}>
                                            <label>Is Active: </label>
                                            <Field
                                                name="is_active"
                                                render={({input}: { input: any }) => (
                                                    <Switch
                                                        defaultChecked={input.value}
                                                        onChange={input.onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    {data.role !== "driver" && (
                                        <Col span={4}>
                                            <div style={{marginTop: 25}}>
                                                <label>Is Owner: </label>
                                                <Field
                                                    name="isOwner"
                                                    render={({input}: { input: any }) => (
                                                        <Switch
                                                            defaultChecked={input.value}
                                                            onChange={input.onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    )}
                                    {data.role !== "driver" && (
                                        <Col span={4}>
                                            <div style={{marginTop: 25}}>
                                                <label>Is Support: </label>
                                                <Field
                                                    name="isSupport"
                                                    render={({input}: { input: any }) => (
                                                        <Switch
                                                            defaultChecked={input.value}
                                                            onChange={input.onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    )}
                                    <Col span={4}>
                                        <div style={{marginTop: 25}}>
                                            <label>Email Verified: </label>
                                            <Field
                                                name="emailVerified"
                                                render={({input}: { input: any }) => (
                                                    <Switch
                                                        defaultChecked={input.value}
                                                        onChange={input.onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    {data?.whichservice && <Col span={6}>
                                        <div style={{marginTop: 25}}>
                                            <label>Which Service : </label>
                                            <Tooltip title={data?.whichservice === 'zeelog' ? 'Zeelog' : 'EVO ELD'}
                                                     placement="top">
                                                <Avatar shape="square" style={{height:'100%'}}
                                                        src={data.whichservice === 'zeelog' ? LogoLight : EVO}/>
                                            </Tooltip>
                                        </div>
                                    </Col>}
                                    {data.role == "dispatcher" && (
                                        <Col span={3}>
                                            <Field
                                                name="registerStatus"
                                                render={({input, meta}: { input: any; meta: any }) => (
                                                    <Col>
                                                        <label style={{}}>Register Status: </label>

                                                        <Select
                                                            defaultValue={input.value}
                                                            onChange={(value, option) => {
                                                                input.onChange(value);
                                                            }}
                                                            style={{width: "100%"}}
                                                        >
                                                            {roleList.map((u: any) => (
                                                                <Option key={u.value} value={u.value}>
                                                                    {u.label}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                        {meta.touched && meta.error && (
                                                            <div style={{color: "red"}}>{meta.error}</div>
                                                        )}
                                                    </Col>
                                                )}
                                            />
                                        </Col>
                                    )}
                                </Row>
                                { data.lastDevice && <Row style={{marginTop:'20px'}} gutter={16}>
                                    { data.lastDevice?.app_version && <Col span={5}>
                                        <div>
                                            <label>App version:</label>
                                            <br/>
                                            <Field
                                                name="lastDevice.app_version"
                                                render={({input}: { input: any }) => (
                                                    <div style={{fontSize:'16px', textTransform: "uppercase"}}>
                                                        <Badge status="default"/> <span>{input.value}</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </Col>}
                                    { data.lastDevice?.android_version && <Col span={5}>
                                        <div>
                                            <label>Android version:</label>
                                            <br/>
                                            <Field
                                                name="lastDevice.android_version"
                                                render={({input}: { input: any }) => (
                                                    <div style={{fontSize:'16px',textTransform: "uppercase"}}>
                                                        <Badge status="default"/> <span>{input.value}</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </Col>}
                                    { data.lastDevice?.device_type && <Col span={5}>
                                        <div>
                                            <label>Device type:</label>
                                            <br/>
                                            <Field
                                                name="lastDevice.device_type"
                                                render={({input}: { input: any }) => (
                                                    <div style={{fontSize:'16px',textTransform: "uppercase"}}>
                                                        <Badge status="default"/> <span>{input.value}</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </Col>}
                                    { data.lastDevice?.model && <Col span={5}>
                                        <div>
                                            <label>Model:</label>
                                            <br/>
                                            <Field
                                                name="lastDevice.model"
                                                render={({input}: { input: any }) => (
                                                    <div style={{fontSize:'16px',textTransform: "uppercase"}}>
                                                        <Badge status="default"/> <span>{input.value}</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </Col>}
                                </Row>}
                                <Button style={{marginTop: 20}} type="primary" htmlType="submit" loading={loadings[0]}
                                        onClick={handleButtonClick}>
                                    Submit
                                </Button>
                            </Space>
                        </div>
                    </form>
                )}
            />
        </Card>

    ) : (
        <Spin size="large" spinning={isLoading}></Spin>
    );
};

export default MainFields;
