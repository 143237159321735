import React from 'react';
import {Button, Spin, Table, Tag} from "antd";
import {Link} from "react-router-dom";

type numStr = number | string;

interface vehicleDataSource {
    no: numStr;
    year: numStr;
    make_model: string;
    truck_number: numStr;
    license_number: numStr;
    license_issue_place :numStr;
    vin:numStr;
    eld_vin:numStr;
    vin_type:numStr;
    fuel_type:numStr;
    is_active: boolean | undefined | null,
    company: string | undefined | null,
    edit: numStr;
    key: React.Key;
}

const columns: object[] = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
    },
    {
        title: 'Make / Model',
        dataIndex: 'make_model',
        key: 'make_model',
    },
    {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
    },
    {
        title: 'Truck Number ',
        dataIndex: 'truck_number',
        key: 'truck_number',
    },
    {
        title: 'License Number',
        dataIndex: 'license_number',
        key: 'license_number',
    },
    {
        title: 'License Issue Place',
        dataIndex: 'license_issue_place',
        key: 'license_issue_place',
    },
    {
        title: 'VIN',
        dataIndex: 'vin',
        key: 'vin',
    },
    {
        title: 'Eld vin',
        dataIndex: 'eld_vin',
        key: 'eld_vin',
    },
    {
        title: 'Vin type',
        dataIndex: 'vin_type',
        key: 'vin_type',
    },
    {
        title: 'Fuel type',
        dataIndex: 'fuel_type',
        key: 'fuel_type',
    },
    {
        title: 'Is Active',
        dataIndex: 'is_active',
        key: 'is_active',
        render: (tag: boolean) => (
            <Tag color={tag ? 'geekblue' : 'red'}>
                {tag ? 'True' : 'False'}
            </Tag>
        ),
        filters: [
            {
                text: 'True',
                value: true,
            },
            {
                text: 'False',
                value: false,
            },

        ],
        onFilter: (value: any, record:any) => {
            return record.is_active === value
        },
    },
    {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
    },

    {
        title: 'Edit',
        dataIndex: 'edit',
        key: 'edit',
        render: (id: number | string) => <Link to={`${id}`}><Button>Edit</Button></Link>,
    },
]
const VehicleTable = ({vehicle = [],total = 0, onChange,isLoading ,isFetching , skip}: { vehicle: Array<any> | undefined, total: number | undefined, onChange(current: any): void , isLoading: boolean | undefined,isFetching:any, skip: string | number | any}) => {

    return (
        <div>
            <Spin size='large' spinning={isLoading || isFetching}>
                <Table onChange={onChange}  dataSource={vehicle.map((u: any, i:number): vehicleDataSource => {
                    const obj: vehicleDataSource = {
                        no: i + 1,
                        year: u.year,
                        make_model: u.make +' / ' +u.model ,
                        truck_number: u.truck_number,
                        license_number: u.license_number,
                        license_issue_place:u.license_issue_place,
                        vin:u.vin,
                        eld_vin:u?.eld_vin,
                        vin_type:u?.vin_type,
                        fuel_type:u.fuel_type,
                        is_active:u.is_active,
                        company:u.company?.name,
                        edit: u.id,
                        key: u.id

                    }
                    return obj
                })} columns={columns} pagination={{ total: total, showSizeChanger: false,current: skip }}/>
            </Spin>
        </div>
    );
};

export default VehicleTable;