import React from 'react'
import {Field, Form} from "react-final-form";
import {Button, Col, Input, Row, Space, Switch} from "antd";
import {useNavigate} from "react-router-dom";
import { tagController } from '../../../../../API/Tms/tagController';

type numStr= number | string

type Fields ={
    text: numStr,
    usersCount: numStr,
    unitsCount:numStr,
    updatedAt: numStr,
    createAt: number,
    companyId: numStr
    isActive: boolean;
    action: string;
   
}
const AddTagController = () => {

    let navigate = useNavigate();

    const onSubmit = async (value: Fields) => {
        await tagController.addTagController(value)
        navigate(-1)
    };
    const required = (value:any) => (value ? undefined : 'Required')
  return (
    <div className='mainPage'>{ <Form onSubmit={onSubmit}

    render={({handleSubmit,  form, submitting,pristine}) => (
        <form onSubmit={handleSubmit}>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <h1>Add Tag</h1>
                <Space direction="vertical" size="middle"
                       style={{display: 'flex',}}>
                    <Row gutter={[16, 20]}>

                            <Col className="gutter-row" span={6}>
                                <label>text: </label>
                                <Field validate={required}
                                    name='text'
                                    render={({input,meta}: { input: any, meta:any }) => (
                                        <div>
                                            <Input {...input} type='text' placeholder='text'/>
                                            {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                        </div>
                                    )}
                                ></Field>
                            </Col>

                             <Col className="gutter-row" span={6}>
                                <label>companyId: </label>
                                <Field validate={required}
                                    name='companyId'
                                    render={({input, meta}: { input: any , meta:any}) => (
                                        <div>
                                            <Input {...input} type='number' placeholder='companyId'/>
                                            {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                        </div>
                                    )}
                                ></Field>
                            </Col>


                    </Row>
                    <div>
                        <label>isActive: </label>
                        <Field
                            name="isActive"
                            render={({input}: { input: any }) => (
                                <Switch defaultChecked={input.value} onChange={input.onChange}/>
                            )}
                        />
                    </div>
                    <div className="buttons">
                        <Button disabled={submitting} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </div>
                </Space>
            </Space>
        </form>
    )
    }
/>
}</div>
  )
}

export default AddTagController