import {useQuery} from "react-query";
import {companies} from "../../../API/TT_ELD/Users/company";

export const useCompaniesData = (companyId: number | string, usdotId:string| number , sort: boolean | undefined, skip: number | undefined, isDisabled:string): object => {
    return useQuery([
            `companies/${companyId || 'all'}`, companyId,usdotId, sort, skip,isDisabled],
        () => companies.read(companyId,usdotId, sort, skip ,isDisabled),
        { refetchOnWindowFocus: false })
}
export const useCompanyData = (companyId: number | string | undefined): any => {
    return useQuery([
            `companies/${companyId}`, companyId],
        () => companies.companyData(companyId),
        { refetchOnWindowFocus: false })
}
export const useGetPrice = (companyId: number | string | undefined): any => {
    return useQuery([
            `getPrice/${companyId}`, companyId],
        () => companies.getPrice(companyId),
        { refetchOnWindowFocus: false })
}
export const useVehicleData = (vehicleId: number | string | undefined): any => {
    return useQuery([
            `vehicles/${vehicleId}`, vehicleId],
        () => companies.vehicleData(vehicleId),
        { refetchOnWindowFocus: false,  })
}

export const useVehicleSearch = async (truck_number: string | number, companyId: string | number | undefined) => {
    return await companies.searchVehicleData(truck_number, companyId)
}

export const useSubscription = (stripeId:string, companyId:string): any => {
    return useQuery([
            `subscription/${stripeId}`, stripeId , companyId], () => companies.subscription(stripeId , companyId),
        { refetchOnWindowFocus: false, })
}

