import React, { useEffect, useState } from "react";
import {  Descriptions } from "antd";

const TruckAllInfo = ({data = []}:{data:Array<any> | undefined} ) => {

  const [truckInfo, setTruckInfo] = useState<any>([]);

  useEffect(() => {
    if (data) {
      setTruckInfo(data);
    }
  }, [data]);
  return (
    <div>
      <Descriptions title="Truck Info" bordered>
        <Descriptions.Item label=" Id">
          {truckInfo.id}
        </Descriptions.Item>
        <Descriptions.Item label="Vehicle Id">
          {truckInfo.vehicleId}
        </Descriptions.Item>
        <Descriptions.Item label="Driver Id">
          {truckInfo.driverId}
        </Descriptions.Item>
        <Descriptions.Item label="VIN">
          {truckInfo.VIN === null ? "null" : truckInfo.VIN}
        </Descriptions.Item>
        <Descriptions.Item label="Ambient Pressure">
          {truckInfo.ambientPressure}
        </Descriptions.Item>
        <Descriptions.Item label="Automatic Renewal">
          {truckInfo.ambientTemperature === null
            ? "null"
            : truckInfo.ambientTemperature}
        </Descriptions.Item>
        <Descriptions.Item label="Bus">{truckInfo.bus}</Descriptions.Item>
        <Descriptions.Item label="Coolant Level Percent">
          {truckInfo.coolantLevelPercent}
        </Descriptions.Item>
        <Descriptions.Item label="Coolant Temperature">
          {truckInfo.coolantTemperature}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {truckInfo.createdAt}
        </Descriptions.Item>
        <Descriptions.Item label="Date">
          {truckInfo.date === null ? "null" : truckInfo.date}
        </Descriptions.Item>
        <Descriptions.Item label="Def Level Percent">
          {truckInfo.defLevelPercent}
        </Descriptions.Item>
        <Descriptions.Item label="Dtc No">{truckInfo.dtcNo}</Descriptions.Item>
        <Descriptions.Item label="Engine Fuel Economy">
          {truckInfo.engineFuelEconomy}
        </Descriptions.Item>
        <Descriptions.Item label="Engine Fuel Rate">
          {truckInfo.engineFuelRate}
        </Descriptions.Item>
        <Descriptions.Item label="Engine Load">
          {truckInfo.engineLoad}
        </Descriptions.Item>
        <Descriptions.Item label="Engine Odometer">
          {truckInfo.engineOdometer}
        </Descriptions.Item>
        <Descriptions.Item label="Engine RPM">
          {truckInfo.engineRPM}
        </Descriptions.Item>
        <Descriptions.Item label="Engine Speed">
          {truckInfo.engineSpeed}
        </Descriptions.Item>
        <Descriptions.Item label="Fuel Level 2 Percent">
          {truckInfo.fuelLevel2Percent}
        </Descriptions.Item>
        <Descriptions.Item label="Fuel Level Percent">
          {truckInfo.fuelLevelPercent}
        </Descriptions.Item>
        <Descriptions.Item label="Fuel Tank Temperature">
          {truckInfo.fuelTankTemperature}
        </Descriptions.Item>
        <Descriptions.Item label="Gear">
            {truckInfo.gear}
        </Descriptions.Item>
        <Descriptions.Item label="Intake Temperature">
          {truckInfo.intakeTemperature}
        </Descriptions.Item>
        <Descriptions.Item label="Intercooler Temperature">
          {truckInfo.intercoolerTemperature}
        </Descriptions.Item>
        <Descriptions.Item label="Oil Level Percent">
          {truckInfo.oilLevelPercent}
        </Descriptions.Item>
        <Descriptions.Item label="Oil Pressure">
          {truckInfo.oilPressure}
        </Descriptions.Item>
        <Descriptions.Item label="Oil Temperature">
          {truckInfo.oilTemperature}
        </Descriptions.Item>
        <Descriptions.Item label="SeatBelt">
          {truckInfo.seatBelt}
        </Descriptions.Item>
        <Descriptions.Item label="Total EngineHours">
          {truckInfo.totalEngineHours}
        </Descriptions.Item>
        <Descriptions.Item label="Total EngineIdleFuel">
          {truckInfo.totalEngineIdleFuel}
        </Descriptions.Item>
        <Descriptions.Item label="Total EngineIdle Time">
          {truckInfo.totalEngineIdleTime}
        </Descriptions.Item>
        <Descriptions.Item label="Total Fuel Used">
          {truckInfo.totalFuelUsed}
        </Descriptions.Item>
        <Descriptions.Item label="Total Pto Time">
          {truckInfo.totalPtoTime}
        </Descriptions.Item>
        <Descriptions.Item label="Transmission Oil Temperature">
          {truckInfo.transmissionOilTemperature}
        </Descriptions.Item>
        <Descriptions.Item label="Turbo Oil Temperature">
          {truckInfo.turboOilTemperature}
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
          {truckInfo.updatedAt}
        </Descriptions.Item>
        <Descriptions.Item label="Type At">
          {truckInfo.type}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default TruckAllInfo;
