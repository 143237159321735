import React, {useEffect, useState} from 'react';
import {Button, Input, Row, Select, Space, Spin, Switch, Col, Empty} from "antd";
import {useParams, useNavigate} from "react-router-dom";
import {useVehicleCompanyData} from "../../../../../../../Hooks/TT_ELD/Vehicles";
import {Form, Field} from "react-final-form";
import {vehicles} from '../../../../../../../API/TT_ELD/Users/vehicles';
import {useMakeSelect, useModelSelect} from "../../../../../../../Hooks/TT_ELD/Makes-Models";
import {usStates} from "../../../../../../../Utils/data";

type MyObjectType = {
    [key: string | number]: any;
};
type inpTypStrNm = number | string | null | undefined | Array<any> | object;
type inpBln = boolean | undefined | null | Array<any> | object;

type Data = {
    data?: Array<any>,
    refetch?: any,
}

interface Data2 {
    data?: {
        data: Array<any> | any
    }
    status: 'success' | 'idle' | 'error' | 'loading' | object
    refetch?: any,
}

type params = {
    readonly id: any
}

const vehiclesInputs = [
    "truck_number",
    "license_number",
    "fuel_type",
    "notes",
    "vin",
    "license_issue_place",
    "eld_number",
    "eldid",
    "year",
]

interface Values {
    name?: inpTypStrNm;
    usdot?: inpTypStrNm;
    chatId?: inpTypStrNm;
    is_active?: inpBln;
    payForDriver?: inpBln;
    tz?: inpTypStrNm;
    phone?: inpTypStrNm;
    terminal_address?: inpTypStrNm;
    address?: inpTypStrNm;
    state?: inpTypStrNm;
    post_code?: inpTypStrNm;
    ownerId?: inpTypStrNm;
    stripeCustomerId?: inpTypStrNm;
    paymentMethodId?: inpTypStrNm;
    price?: inpTypStrNm;
    subscriptionId?: inpTypStrNm;
    quantity?: inpTypStrNm;
    stripe?: inpTypStrNm;
}

const VehicleEdit = ({vehicleId}: {vehicleId :string | number | undefined}) => {
    let navigate = useNavigate();
    const [companyData, setCompanyData] = useState<any>();
    const {id} = useParams<params>();
    const {data, refetch ,isLoading}: MyObjectType = useVehicleCompanyData(id)

    const onSubmit = async (values: any) => {
        let body: MyObjectType  = {}
        let key: string | number
        let key2: string | number
        for (key in values) {
            if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] != null) {
                for (key2 in values[key]) {
                    if (typeof values[key][key2] === 'object' && !Array.isArray(values[key][key2]) && values[key][key2] != null) {
                        if (JSON.stringify(values[key][key2]) != JSON.stringify(data[key][key2])) {
                            body[key] = values[key]
                            break
                        }
                    } else if (data[key][key2] != values[key][key2]) {
                        body[key] = values[key]
                        break
                    }
                }
            } else if (data[key] != values[key]) {
                body[key] = values[key]
            }
        }
        body = {
            ...body,
            makeId : values.makeId,
            modelId : values.modelId,
        }
        refetch()
        navigate(-1)
        await vehicles.companyPatchData(body, vehicleId);
    };
    useEffect(() => {
        if (data) {
            setCompanyData(data)
        }
    }, [data])


    const [models, setModels] = useState<any>();
    const makes: Data2 | any = useMakeSelect()
    const initialModels: Data = useModelSelect(companyData?.makeId)

    const filteredMakes = makes?.status === "success" ? makes?.data?.data?.map((make: any) => ({
        label: make.name,
        value: make.id
    })) : []


    return (
        <div className='mainPage'>
            <Spin size="large" spinning={isLoading}>
                {companyData ? <Form onSubmit={onSubmit}
                                      initialValues={{...companyData}}
                                      render={({handleSubmit, values, form}) => (
                                          <form onSubmit={handleSubmit}>
                                              <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                                  <Row gutter={[16, 16]}>
                                                      <Col span={8}>
                                                          <div>
                                                          <label>Company Id</label>
                                                          <Field
                                                              name="companyId"
                                                              render={({input}: { input: any }) => (
                                                                  <Input disabled={true} placeholder="company id"
                                                                         type="text" {...input} />
                                                              )}
                                                          />
                                                      </div>
                                                      </Col>
                                                      <Col span={8}><div>
                                                          <label>Truck Number</label>
                                                          <Field
                                                              name="truck_number"
                                                              render={({input}: { input: any }) => (
                                                                  <Input placeholder="Truck Number"
                                                                         type="text" {...input} />
                                                              )}
                                                          />
                                                      </div></Col>
                                                  </Row>
                                                  <Row gutter={[16, 16]}>
                                                      <Col span={8}>
                                                          <div>
                                                              <label>Make</label>
                                                              <Field
                                                                  name="makeId"
                                                                  render={({input}: { input: any }) => (
                                                                      <Select
                                                                          {...input}
                                                                          placeholder="Select Makes"
                                                                          onChange={(value, option) => {
                                                                              input.onChange(value)
                                                                              const {models: modelsFromMake} = makes?.data?.data.find((make: any) => make.id === value);
                                                                              const filteredModels = modelsFromMake.map((model: any) => ({
                                                                                  label: model?.name,
                                                                                  value: model?.id
                                                                              }))
                                                                              setModels(filteredModels)
                                                                              if (values.modelId) {
                                                                                  form.change('modelId', null)
                                                                              }
                                                                          }}
                                                                          style={{width: "100%"}}
                                                                          showSearch
                                                                          optionFilterProp="children"
                                                                          filterOption={(input, option: any) =>
                                                                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                          }
                                                                          options={filteredMakes?.map((option: any) => ({
                                                                              value: option.value,
                                                                              label: option.label,
                                                                          }))}
                                                                      />
                                                                  )}
                                                              />
                                                          </div>
                                                      </Col>
                                                      <Col span={8}><div>
                                                          <label>Model</label>
                                                          <Field
                                                              name="modelId"
                                                              render={({input}: { input: any }) => (
                                                                  <Select
                                                                      {...input}
                                                                      placeholder="Select Makes"
                                                                      style={{width: "100%"}}
                                                                      showSearch
                                                                      optionFilterProp="children"
                                                                      filterOption={(input, option: any) =>
                                                                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                      }
                                                                      options={models ? models : initialModels?.data?.map((option: any) => ({
                                                                          value: option.value,
                                                                          label: option.label,
                                                                      }))}
                                                                  />
                                                              )}
                                                          />
                                                      </div></Col>
                                                  </Row>
                                                  <Row gutter={[16, 16]}>
                                                      <Col span={8}><div>
                                                          <label>License Plate No</label>
                                                          <Field
                                                              name="license_number"
                                                              render={({input}: { input: any }) => (
                                                                  <Input placeholder="license_number"
                                                                         type="text" {...input} />
                                                              )}
                                                          />
                                                      </div></Col>
                                                      <Col span={8}><div>
                                                          <label>License Issue Place</label>
                                                          <Field
                                                              name="license_issue_place"
                                                              render={({input}: { input: any }) => (
                                                                  <Select
                                                                      defaultValue={input.value}
                                                                      onChange={(value, option) => {
                                                                          input.onChange(value);
                                                                      }}
                                                                      allowClear
                                                                      style={{width: '100%'}}
                                                                      placeholder="Please select"
                                                                      filterOption={(input, option: any) =>
                                                                          (option?.label ?? "")
                                                                              .toLowerCase()
                                                                              .includes(input.toLowerCase())
                                                                      }
                                                                      options={usStates?.map(
                                                                          (option: any) => ({
                                                                              value: option.id,
                                                                              label: option.truck_number,
                                                                          })
                                                                      )}
                                                                  />

                                                              )}
                                                          />
                                                      </div></Col>
                                                  </Row>
                                                  <Row gutter={[16, 16]}>
                                                      <Col span={8}><div>
                                                          <label>Fuel Type</label>
                                                          <Field
                                                              name="fuel_type"
                                                              render={({input}: { input: any }) => {
                                                                  return <Select defaultValue={input.value}
                                                                                 onChange={(value, option) => {
                                                                                     input.onChange(value)
                                                                                 }} style={{width: '100%'}}>
                                                                      <Select.Option value='Diesel'>Diesel</Select.Option>
                                                                      <Select.Option
                                                                          value='Gasoline'>Gasoline</Select.Option>
                                                                      <Select.Option value='Liquid Natural Gas'>Liquid
                                                                          Natural Gas</Select.Option>
                                                                      <Select.Option value='Compressed Natural Gas'>Compressed
                                                                          Natural Gas</Select.Option>
                                                                      <Select.Option
                                                                          value='Ethanol'>Ethanol</Select.Option>
                                                                      <Select.Option value='E-85'>E-85</Select.Option>
                                                                      <Select.Option value='M-85'>M-85</Select.Option>
                                                                      <Select.Option value='A55'>A55</Select.Option>
                                                                      <Select.Option
                                                                          value='Biodiesel'>Biodiesel</Select.Option>
                                                                      <Select.Option value='Other'>Other</Select.Option>
                                                                  </Select>
                                                              }}
                                                          />

                                                      </div></Col>
                                                      <Col span={8}><div>
                                                          <label>Notes</label>
                                                          <Field
                                                              name="notes"
                                                              render={({input}: { input: any }) => (
                                                                  <Input placeholder="Notes"
                                                                         type="text" {...input} />
                                                              )}
                                                          />
                                                      </div></Col>
                                                  </Row>
                                                  <Row gutter={[16, 16]}>
                                                      <Col span={8}><div>
                                                          <label>Vin</label>
                                                          <Field
                                                              name="vin"
                                                              render={({input}: { input: any }) => (
                                                                  <Input placeholder="Vin"
                                                                         type="text" {...input} />
                                                              )}
                                                          />
                                                      </div></Col>
                                                      <Col span={8}><div>
                                                          <label>Eld vin</label>
                                                          <Field
                                                              name="eld_vin"
                                                              render={({input}: { input: any }) => (
                                                                  <Input placeholder="eld_vin"
                                                                         type="number" {...input} />
                                                              )}
                                                          />
                                                      </div></Col>
                                                  </Row>
                                                  <Row gutter={[16, 16]}>
                                                      <Col span={8}><div>
                                                          <label>Vin type</label>
                                                          <Field
                                                              name="vin_type"
                                                              render={({input}: { input: any }) => (
                                                                  <Input placeholder="vin_type"
                                                                         type="text" {...input} />
                                                              )}
                                                          />
                                                      </div></Col>
                                                      <Col span={8}> <div>
                                                          <label>Year</label>
                                                          <Field
                                                              name="year"
                                                              render={({input}: { input: any }) => (
                                                                  <Input placeholder="Year"
                                                                         type="text" {...input} />
                                                              )}
                                                          />
                                                      </div></Col>
                                                  </Row>
                                                  <Row gutter={[16, 16]}>
                                                      <Col span={8}>
                                                          <div>
                                                          <label>Eld Number</label>
                                                          <Field
                                                              name="eld_number"
                                                              render={({input}: { input: any }) => (
                                                                  <Input placeholder="Eld Number"
                                                                         type="text" {...input} />
                                                              )}
                                                          />
                                                      </div>
                                                      </Col>
                                                      <Col span={8}><div>
                                                          <label>Eldid</label>
                                                          <Field
                                                              name="eldid"
                                                              render={({input}: { input: any }) => (
                                                                  <Input placeholder="Eldid"
                                                                         type="text" {...input} />
                                                              )}
                                                          />
                                                      </div>
                                                      </Col>
                                                  </Row>
                                                  <Row gutter={[16, 16]}>
                                                      <Col span={8}>
                                                          <div>
                                                              <label>Whichservice</label>
                                                              <Field
                                                                  name="whichservice"
                                                                  render={({input}: { input: any }) => (
                                                                      <Input disabled={true} placeholder="Whichservice" type="text" {...input} />
                                                                  )}
                                                              />
                                                          </div>
                                                      </Col>
                                                      <Col span={8}>
                                                          <div>
                                                              <label>Is Active</label>
                                                              <Field
                                                                  name="is_active"
                                                                  render={({input}: { input: any }) => (
                                                                      <div>
                                                                          <Switch defaultChecked={input.value}
                                                                                  onChange={input.onChange}/>
                                                                      </div>
                                                                  )}
                                                              />
                                                          </div>
                                                      </Col>

                                                  </Row>



                                                  <Row>
                                                      <div style={{marginTop:'20px'}} className="buttons">
                                                          <Button type="primary" htmlType="submit">
                                                              Submit
                                                          </Button>
                                                      </div>
                                                  </Row>

                                                          </Space>
                                          </form>
                                      )}
                /> : <Empty style={{margin:'200px  0'}}/>
                }
            </Spin>
        </div>
    );
};

export default VehicleEdit;
