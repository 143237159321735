import instance from "../api";
import {message} from "antd";
import {ErrorNotification} from "../../Utils/data";

export const logs = {
    async read(id: string | undefined, todayDate: string, record_status: number | undefined) {

        try{
            if(id){
                const { data }: { data: Array<any> } = await instance(`logs/selectedlogs/${id}/${todayDate}?record_status=${record_status}`);
                return data?.reverse();
            }
        } catch (error:any) {
            if (error.response && error.response.status ) {
                ErrorNotification({ status: error.response.status , message: error.response.data.error.message });
            }
        }
    },
    async updateLogs(logData: any,id:any, pathname: string) {
        message.loading({ content: 'Loading...', key: logData.id });
        const { data } = await instance(pathname?.includes('logzeros') ? `/logzeros/logedit-admin/${logData.id}/${id}` : `/logs/logedit-admin/${logData.id}/${id}`, {
            method: 'PATCH',
            data: logData,
        }).then(u => {
            setTimeout(() => {
                message.success({ content: 'Loaded!', key: logData.id, duration: 2 });
            }, 1000);
            return u;
        });
        return data;
    },

    async updateLogsEdit(logData: any,logId:string |undefined , id:any, pathname: string) {
        message.loading({ content: 'Loading...', key: logId });
        const { data } = await instance(pathname?.includes('logzeros') ? `/logzeros/logedit-admin/${logId}/${id}` : `/logs/logedit-admin/${logId}/${id}`, {
            method: 'PATCH',
            data: logData,
        }).then(u => {
            setTimeout(() => {
                message.success({ content: 'Loaded!', key: logId, duration: 2 });
            }, 1000);
            return u;
        });
        return data;
    },


    async updateLogsMany(logData: any, getKey:Array<number>,  pathname: string) {
        message.loading({ content: 'Loading...', key: logData.id });
        const datas = new Array<any>;
        for (const id of getKey) {
            await instance(pathname?.includes('logzeros') ? `/logzeros/${id}` : `/logs/${id}`, {
                method: 'PATCH',
                data: logData,
            }).then(u => {
                setTimeout(() => {
                    // message.success({ content: 'Loaded! ', key: logData.id, duration: 2 });
                }, 1000);
                datas.push(u)
            });
        }
        return datas;
    },

    // async updateMany(logData: any, ids: Array<number>, pathname: string) {
    //     message.loading({ content: 'Loading...', key: logData.id });
    //     const datas = new Array<any>;
    //     for (const id of ids) {
    //         await instance(pathname?.includes('logzeros') ? `/logzeros/${id}` : `/logs/${id}`, {
    //             method: 'PATCH',
    //             data: logData,
    //         }).then(u => {
    //                 // message.success({ content: `${id} Loaded! `, key: logData.id, duration: 2 });
    //             datas.push(u)
    //         });
    //     }
    //     return datas;
    // },

    async updateMany(body: any, driverId: Array<number>, date: string , companyId: string, recordStatus: number | string) {
        const key = 'updatable';
        message.loading({content: 'Loading...', key});
        let url = recordStatus != 0 ? `logs/bulkedit-admin/${driverId}/${date}/${companyId}` : `logzeros/bulkedit-admin/${driverId}/${date}/${companyId}`
        try{
            const {data}: { data: any } = await instance(url, {
                method: 'PATCH',
                data: body
            }).then(u => {
                setTimeout(() => {
                    message.success({content: 'Loaded!', key, duration: 2});
                }, 1000);
                return u;
            });
            return data;
        }catch (error : any){
            if (error.response && error.response.status ) {
                ErrorNotification({ status: error.response.status , message: error.response.data.error.message });
            }
        }

    },
    // async findOneLog(id: number | undefined, pathname: string) {
    //     if(id) {
    //         const { data }: { data: object } = await instance(pathname?.includes('logzeros') ? `/logzeros/${id}` : `/logs/${id}`);
    //         return data;
    //     }
    //     return
    // },

    async findOneLog(id: number | undefined,companyId: number | undefined, pathname: string) {
        if(id) {
            const { data }: { data: object } = await instance(pathname?.includes('logzeros') ? `/logzeros/find-admin/${id}/${companyId}` : `/logs/find-admin/${id}/${companyId}`);
            return data;
        }
        return
    },
    //
    // async findOneOrMany(inq: Array<number>, pathname: string) {
    //     if(inq && inq.length) {
    //         let filterExt = {
    //             where: {
    //                 id: {
    //                     inq: inq
    //                 }
    //             }
    //         }
    //         const { data }: { data: object } = await instance(pathname?.includes('logzeros') ? `/logzeros?filter=${JSON.stringify(filterExt)}` : `/logs?filter=${JSON.stringify(filterExt)}`);
    //         return data;
    //     }
    //     return
    // },
    async findOneOrMany(inq: Array<number>,companyId:string |number ,  pathname: string) {
        if(inq) {
            const { data }: { data: object } = await instance(pathname?.includes('logzeros') ? `/logzeros/find-admin/${inq}/${companyId}` : `/logs/find-admin/${inq}/${companyId}`);
            return data;
        }
        return
    },


    async searchVehicleData( query:any, companyId: string | number | undefined) {
        if(companyId){
            const {data}: any = await instance(`vehicles/searching-list?perPage=10000&companyId=${companyId}`);

            return {
                data: data.data,
                count: data.count
            };
        }
    },

    async lastTrucking(id: string | number | undefined) {
        const {data}: { data: any } = await instance(`/dashboards/userlasttracking/${id}`);
        return data;
    },



        async cleanCache(driverId : string | undefined, date: string | number){
            message.loading({ content: 'Loading...', key: driverId });

        const {data} : {data:any} =await instance(`/dashboards/cleancache/${driverId}/${date}/`,{
            method: 'POST',
            data: driverId,
            // headers: { 'Content-Type': 'application/json' }
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Clean Cashe !', key:driverId, duration: 2});
            }, 1000);
            return u;
        });
        localStorage.getItem('token')
        return data;
    },


    async openNewDay(body: number | string,driverId :number |string|undefined){
        message.loading( {content: 'Loading...', key: body});
        try{
            const {data} =await instance(`logs/open-new-day?driverId=${driverId}`, {
                method: 'POST',
                data: body

            }).then(u => {
                setTimeout(() => {
                    message.success({content: 'Loaded!', key: body, duration: 2});
                }, 1000);
                return u;
            });
            return data;
        }catch (error:any){
            if (error.response && error.response.status ) {
                ErrorNotification({ status: error.response.status , message: error.response.data.error.message });
            }
        }
    },
}
