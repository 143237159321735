import React from 'react'
import {Button, Modal, Space, Spin, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import { categoryController } from '../../../../../API/Tms/category';
import { useCategoryData } from '../../../../../Hooks/cleverTms/Categoty';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import contact from "../Contact";
import {contactController} from "../../../../../API/Tms/contact";


type numStr = string | number
interface contactSource {
    no: numStr
    fullName: numStr,
    updatedAt: numStr,
    createdAt: numStr,
    companyId: numStr,
    isActive: boolean,
    phone: numStr,
    email: numStr,
    brokerCompanyId: numStr,
    customerId: numStr
    action: { id: numStr, queryClient: any,};
    key: React.Key;

}

const columns: object[] = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        sorter: {
            compare: (a:any, b:any) => a.no - b.no,
            multiple: 1,
        },
    },
    {
        title: 'fullName',
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: (a:any, b:any) => a.fullName.length - b.fullName.length,

    }, {
        title: 'updatedAt',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: (a:any, b:any) => a.updatedAt.length - b.updatedAt.length,

    },{
        title: 'createdAt',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a:any, b:any) => a.createdAt.length - b.createdAt.length,

    },
    {
        title: 'companyId',
        dataIndex: 'companyId',
        key: 'companyId',
        sorter: (a:any, b:any) => a.companyId.length - b.companyId.length,

    },
    {
        title: 'Is Active',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (tag: boolean) => (
            <Tag color={tag ? 'geekblue' : 'red'}>
                {tag ? 'True' : 'False'}
            </Tag>
        ),
        filters: [
            {
                text: 'True',
                value: true,
            },
            {
                text: 'False',
                value: false,
            },

        ],
        onFilter: (value: any, record:any) => {
            return record.isActive === value

        },
    },
    {
        title: 'phone',
        dataIndex: 'phone',
        key: 'phone',
        sorter: (a:any, b:any) => a.phone.length - b.phone.length,

    },{
        title: 'email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a:any, b:any) => a.email.length - b.email.length,

    },{
        title: 'brokerCompanyId',
        dataIndex: 'brokerCompanyId',
        key: 'brokerCompanyId',

    },{
        title: 'customerId',
        dataIndex: 'customerId',
        key: 'customerId',
    },
    {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        render: ({id, queryClient} : {id: string | number,queryClient:any }) =>
            <Space>
                <Link to={`${id}`}><Button>Edit</Button></Link>
                <Button  onClick={() => {
                    Modal.confirm({
                            title: 'LoadController',
                            icon: <ExclamationCircleOutlined rev={undefined}/>,
                            content: 'Do you want to delete this load ?',
                            okText: 'Ok',
                            cancelText: 'cancel',
                            onOk: async () => {
                                await contactController.deleteContact(id)
                                queryClient.refetchQueries(useCategoryData);

                            }
                        }
                    );
                }
                }
                >Delete
                </Button>
            </Space>

    },
]
const ContactTable = ({contact = [], total = 0, onChange, isLoading=true} : {contact: Array<any>| undefined, total: number | undefined, onChange(current: any): void,isLoading: undefined | boolean}) => {
    const queryClient = useQueryClient();

    return (
        <div>
            <Spin size='large' spinning={isLoading}>


                <Table onChange={onChange} dataSource={contact?.map((u: any, i: number): contactSource => {
                    let create = u.createdAt
                    let update = u.updatedAt
                    const obj: contactSource = {
                        no: i + 1,
                        fullName: u.fullName,
                        updatedAt: update ? moment(update).format("YYYY-MM-DD, h:mm:ss a") : "",
                        createdAt:  create ? moment(create).format("YYYY-MM-DD, h:mm:ss a") : "",
                        companyId: u.companyId,
                        isActive: u.isActive,
                        phone:u.phone,
                        email: u.email,
                        brokerCompanyId: u.brokerCompanyId,
                        customerId:u.customerId,
                        action: {id: u.id, queryClient},
                        key: u.id
                    }
                    return obj
                })} columns={columns} pagination={{total: total, pageSizeOptions: [10,50,100]}}

                />
            </Spin>
        </div>
    )
}

export default ContactTable