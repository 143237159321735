import React from 'react';
import {Button, Modal, Space, Spin, Table} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {deviceVersion} from "../../../../../API/TT_ELD/Team/deviceVersion";
type numStr = string | number
const { confirm } = Modal;

interface modelSource {
    no: numStr,
    versioncode: string
    createdBy: numStr
    editedBy: numStr
    status: numStr,
    createdAt: numStr,
    action: { id: numStr ,status:string};
    key: React.Key;
}


const DeviceVersionTable = ({
                                data = [],
                                total = 0,
                                isLoading,
                                isFetching,
                                refetch
                            }: { data: Array<any> | undefined, total: number | undefined,  isLoading: boolean | undefined, isFetching: boolean, refetch(): void }) => {
    const column: object[] = [
        {
            title: 'VersionCode',
            dataIndex: 'versioncode',
            key: 'versioncode',
        },

        {
            title: 'CreatedBy',
            dataIndex: 'createdBy',
            key: 'createdBy',

        },
        {
            title: 'EditedBy',
            dataIndex: 'editedBy',
            key: 'editedBy',

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',

        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',

        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: ({ id,status }: { id: string | number,status:string }) => {
                const showConfirm = () => {
                    confirm({
                        title: 'Approval',
                        icon: <ExclamationCircleFilled rev={undefined} />,
                        content: 'Do you want to Canceled this version ?',
                        onOk: async () => {
                            return new Promise(async (resolve, reject) => {
                                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                                deviceVersion.deviceCancel(id)
                                refetch()
                            }).catch(() => {{refetch()}
                            });
                        },

                    });
                };
                return (
                    <Space>
                        {status === 'canceled' ?
                            <Button  disabled={true}>
                                Canceled
                            </Button>:
                            <Button  type="primary" onClick={showConfirm}>
                                Cancel
                            </Button>
                        }
                    </Space>)
            }

        },

    ]
    return (
        <div>
            <Spin size='large' spinning={isLoading || isFetching}>
                <Table dataSource={data?.map((u: any, i: number): modelSource => {
                    const obj: modelSource = {
                        no: i + 1,
                        versioncode: u?.versioncode,
                        createdBy: u?.createdBy,
                        editedBy: u?.editedBy,
                        status: u?.status,
                        createdAt: u?.createdAt,
                        action: {id: u?.versioncode, status:u?.status},
                        key: i +1
                    }
                    return obj
                })} columns={column} pagination={{total: total, showSizeChanger: false,}}/>
            </Spin>
        </div>
    );
};

export default DeviceVersionTable;