import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useLocation, Location} from "react-router-dom";
import {Spin, Tabs,} from "antd";
import {
    AndroidOutlined,
    AppleOutlined,
    SettingOutlined,
    ControlOutlined,
    ScheduleOutlined,
    InfoCircleOutlined,
    SlidersOutlined,
    MonitorOutlined,
    CloudDownloadOutlined,
} from "@ant-design/icons";
import {useUserData} from "../../../../../../Hooks/TT_ELD/Users";
import MainFields from "./MainFields";
import Commands from "./Commands";
import Config from "./Config";
import Logs from "../Logs";
import TruckInfo from "../TruckInfo/TruckInfo";
import LastTracking from "./LastTracking";
import Settings from "./Settings";
import Documents from "./Documents";
import UserCompanies from "../../../UserCompanies/UserCompanies";
import VehicleTabs from "../../Vehicles/VehicleData/VehicleEdit/VehicleTabs";
import useRouteQuery from "../../../../../../Utils/useRouterQuery";


interface Params {
    id: string | undefined;

    [key: string]: string | undefined;
}

type Data = {
    data: any
    isLoading?: boolean,
    isFetching?: boolean
    refetch(): void,
}

const User = () => {
    const [getTabKey, setTabKey] = useRouteQuery("1", 'tabKey', (v) => String(v));
    const [getTabKeyLocal, setTabKeyLocal] = useState('');
    useEffect(() => {
        setTabKeyLocal(getTabKey);
    }, [getTabKey])

    let navigate = useNavigate();
    const location: Location = useLocation()
    const {id} = useParams<Params>();
    const {data, isLoading, isFetching}: Data = useUserData(id);
    const items = [
        {
            label: <span><AppleOutlined rev={undefined}/>MAIN FIELDS</span>,
            key: '1',
            children:<MainFields/>,
        },
        {
            label: <span><AndroidOutlined rev={undefined}/>Commands</span>,
            key: '2',
            children: <Commands/>,
        },
        {
            label: <span><ControlOutlined rev={undefined}/>Config</span>,
            key: '3',
            children: <Config/>,
        },
        {
            label: <span><SettingOutlined rev={undefined}/>SETTINGS</span>,
            key: '4',
            children: <Settings/>,
        },
        {
            label: <span><ScheduleOutlined rev={undefined}/>Logs</span>,
            key: '5',
            children: <Logs/>,
        },
        {
            label: <span><InfoCircleOutlined rev={undefined}/>Truck Info</span>,
            key: '6',
            children: <TruckInfo/>,
        },
        {
            label: <span> <SlidersOutlined rev={undefined}/>Vehicle</span>,
            key: '7',
            children: <VehicleTabs vehicleId={data?.vehicleId}/>,
        },
        {
            label: <span> <MonitorOutlined rev={undefined}/>Last Tracking</span>,
            key: '8',
            children: <LastTracking/>,
        },
        {
            label: <span> <CloudDownloadOutlined rev={undefined}/>Documents</span>,
            key: '9',
            children: <Documents/>,
        },
    ]
    const items2 = [
        {
            label: <span><AppleOutlined rev={undefined}/>MAIN FIELDS</span>,
            key: '1',
            children:<MainFields/>,
        },
        {
            label: <span><AndroidOutlined rev={undefined}/>Commands</span>,
            key: '2',
            children: <Commands/>,
        },
        {
            label: <span><ControlOutlined rev={undefined}/>Config</span>,
            key: '3',
            children: <Config/>,
        },
        {
            label: <span> <CloudDownloadOutlined rev={undefined}/>Company</span>,
            key: '4',
            children: <UserCompanies uid={id}/>,
        },
    ]
    const getTabs = data?.role === 'driver' ? items : items2



    return (
            <Spin style={{marginTop:200}} size="large" spinning={isLoading || isFetching || !getTabKeyLocal}>
                <div style={{padding: "12px 8px"}}>
                {data && getTabKeyLocal && <Tabs
                    onTabClick={(e) => {
                        navigate(`/user/users/${id}?tabKey=${e}`);
                        setTabKey(e);
                    }}
                    defaultActiveKey={location.search.split('').splice(8, 9)[0] || '1'}
                    style={{height: '100%'}}
                    items={getTabs.map((tab) => {
                        return {
                            label: tab.label,
                            key: tab.key,
                            children: tab.children,
                        };
                    })}
                />}
                </div>
            </Spin>
    );
};

export default User;