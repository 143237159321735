import React, { useEffect, useState } from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Input, Row, Space, Spin, Switch, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {AppleOutlined} from "@ant-design/icons";
import { useTagOneData } from '../../../../../Hooks/cleverTms/TagController';
import { tagController } from '../../../../../API/Tms/tagController';

const TabPane = Tabs.TabPane;

type Data = {
  data?: {
      data: Array<any>,
      count: number
  }
}
type params = {
  readonly id: any
}
type numStr = number | string;

interface Fields {
  text: numStr,
  usersCount: numStr,
  unitsCount:numStr,
  updatedAt: numStr,
  createAt: number,
  companyId: numStr
  isActive: boolean;
  action: string;

}
const TagControllerEdit = () => {

  const [companyData, setCompanyData] = useState<{ data: Array<Fields>, count: number }>();
  const {id} = useParams<params>();
  const {data}: Data = useTagOneData(id)
  let navigate = useNavigate();

  useEffect(() => {
      if (data) {
          setCompanyData(data)
      }
  }, [data])

  const onSubmit = async (value: any) => {
      await tagController.tagPatch(value)
      navigate(-1)
  };
  return (
    <div className='mainPage'>
        <Spin size="large" spinning={!data}>
    {companyData && <Form onSubmit={onSubmit}
                          initialValues={{...companyData,}}
                          render={({handleSubmit, values}) => (
                              <form onSubmit={handleSubmit}>
                                  <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                      <Tabs defaultActiveKey="1">
                                          <TabPane
                                              tab={
                                                  <span>
                              <AppleOutlined rev={undefined}/>
                              MAIN FIELDS
                               </span>
                                              }
                                              key="1"
                                          >
                                              <Space direction="vertical" size="middle"
                                                     style={{display: 'flex',}}>
                                                  <Row gutter={[16, 20]}>

                                                           <Col className="gutter-row" span={6}>
                                                              <label>text: </label>
                                                              <Field
                                                                  name='text'
                                                                  render={({input}: { input: any }) => (
                                                                      <Input  placeholder='text'
                                                                              type="text" {...input} />
                                                                  )}
                                                              />
                                                          </Col>

                                                     <Col className="gutter-row" span={6}>
                                                          <label>companyid: </label>
                                                          <Field
                                                              name='companyId'
                                                              render={({input}: { input: any }) => (
                                                                  <Input  placeholder='companyid'
                                                                          type="number" {...input} />
                                                              )}
                                                          />
                                                      </Col>


                                                  </Row>
                                                  <div>
                                                      <label>isActive: </label>
                                                      <Field
                                                          name="isActive"
                                                          render={({input}: { input: any }) => (
                                                              <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                                          )}
                                                      />
                                                  </div>
                                                  <div className="buttons">
                                                      <Button type="primary" htmlType="submit">
                                                          Submit
                                                      </Button>
                                                  </div>
                                              </Space>
                                          </TabPane>
                                      </Tabs>
                                  </Space>
                              </form>
                          )
                          }
    />
    }
</Spin></div>
  )
}

export default TagControllerEdit