import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Input, Row, Space, Switch} from "antd";
import {Field, Form} from "react-final-form";
import {deviceController} from "../../../../../API/Tms/device";
import React from "react";

type params = {
    readonly id: any
}
type numStr = number | string;

interface Fields {
    no: numStr
    updatedAt: numStr,
    createdAt: numStr,
    isActive: boolean,
    model: numStr,
    androidVersion: numStr,
    ios: numStr,
    deviceToken: numStr,
    fcmToken: numStr,
    oneSignalUserId: numStr,
    appVersion: numStr,
    versionCode: numStr,
    deviceInfo: numStr,
    driverId: numStr,
    companyId: numStr,
    config: numStr,
    mediaDeviceId: numStr,
    dispatcherId: numStr

}
const deviceInputs = [
    "appVersion",
    "deviceToken",
    "model",
    "androidVersion",
    "ios",
    'fcmToken',
    'oneSignalUserId',

]
const deviceNumber = [
    "versionCode",
    "driverId",
    "companyId",
    "dispatcherId",
]
const AddContact = () => {

    const {id} = useParams<params>();

    let navigate = useNavigate();

    const onSubmit = async (value: Fields) => {
        await deviceController.addDeviceController(value)
        navigate(-1)
    };



    const required = (value:any) => (value ? undefined : 'Required')

    return (
        <div className='mainPage'>{<Form onSubmit={onSubmit}

                    render={({handleSubmit, values}) => (
                        <form onSubmit={handleSubmit}>
                            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                <h1>Add Device</h1>
                                <Space direction="vertical" size="middle"
                                       style={{display: 'flex',}}>
                                    <Row gutter={[16, 20]}>

                                        {deviceInputs.map(u => {
                                            return <Col className="gutter-row" span={6}>
                                                <label>{u}: </label>
                                                <Field  validate={required}
                                                    name={u}
                                                    render={({input, meta}: { input: any , meta:any}) => (
                                                        <div>
                                                            <Input {...input} type='text' placeholder={u}/>
                                                            {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                        </div>
                                                    )}
                                                />
                                            </Col>
                                        })
                                        }
                                        {deviceNumber.map(u => {
                                            return <Col className="gutter-row" span={6}>
                                                <label>{u}: </label>
                                                <Field  validate={required}
                                                    name={u}
                                                    render={({input , meta}: { input: any, meta:any }) => (
                                                        <div>
                                                            <Input {...input} type='number' placeholder={u}/>
                                                            {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                        </div>
                                                    )}
                                                />
                                            </Col>
                                        })
                                        }
                                    </Row>
                                    <div>
                                        <label>isActive: </label>
                                        <Field
                                            name="isActive"
                                            render={({input}: { input: any }) => (
                                                <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                            )}
                                        />
                                    </div>
                                    <div className="buttons">
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </Space>
                            </Space>
                        </form>
                    )
                    }
        />
        }</div>
    )
}

export default AddContact