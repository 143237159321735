import React, { useState} from 'react';
import {useServerConfig} from "../../../../../Hooks/TT_ELD/Team/ServerConfig";
import {Button, Input, InputNumber, Row, Space, Spin, Switch, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {AppleOutlined} from "@ant-design/icons";
import {serverConfig} from "../../../../../API/TT_ELD/Team/serverConfig";
import {sendEvent} from "../../../../../Utils/analytics";

type Data = {
    data?: {
        data: any
        count: number
    },
    isLoading?: boolean,
    refetch?: () => void,
    isFetching?: any
}
const TabPane = Tabs.TabPane;
const {TextArea} = Input;
const ServerConfiguration = () => {


    const {data, isLoading, isFetching}: Data = useServerConfig()
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    };

    const onSubmit = async (value: any) => {
        await serverConfig.serverConfigPatch(value)
        sendEvent(`Changed ServerConfiguration page`, `Changed ServerConfiguration page `, `Submit ServerConfiguration page ` , 1);
    };
    return (
        <div className='mainPage'>
            {/*<ServerConfigurationTabs data={data?.data?.config} isLoading={isLoading} isFetching={isFetching}/>*/}
            <Spin size="large" spinning={isLoading}>
                {data && <Form onSubmit={onSubmit}
                               initialValues={data?.data?.config}
                               render={({handleSubmit, values}) => (
                                   <form onSubmit={handleSubmit}>
                                       <Space direction="vertical" size={[50, 56]} style={{display: 'flex'}}>
                                           <Tabs defaultActiveKey="1">

                                               <TabPane
                                                   tab={
                                                       <span>
                                          <AppleOutlined rev={undefined}/>
                                          MAIN FIELDS
                                           </span>
                                                   }
                                                   key="1"
                                               >
                                                   <Space direction="vertical" size={[50, 36]}
                                                          style={{display: 'flex',}}>
                                                       < Row style={{margin: '20px 0 0px 0'}}>
                                                           <Field
                                                               name="disable_banner"
                                                               render={({input}: { input: any }) => (
                                                                   <Switch defaultChecked={input.value}
                                                                           onChange={input.onChange}/>
                                                               )}
                                                           />
                                                           <label style={{marginLeft: '15px'}}>disable_banner</label>
                                                       </Row>
                                                       < Row style={{margin: '0px 0 0px 0'}}>
                                                           <Field
                                                               name="view_message"
                                                               render={({input}: { input: any }) => (
                                                                   <Switch  defaultChecked={input.value}
                                                                           onChange={input.onChange}/>
                                                               )}
                                                           />
                                                           <label style={{marginLeft: '15px'}}>view_message</label>
                                                       </Row>

                                                       <Row style={{margin: '0px 0 0px 0', maxWidth: '500px'}}>
                                                           <label style={{marginLeft: '15px'}}>dashboard_message</label>
                                                           <Field
                                                                  name="dashboard_message"
                                                                  render={({
                                                                               input,
                                                                               meta
                                                                           }: { input: any, meta: any }) => (
                                                                      <>
                                                                          <TextArea rows={4} showCount maxLength={350}
                                                                                    onChange={onChange} {...input}/>
                                                                          {meta.error && <div
                                                                              style={{color: 'red'}}>{meta.error}</div>}
                                                                      </>
                                                                  )}
                                                           />
                                                       </Row>

                                                       < Row style={{margin: '0px 0 0px 0'}}>
                                                               <Field
                                                                   name="view_message_for_app"
                                                                   render={({input}: { input: any }) => (
                                                                       <Switch
                                                                               defaultChecked={input.value}
                                                                               onChange={input.onChange}/>
                                                                   )}
                                                               />
                                                               <label
                                                                   style={{marginLeft: '15px'}}>view_message_for_app</label>
                                                       </Row>
                                                       < Row style={{margin: '0px 0 0px 0', maxWidth: '500px'}}>

                                                               <label
                                                                   style={{marginLeft: '15px'}}>app_message</label>
                                                               <Field
                                                                      name="app_message"
                                                                      render={({
                                                                                   input,
                                                                                   meta
                                                                               }: { input: any, meta: any }) => (
                                                                          <>
                                                                              <TextArea rows={4} showCount
                                                                                        maxLength={350}
                                                                                        onChange={onChange} {...input}/>
                                                                              {meta.error && <div
                                                                                  style={{color: 'red'}}>{meta.error}</div>}
                                                                          </>
                                                                      )}
                                                               />

                                                           {/*<label*/}
                                                           {/*    style={{margin: '15px 15px 0 15px'}}>last_company_id</label>*/}
                                                           {/*<Field*/}
                                                           {/*       name="last_company_id"*/}
                                                           {/*       render={({input, meta}: { input: any, meta: any }) => {*/}
                                                           {/*           const defaultValue = input?.value == null ? input?.value : 10;*/}
                                                           {/*           return(*/}
                                                           {/*                   <>*/}
                                                           {/*                       <InputNumber style={{width:'100%'}} type='number' defaultValue={defaultValue}*/}
                                                           {/*                               {...input}/>*/}
                                                           {/*                   </>*/}
                                                           {/*               )*/}

                                                           {/*       }}*/}
                                                           {/*/>*/}

                                                       </Row>
                                                       <Row gutter={[16, 40]}>
                                                           <div className="buttons" style={{marginLeft:'10px'}}>
                                                               <Button type="primary" htmlType="submit">
                                                                   Submit
                                                               </Button>
                                                           </div>
                                                       </Row>
                                                   </Space>
                                               </TabPane>
                                           </Tabs>
                                       </Space>
                                   </form>
                               )
                               }
                />
                }
            </Spin>

        </div>
    );
};

export default ServerConfiguration;