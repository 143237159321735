import React, {useState} from 'react';
import {Button, Spin, Table, Tag, } from "antd";
import moment from "moment";
import {Link, useLocation} from "react-router-dom";
import ModalExpireCompanyDate from "./ModalExpireCompanyDate";

interface companyDataSource {
    id: number | string;
    name: string,
    owner: number | string | undefined | null;
    isActive: boolean | undefined | null;
    payForDriver: boolean | undefined | null;
    stripeType: string;
    isPaid: boolean | undefined | null;
    subscriptionQuantity: number | string | undefined | null;
    disableCompanyDate:string
    timeDifference:{createdAt:string , disableDate:string}
    subscription: string | undefined | null;
    note: string
    expireCompanyDate: {time:string, id: number |string}
    edit: { id: number | string , stripeCustomerId: number | string, stripeCustomerId2:string,stripeType:string };
    key: React.Key;

}

const CompaniesTable = ({ companiesData = [], total = 0, onChange,isLoading ,isFetching,skip, refetch}: { companiesData: Array<any> | undefined, total: number | undefined, onChange(current: any): void ,isLoading: boolean | undefined, isFetching:any,skip:number, refetch:any}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getId, setGetId] = useState<number>();
    const handleGetId = (record: any) => {
        setGetId(record)
    }
    const location = useLocation();
    let disable = location.pathname === '/disabled-companies'
    const showModal = () => {
        setIsModalOpen(true);
    };
    let disableCompanyDate  = disable ? {
        title:'Disable Company Date',
        dataIndex:'disableCompanyDate',
        key:'disableCompanyDate'
    } : {}
    let note  = disable ? {
        title:'Note',
        dataIndex:'note',
        key:'note'
    } : {}
    let timeDifference  = disable ? {
        title: 'Expire Company Date',
        dataIndex: 'expireCompanyDate' ,
        key:'expireCompanyDate',
        render: ({time , id }: { time :string , id: string | number }) => {
            const now = new Date();
            const endDateTime = new Date(time);
            const timeDiffMilliseconds = endDateTime.getTime()  - now.getTime()  ;
            const timeDiffSeconds = Math.floor(timeDiffMilliseconds / 1000);
            const timeDiffMinutes = Math.floor(timeDiffSeconds / 60);
            const timeDiffHours = Math.floor(timeDiffMinutes / 60);
            const timeDiffDays = Math.floor(timeDiffHours / 24);
            const day = timeDiffDays !== 0 ? `${timeDiffDays} days.` : '';
            const hour = timeDiffHours % 24 !== 0 ? `${timeDiffHours % 24} hour.` : '';
            return (
                <div>
                    {disable ? <>{time && (
                        <div style={{color: timeDiffDays < 0 || timeDiffHours % 24 < 0 ? "red":''}}>
                            {day} {hour}
                        </div>
                    )}
                    </> : <>{}</>}
                    {disable && !time && <Button onClick={showModal}>
                        Select expiration date
                    </Button>}
                </div>
            )
        }
    } : {}

    const columns: object[] = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'CompanyName',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
        },
        {
            title: 'Is active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (tag: boolean) => (
                <Tag color={tag ? 'geekblue' : 'red'}>
                    {tag ? 'True' : 'False'}
                </Tag>
            ),
        },
        {
            title:disable ? 'StripeType' : 'Pay for driver',
            dataIndex: disable ? 'stripeType' : 'payForDriver',
            key: disable ?  'stripeType' : 'payForDriver',
            render: (tag: boolean | string) => {
                return(
                    <>
                        {disable ? <>{tag}</> : <Tag color={tag ? 'geekblue' : 'red'}>
                            {tag ? 'True' : 'False'}
                        </Tag>}
                    </>
                    )
            },
        },
        {
            title: 'Is paid',
            dataIndex: 'isPaid',
            key: 'isPaid',
            render: (tag: boolean) => (
                <Tag color={tag ? 'geekblue' : 'red'}>
                    {tag ? 'True' : 'False'}
                </Tag>
            ),
        },
        {
            title: 'Subscription quantity',
            dataIndex: 'subscriptionQuantity',
            key: 'subscriptionQuantity',
        },
        disableCompanyDate,
        {
            title: disable ? 'Past time' : 'Created At' ,
            dataIndex: 'timeDifference' ,
            key:'timeDifference',
            render: (val: {createdAt:string, disableDate:string}) => {
                const now = new Date();
                const endDateTime = new Date(val?.disableDate);
                const timeDiffMilliseconds = now.getTime() - endDateTime.getTime() ;
                const timeDiffSeconds = Math.floor(timeDiffMilliseconds / 1000);
                const timeDiffMinutes = Math.floor(timeDiffSeconds / 60);
                const timeDiffHours = Math.floor(timeDiffMinutes / 60);
                const timeDiffDays = Math.floor(timeDiffHours / 24);
                return (
                    <div>
                        {disable ? <>{val?.disableDate && (
                            <div>
                                {timeDiffDays <= 0 ? '' :( timeDiffDays + ' days.') } {timeDiffHours % 24} hour.
                            </div>
                        )}
                        </> : <>{ moment(val?.createdAt,).format("YYYY-MM-DD HH:mm")}</>}

                    </div>
                )
            }
        },
        {
            title: 'Subscription',
            dataIndex: 'subscription',
            key: 'subscription',
            render: (tag: boolean) => (
                <Tag color={tag ? 'geekblue' : 'red'}>
                    {tag ? 'True' : 'False'}
                </Tag>
            ),
        },
        note,
        timeDifference,
        {
            title: 'Action',
            dataIndex: 'edit',
            key: 'edit',
            render: ({id,stripeCustomerId,stripeCustomerId2,stripeType}:{id: number | string, stripeCustomerId:string, stripeCustomerId2:string, stripeType:string}) => {
                let customerId:string = ''
                if(stripeCustomerId2){
                    if(stripeType === 'stripe1'){
                        customerId = stripeCustomerId
                    }
                    if(stripeType === 'stripe2'){
                        customerId = stripeCustomerId2
                    }
                }else {
                    customerId = stripeCustomerId
                }
                return(
                    <div style={{display:'flex'}}>
                        {disable && customerId && <a target={'_blank'} href={` https://dashboard.stripe.com/customers/${customerId}`}><Button style={{marginRight:10}} type='primary'>Open Stripe</Button></a> }
                        <Link to={`${id}`}><Button>Edit</Button></Link>
                    </div>
                    )

            },
        },
    ];
    return (
        <div>
            <Spin size="large" spinning={isLoading || isFetching}>
                {getId && <ModalExpireCompanyDate id={getId} isModalOpen={isModalOpen}
                                         setIsModalOpen={setIsModalOpen} refetch={refetch}/>}

                {companiesData && <Table onRow={(record: any) => {
                    return {
                        onClick: () => {
                            handleGetId(record)
                        },
                    };
                }} onChange={onChange} dataSource={companiesData?.map((u: any): companyDataSource => {
                    const obj: companyDataSource = {
                        id: u.id,
                        name: u.name,
                        owner: u.ownerId,
                        isActive: u.is_active,
                        payForDriver: u?.payForDriver,
                        isPaid: u?.stripe?.subscription?.latest_invoice?.paid,
                        stripeType: u?.stripeType,
                        timeDifference: {createdAt : u?.createdAt, disableDate: u?.disableCompanyDate},
                        disableCompanyDate: u?.disableCompanyDate ? moment(u?.disableCompanyDate,).format("YYYY-MM-DD HH:mm") : '',
                        subscriptionQuantity: u.stripe.subscription?.quantity,
                        subscription: u.stripe.subscription?.status,
                        note :u.note,
                        expireCompanyDate: {time : u.expireCompanyDate, id:u.id},
                        edit: {id:u.id, stripeCustomerId:u?.stripeCustomerId, stripeCustomerId2:u?.stripeCustomerId2,stripeType:u?.stripeType},
                        key: u.id
                    }
                    return obj;
                })} columns={columns} pagination={disable ? {total:total , current:skip, showSizeChanger: false} : false }/>}
            </Spin>
        </div>
    );
};

export default CompaniesTable;
