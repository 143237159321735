import React from 'react';
import {Field, Form} from "react-final-form";
import {Button, Col, Input, Row, Space, Switch} from "antd";
import {cityController} from "../../../../../API/Tms/cityController";
import {useNavigate} from "react-router-dom";

const AddCityController = () => {

    const cityInputs = [
        "city",
        "state",
        "zips",
        "timezone",
    ]

    const cityNumber = [
        "lat",
        "lng",
    ]
    type Fields ={
        city: string;
        state: string;
        lat: number;
        lng: number;
        zips: string;
        timezone:string;
        isActive: boolean;
        key: React.Key;
    }
    let navigate = useNavigate();

    const onSubmit = async (value: Fields) => {
        await cityController.addCityController(value)
        navigate(-1)
    };
    const required = (value:any) => (value ? undefined : 'Required')

    return (
        <div className='mainPage'>
            { <Form onSubmit={onSubmit}
                    render={({handleSubmit, values}) => (
                        <form onSubmit={handleSubmit}>
                            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                <h1>Add City</h1>
                                <Space direction="vertical" size="middle"
                                       style={{display: 'flex',}}>
                                    <Row gutter={[16, 20]}>

                                        {cityInputs.map(u => {
                                            return <Col className="gutter-row" span={6}>
                                                <label>{u}: </label>
                                                <Field validate={required}
                                                    name={u}
                                                       render={({input, meta}: { input: any , meta:any}) => (
                                                           <div>
                                                               <Input {...input} type='text' placeholder={u}/>
                                                               {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                           </div>
                                                       )}
                                                ></Field>
                                            </Col>
                                        })}

                                        {cityNumber.map(u => {
                                            return <Col className="gutter-row" span={6}>
                                                <label>{u}: </label>
                                                <Field validate={required}
                                                    name={u}
                                                       render={({input , meta}: { input: any , meta:any}) => (
                                                           <div>
                                                               <Input {...input} type='number' placeholder={u}/>
                                                               {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                           </div>
                                                       )}
                                                ></Field>
                                            </Col>
                                        })
                                        }

                                    </Row>
                                    <div>
                                        <label>isActive: </label>
                                        <Field
                                            name="isActive"
                                            render={({input}: { input: any }) => (
                                                <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                            )}
                                        />
                                    </div>
                                    <div className="buttons">
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </Space>
                            </Space>
                        </form>
                    )
                    }
            />
            }
        </div>
    );
};

export default AddCityController;