import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Input, Row, Space, Spin, Switch, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {AppleOutlined} from "@ant-design/icons";
import {useCustomerOneData} from '../../../../../Hooks/cleverTms/CustomerController';
import {customerController} from '../../../../../API/Tms/customerController';

const TabPane = Tabs.TabPane;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
}
type params = {
    readonly id: any
}
type numStr = number | string;

interface Fields {
    lat: number,
    lng: number,
    address: string,
    name: string,
    city: string,
    state: string,
    coordinates: numStr,
    updatedAt: numStr,
    createAt: numStr,
    notes: numStr,
    placeId: numStr,
    zipCodes: numStr,
    phone: numStr,
    isActive: boolean,
    companyId: numStr,
    action: numStr,
}

const CustomerControllerEdit = () => {
    const [companyData, setCompanyData] = useState<{ data: Array<Fields>, count: number }>();
    const {id} = useParams<params>();
    const {data}: Data = useCustomerOneData(id)
    let navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setCompanyData(data)
        }
    }, [data])

    const onSubmit = async (value: any) => {
        await customerController.customerPatch(value)
        navigate(-1)
    };
    const customerInputs = [
        "address",
        "name",
        "city",
        "state",
        "notes",
        "placeId",
        "zipCodes",
        "phone",
    ]

    const customerNumber = [
        "lat",
        "lng",
        "companyId"
    ]

    return (
        <div className='mainPage'>
            <Spin size="large" spinning={!data}>
                {companyData && <Form onSubmit={onSubmit}
                                      initialValues={{...companyData,}}
                                      render={({handleSubmit, values}) => (
                                          <form onSubmit={handleSubmit}>
                                              <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                                  <Tabs defaultActiveKey="1">
                                                      <TabPane
                                                          tab={
                                                              <span>
                              <AppleOutlined rev={undefined}/>
                              MAIN FIELDS
                               </span>
                                                          }
                                                          key="1"
                                                      >
                                                          <Space direction="vertical" size="middle"
                                                                 style={{display: 'flex',}}>
                                                              <Row gutter={[16, 20]}>

                                                                  {customerInputs.map(u => {
                                                                      return <Col className="gutter-row" span={6}>
                                                                          <label>{u}: </label>
                                                                          <Field
                                                                              name={u}
                                                                              render={({input}: { input: any }) => (
                                                                                  <Input placeholder={u}
                                                                                         type="text" {...input} />
                                                                              )}
                                                                          />
                                                                      </Col>
                                                                  })
                                                                  }
                                                                  {customerNumber.map(u => {
                                                                      return <Col className="gutter-row" span={6}>
                                                                          <label>{u}: </label>
                                                                          <Field
                                                                              name={u}
                                                                              render={({input}: { input: any }) => (
                                                                                  <Input placeholder={u}
                                                                                         type="number" {...input} />
                                                                              )}
                                                                          />
                                                                      </Col>
                                                                  })
                                                                  }

                                                              </Row>
                                                              <div>
                                                                  <label>isActive: </label>
                                                                  <Field
                                                                      name="isActive"
                                                                      render={({input}: { input: any }) => (
                                                                          <Switch defaultChecked={input.value}
                                                                                  onChange={input.onChange}/>
                                                                      )}
                                                                  />
                                                              </div>
                                                              <div className="buttons">
                                                                  <Button type="primary" htmlType="submit">
                                                                      Submit
                                                                  </Button>
                                                              </div>
                                                          </Space>
                                                      </TabPane>
                                                  </Tabs>
                                              </Space>
                                          </form>
                                      )
                                      }
                />
                }
            </Spin>
        </div>
    )
}

export default CustomerControllerEdit