import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Input, Row, Space, Spin, Switch, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {AppleOutlined} from "@ant-design/icons";
import {brokerController} from "../../../../../API/Tms/brokerController";
import {useBrokerOneData} from "../../../../../Hooks/cleverTms/BrokerController";

const TabPane = Tabs.TabPane;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
}
type params = {
    readonly id: any
}

interface Fields {
    "id": boolean,
    "name": boolean,
    "city": boolean,
    "state": boolean,
    "updatedAt": boolean,
    "createdAt": boolean,
    "isActive": boolean,
    "phone": boolean,
    "address": boolean,
    "email": boolean,
    "notes": boolean,
    "attachment": boolean,
    "zipCodes": boolean,
    "companyId": boolean,
}
const BrokerInputs = [
    "name",
    "city",
    "state",
    "updatedAt",
    "createdAt",
    "phone",
    "address",
    "email",
    "notes",
    "attachment",
    "zipCodes",
    "placeId",
]

const BrokerControllerEdit = () => {

    const [companyData, setCompanyData] = useState<any>();
    const {id} = useParams<params>();
    const {data}: Data = useBrokerOneData(id)
    let navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setCompanyData(data.data)
        }
    }, [data])

    const onSubmit = async (value: any) => {
        await brokerController.brokerPatch(value)
        navigate(-1)
    };
    return (
        <div className='mainPage'>
            <Spin size="large" spinning={!data}>
                {<Form onSubmit={onSubmit}
                       initialValues={{...companyData,}}
                                      render={({handleSubmit, values}) => (
                                          <form onSubmit={handleSubmit}>
                                              <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                                  <Tabs defaultActiveKey="1">

                                                      <TabPane
                                                          tab={
                                                              <span>
                                          <AppleOutlined rev={undefined}/>
                                          MAIN FIELDS
                                           </span>
                                                          }
                                                          key="1"
                                                      >
                                                          <Space direction="vertical" size="middle"
                                                                 style={{display: 'flex',}}>
                                                              <Row gutter={[16, 20]}>
                                                                  <Col span={6}>
                                                                      <div>
                                                                          <label>Id: </label>
                                                                          <Field
                                                                              name="id"
                                                                              render={({input}: { input: any }) => (
                                                                                  <Input disabled type="text" {...input} />
                                                                              )}
                                                                          />
                                                                      </div>
                                                                  </Col>
                                                                  {BrokerInputs.map(u => {
                                                                      return <Col className="gutter-row" span={6}>
                                                                          <label>{u}: </label>
                                                                          <Field
                                                                              name={u}
                                                                              render={({input}: { input: any }) => (
                                                                                  <Input  placeholder={u}
                                                                                         type="text" {...input} />
                                                                              )}
                                                                          />
                                                                      </Col>
                                                                  })
                                                                  }
                                                                  <Col span={6}>
                                                                      <div>
                                                                          <label>companyId : </label>
                                                                          <Field
                                                                              name="companyId"
                                                                              render={({input}: { input: any }) => (
                                                                                  <Input placeholder="companyId" type="number" {...input} />
                                                                              )}
                                                                          />
                                                                      </div>
                                                                  </Col>

                                                              </Row>
                                                              <div>
                                                                  <label>isActive: </label>
                                                                  <Field
                                                                      name="isActive"
                                                                      render={({input}: { input: any }) => (
                                                                          <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                                                      )}
                                                                  />
                                                              </div>
                                                              <div className="buttons">
                                                                  <Button type="primary" htmlType="submit">
                                                                      Submit
                                                                  </Button>
                                                              </div>
                                                          </Space>
                                                      </TabPane>
                                                  </Tabs>
                                              </Space>
                                          </form>
                                      )
                                      }
                />
                }
            </Spin>
        </div>
    );
};

export default BrokerControllerEdit;