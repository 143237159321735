import React, { useState} from 'react';
import {Field, Form} from "react-final-form";
import {Button,  Space, Form as FormAnt,} from "antd";
import {
    SearchResultForCompany,
    SearchResultForFindSupport,
} from "../../../../../Utils/SearchResults";
import {supports} from "../../../../../API/TT_ELD/supports";
import Search from "../../../../../Utils/SearchOptions";
import {useDispatcher} from "../../../../../Hooks/TT_ELD/Supports";
import SupportUserTable from "./SupportUserTable";
import {sendEvent} from "../../../../../Utils/analytics";

type numStr = number | string;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    };
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any,
}

interface Fields {
    companyId: numStr,
    role: numStr,
    userId: numStr,

}

const Supports = () => {

    const [form] = FormAnt.useForm();
    const [currentCompanyId, setCurrentCompanyId] = useState<undefined | number | string>(undefined);

    const [dispatcherId, setDispatcherId] = useState<any>('')
    const {data, isLoading, refetch, isFetching}: Data = useDispatcher(dispatcherId)
    const required = (value: any) => (value ? undefined : 'Required')

    const onSubmit = async (values: Fields) => {
        if (currentCompanyId && dispatcherId) {
            values.role = 'support-user'
            await supports.supportPatchData(values)
            sendEvent(`Support Add Company Submit `, `Add Company in Support page`, `companyId` ,'1');
            refetch()
            SearchResultForCompany('')
        }

    };
    return (
        <div className='mainPage'>
            <Form onSubmit={onSubmit}
                  render={({handleSubmit, form, values, submitting, pristine, invalid}) => (
                      <form onSubmit={handleSubmit}>
                          <Space>
                              <div>
                                  <Field
                                      name="companyId" validate={required}
                                      render={({input, meta}: { input: any, meta: any }) => (
                                          <>
                                              <Search SearchResult={SearchResultForCompany}
                                                      onSelect={(value: any, {valId}: { valId: number | undefined }) => {
                                                          input.onChange(valId)
                                                          setCurrentCompanyId(valId);
                                                      }} placeholder={'Company Search'} value={''}/>
                                              {meta.touched && meta.error &&
                                                  <div style={{color: 'red'}}>{meta.error}</div>}
                                          </>
                                      )}
                                  />
                              </div>
                              <div>
                                  <Field
                                      name="userId" validate={required}
                                      render={({input, meta}: { input: any, meta: any }) => (
                                          <>
                                              <Search
                                                  SearchResult={(query: any, companyId: number | undefined, role: string | undefined) => SearchResultForFindSupport(query)}
                                                  onSelect={(value: any, {valId}: { valId: number | undefined }) => {
                                                      setDispatcherId(valId)
                                                      input.onChange(valId)
                                                  }} placeholder="Support user" value=''/>

                                              {meta.touched && meta.error &&
                                                  <div style={{color: 'red'}}>{meta.error}</div>}
                                          </>
                                      )}
                                  />
                              </div>
                              <div className="buttons" style={{marginTop: '0px'}}>
                                  <Button  disabled={submitting || pristine} type="primary" htmlType="submit">
                                      Submit
                                  </Button>
                              </div>

                          </Space>
                          <Button style={{float: "right", marginLeft: '15px'}} size={'large'}
                                  onClick={refetch}>Refresh</Button>

                      </form>
                  )}
            />
            <SupportUserTable company={data} dispatcherId={dispatcherId} isLoading={isLoading} isFetching={isFetching} refetch={refetch}/>
        </div>
    );

}


export default Supports;