import React, {useEffect, useState} from "react";
import { Tabs} from "antd";
import {
    AndroidOutlined,
    AppleOutlined,
    UserOutlined,
    WindowsOutlined,
    DeliveredProcedureOutlined,
    DiffOutlined,
    CarOutlined,
    SafetyCertificateOutlined
} from "@ant-design/icons";
import TabDrivers from "./CompanyTabs/TabDrivers";
import UserCompanies from "../../../UserCompanies/UserCompanies";
import Subscription from "./CompanyTabs/Subscription";
import MainField from "./CompanyTabs/MainField";
import Stripe from "./CompanyTabs/Stripe";
import IFTA from "./CompanyTabs/IFTA";
import useRouteQuery from "../../../../../../Utils/useRouterQuery";
import VehiclesCompany from "../../Vehicles/VehiclesCompany";
import FreeTrial from "./CompanyTabs/FreeTrial";
import {useCompanyData} from "../../../../../../Hooks/TT_ELD/Companies";
import {useParams} from "react-router-dom";
import  Whichservice  from "./CompanyTabs/Whichservice";
import UpdateSubcription from "./CompanyTabs/UpdateSubcription";

type MyObjectType = {
    [key: string | number]: any; // Индексная подпись с параметром типа 'string'
};
type params = {
    readonly id: any
}

export const Company: React.FC = () => {
    const [getTabKey, setTabKey] = useRouteQuery("1", 'getTabKey', (v) => String(v));
    const [getTabKeyLocal, setTabKeyLocal] = useState('');
    useEffect(() => {
        setTabKeyLocal(getTabKey);
    }, [getTabKey])
    const {id} = useParams<params>();
    const {data}: MyObjectType = useCompanyData(id);
    const items = [
        {
            label: <span><AppleOutlined rev={undefined}/>MAIN FIELDS</span>,
            key: '1',
            children:<MainField/>,
        },
        {
            label: <span><AndroidOutlined rev={undefined}/>STRIPE</span>,
            key: '2',
            children:<Stripe/>,
        },
        {
            label: <span><DeliveredProcedureOutlined rev={undefined}/>SUBSCRIPTION</span>,
            key: '3',
            children: <Subscription/>,
        },
        {
            label: <span><DeliveredProcedureOutlined rev={undefined}/>Update SUBSCRIPTION</span>,
            key: '4',
            children: <UpdateSubcription/>,
        },
        {
            label: <span><WindowsOutlined rev={undefined}/>DRIVERS</span>,
            key: '5',
            children: <TabDrivers/>,
        },
        {
            label: <span><UserOutlined rev={undefined}/>Users</span>,
            key: '6',
            children: <UserCompanies uid={''}/>,
        },
        {
            label: <span><DiffOutlined rev={undefined}/>Vehicles</span>,
            key: '7',
            children: <VehiclesCompany/>,
        },
        {
            label: <span><SafetyCertificateOutlined  rev={undefined}/>IFTA</span>,
            key: '8',
            children: <IFTA/>,
        },
        {
            label: data?.stripe?.subscription?.status !== 'active' && <span><CarOutlined rev={undefined}/>Free trial</span> ,
            key: '9',
            children: <FreeTrial/>,
        },
        {
            label: <span><SafetyCertificateOutlined  rev={undefined}/>Change Whichservice</span>,
            key: '10',
            children: <Whichservice/>,
        },
    ]

    return <div className='mainPage'>
            <div >
                { getTabKeyLocal && <Tabs
                    onTabClick={(e) => {
                        setTabKey(e);
                    }}
                    defaultActiveKey={getTabKey}
                    style={{height: '100%'}}
                    items={items.map((tab) => {
                        return {
                            label: tab.label,
                            key: tab.key,
                            children: tab.children,
                        };
                    })}
                />}
            </div>
    </div>
};

 export default Company
