import React from 'react';
import {Col, Input, Row, Select, Space, Switch} from "antd";
import {Field} from "react-final-form";



const Coordinates = ({ data }: { data: any }) => {

    const coordinatesInputs = [
        "address",
        "coordinates.lat",
        "coordinates.lng",
        "eld_coordinates.lat",
        "eld_coordinates.lng",
        "gps_coordinates.lat",
        "gps_coordinates.lng",
        "fused_coordinates.lat",
        "fused_coordinates.lng",
    ]
    return (
        <div>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>

                <Row gutter={[16, 20]}>
                    {coordinatesInputs.map(u => {
                        return <Col className="gutter-row" span={8}>
                            <label>{u} </label>
                            <Field
                                name={u}
                                render={({input}: { input: any }) => (
                                    <Input  placeholder={u}
                                            type="text" {...input} />
                                )}
                            />
                        </Col>
                    })
                    }
                </Row>

            </Space>
        </div>
    );
};

export default Coordinates;