import React, { useState} from 'react';
import {useCategoryData} from '../../../../Hooks/cleverTms/Categoty';
import CategoryTable from './CategoryTable/CategoryTable';
import {SearchResultForCategory} from "../UtilsTMS/SearchResults";
import SearchOptions from "../../../../Utils/SearchOptions";
import {Button} from "antd";
import {SearchResultForCompany} from "../../../../Utils/SearchResults";
import AddCategory from "./CategoryTable/AddCategory";


type Data = {
    data?: {
        data: Array<any>,
        count: number
    };
    isLoading?: boolean,
    refetch?: any,
    isFetching?:any,
}
const Category = () => {

    const [skip, setSkip] = useState(0);
    const [name, setName] = useState<string | number | undefined>('')
    const [companyId, setCompanyId] = useState<undefined | number | string>('');
    const {data, isLoading, refetch, isFetching}: Data = useCategoryData(name, companyId);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onChange = (query: any) => {
        setSkip(10 * (parseInt(query.current) - 1));
    }
    const showModal = () => {
        setIsModalVisible(true);
    };



    return (
        <div className='mainPage'>
            <SearchOptions SearchResult={SearchResultForCompany}
                    onSelect={(value: any, {valId}: { valId: number | undefined }) => {
                        setCompanyId(valId);
                    }}
                    placeholder={'Company Search'}  value={''}/>
            <SearchOptions SearchResult={SearchResultForCategory}
                    onSelect={(value: any, {valId}: { valId: string | number | undefined }) => {
                        setName(valId);
                    }} placeholder="Name Search"  value={''}/>
            <Button style={{float: "right", marginLeft: '15px'}} size={'large'} onClick={refetch}>Refresh</Button>
            {isModalVisible &&   <AddCategory isModalVisible={isModalVisible}  setIsModalVisible={setIsModalVisible} companyId={companyId} refetch={refetch}/> }
            <Button type="primary" style={{float: "right"}} size={'large'} onClick={showModal}>ADD Category</Button>
            <CategoryTable category={data?.data} total={data?.count} onChange={onChange} isLoading={isLoading} isFetching={isFetching} />
        </div>
    );
};

export default Category;