import instance from "../api";
import {message} from "antd";
// import instanceEVO from "../apiEVO";
import {ErrorNotification} from "../../Utils/data";

export const fmcsa = {
    async read( skip: string | number = 1) {
        const {data}: any = await instance(`logs/fmcsa-pending-list?page=${skip}&perPage=10`);
        return {
            data: data.data,
            count: data.count
        };
    },

    async fmcsaPatch(id: any, body: any) {
        const key = 'updatable';
        message.loading({content: 'Loading...', key});
        try {
            const {data}: { data: any } = await instance(`logs/fmcsa-edit/${id}`, {
                method: 'PATCH',
                data: body
            }).then(u => {
                setTimeout(() => {
                    message.success({content: 'Loaded!', key, duration: 2});
                }, 1000);
                return u;
            });
            return data;
        }catch (error: any){
            if (error.response && error.response.status ) {
                ErrorNotification({ status: error.response.status , message: error.response.data.error.message });
            }
        }
    },
    // async fmcsaPatchEVO(id: any, body: any) {
    //     const key = 'updatable';
    //     message.loading({content: 'Loading...', key});
    //     try {
    //         const {data}: { data: any } = await instanceEVO(`logs/fmcsa-edit/${id}`, {
    //             method: 'PATCH',
    //             data: body
    //         }).then(u => {
    //             setTimeout(() => {
    //                 message.success({content: 'Loaded!', key, duration: 2});
    //             }, 1000);
    //             return u;
    //         });
    //         return data;
    //     }catch (error: any){
    //         if (error.response && error.response.status ) {
    //             ErrorNotification({ status: error.response && error.response.status , message: error.response.data.error.message });
    //         }
    //     }
    // },
    async fmcsaCount() {
        const isAuthenticated = localStorage.getItem('token') as string;
        if (isAuthenticated) {
            const {data}: any = await instance(`logs/fmcsa-count`);
            return {
                data: data.data,
                count: data.count
            };
        }
    },

}