import React, {useContext} from 'react';
import {useApproval, useApprovalCount} from "../../../../Hooks/TT_ELD/Approval";
import ApprovalTable from "./ApprovalTable/ApprovalTable";
import {Button} from "antd";
import SearchInput from "../../../../Utils/SearchInput";
import RefreshContext from "../../../utils/context";
import useRouteQuery from "../../../../Utils/useRouterQuery";

const Approval = () => {
    const [skip, setSkip] = useRouteQuery("1", 'skip', (v) => String(v));
    const [company, setCompany] = useRouteQuery("", 'company', (v) => String(v));
    const [userName, setUserName] = useRouteQuery("", 'userName', (v) => String(v));
    const [userEmail, setUserEmail] = useRouteQuery("", 'userEmail', (v) => String(v));

    type Data = {
        data?: {
            data: any
            count: number
        },
        isLoading?: boolean,
        refetch?: any,
        isFetching?: any
    }

    const {data, refetch, isLoading, isFetching}: Data = useApproval(userName, userEmail, company, skip)
    const count = useApprovalCount()

    const onChange = (query: any) => {
        setSkip(query.current);
    }
    const handleInputChange = (value: any, field: any) => {
        switch (field) {
            case 'company':
                setCompany(value);
                break;
            case 'userName':
                setUserName(value);
                break;
            case 'userEmail':
                setUserEmail(value);
                break;
            default:
                break;
        }
        if (value) {
            setSkip('1');
        }
    };
    const handleChange = (event:string, inputName:string) => {
        const updateFunctions: Record<string, (value: string) => void> = {
            company: setCompany,
            userEmail: setUserEmail,
            userName: setUserName,
        };

        if (updateFunctions[inputName]) {
            updateFunctions[inputName](event.trim());
        }
    };


    const { refetchBtn, setRefetchBtn } = useContext(RefreshContext);
    const RefToggler = () => {
        setRefetchBtn(refetchBtn + 1);
    };
    const updates =  ()=>{
        refetch()
        count.refetch()
        RefToggler()
    }

    return (
        <div className='mainPage'>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <SearchInput value={company} onDebounceChange={(value:string) => handleChange(value, 'company')} placeholder={'Company Search'}/>
                <SearchInput value={userName}  onDebounceChange={(value:string) => handleChange(value, 'userName')} placeholder={'User name'}/>
                <SearchInput value={userEmail} onDebounceChange={(value:string) => handleChange(value, 'userEmail')} placeholder={'User email'}/>
                <Button
                    style={{marginLeft:'auto' }}
                    size={'large'} onClick={updates}>Refresh</Button>
            </div>

            <ApprovalTable data={data?.data} onChange={onChange} skip={skip} total={data?.count} isLoading={isLoading} isFetching={isFetching} refetch={updates}/>
        </div>
    );
};

export default Approval;