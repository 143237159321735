import React, { useState} from 'react';
import {
    certifyStatus,
    configNumberInputs,
    configs,
    coordinatesSource, inputNumber,
} from "../../../../../../Utils/data";
import {Input, Select, Space, Switch, Button, Spin, InputNumber, Row, Col, Card} from "antd";
import {useUserData} from "../../../../../../Hooks/TT_ELD/Users";
import {useParams} from "react-router-dom";
import {Field, Form} from "react-final-form";
import {users} from "../../../../../../API/TT_ELD/Users/user";
import {sendEvent} from "../../../../../../Utils/analytics";

const {Option} = Select;
type MyObjectType = {
    [key: string | number]: any;
};

interface Params {
    id: string | undefined;
    [key: string]: string | undefined;
}

type Data = {
    data: any
    isLoading?: boolean,
    refetch(): void,
}
const Config = () => {
    const {id} = useParams<Params>();
    const {data, isLoading, refetch}: Data = useUserData(id);
    const [loadings, setLoadings] = useState<boolean[]>([]);

    const getCommand = async (command: string) => {
        await users.command(data.id, command)
        enterLoading(1)
        refetch()
    }

    const enterLoading = (index: number) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });

        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };

    const onSubmit = async (values: any) => {
        const body: MyObjectType = {}
        let key: string | number
        let key2: string | number
        for (key in values) {
            if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] != null) {
                for (key2 in values[key]) {
                    if (data[key][key2] != values[key][key2]) {
                        body[key] = values[key]
                        break
                    }
                }
            } else if (data[key] != values[key]) {
                body[key] = values[key]
            }
        }
        await users.userDataPatch(id, body);
        sendEvent('Config Tab ', `Config ${values} ` , `Config Click ` ,1 )
        refetch()
    };

    return data ? (
        <Card>
            <Form onSubmit={onSubmit}
                  initialValues={data}
                  render={({handleSubmit, values}) => (
                      <form onSubmit={handleSubmit}>
                          <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                              <Row gutter={[16, 16]}>
                                  {configs.map((config, index) => {
                                      return data?.role === config.case &&  <Col span={8}>
                                          <Field key={data.id}
                                                 name={`config.${config.label}`}
                                                 render={({input}: { input: any }) => (
                                                     <Switch title={config?.title} defaultChecked={input.value} onChange={input.onChange}/>
                                                 )}
                                          />
                                          <label title={config?.title} style={{marginLeft: '15px'}}>{config.label}</label>
                                      </Col>
                                  })}
                              </Row>
                              {data?.role !== "dispatcher" && <>
                                  <Row gutter={[16, 16]}>
                                      {inputNumber.map((u, index) => {
                                          return  <Col span={8}>
                                              <div>
                                                  <label>{u.label}</label>
                                                  <Field
                                                      name={`config.${u.label}`}
                                                      render={({ input }: { input: any }) => {
                                                          const defaultValue = input?.value == null ? input?.value : u.defaultValue;
                                                          return (
                                                              <InputNumber
                                                                  title={u.title}
                                                                  style={{ width: '100%' }}
                                                                  placeholder={u.label}
                                                                  {...input}
                                                                  defaultValue={defaultValue}
                                                              />
                                                          );
                                                      }}
                                                  />
                                              </div>
                                          </Col>
                                      })}
                                  <Col span={8}>
                                  <div>
                                      <label>certify_status</label>
                                      <Field
                                          name="config.certify_status"
                                          render={({input}: { input: any }) => (
                                              <Select defaultValue={input.value} onChange={(value, option) => {
                                                  input.onChange(value)
                                              }} style={{width: '100%'}}>
                                                  {certifyStatus.map((data, index) => <Option
                                                      key={data.id}>{data.name}</Option>)}
                                              </Select>
                                          )}
                                      />
                                  </div>
                                  </Col>
                                  <Col span={8}>
                                  <div>
                                      <label>coordinates_source</label>
                                      <Field
                                          name="config.coordinates_source"
                                          render={({input}: { input: any }) => (
                                              <Select defaultValue={input.value} onChange={(value, option) => {
                                                  input.onChange(value)
                                              }} style={{width: '100%'}}>
                                                  {coordinatesSource.map((data, index) => <Option
                                                      key={data.id}>{data.name}</Option>)}
                                              </Select>
                                          )}
                                      />
                                  </div>
                                  </Col>
                                  <Col span={8}>
                                  <div>
                                      <label>tracking_source</label>
                                      <Field
                                          name="config.tracking_source"
                                          render={({input}: { input: any }) => (
                                              <Select defaultValue={input.value} onChange={(value, option) => {
                                                  input.onChange(value)
                                              }} style={{width: '100%'}}>
                                                  {coordinatesSource.map(data => <Option
                                                      key={data.id}>{data.name}</Option>)}
                                              </Select>
                                          )}
                                      />
                                  </div>
                                  </Col>
                                  {configNumberInputs.map((u, index) => {
                                      return  <Col span={8} key={index}>
                                          <label>{u}: </label>
                                          <Field
                                              name={u}
                                              render={({input}: { input: any }) => (
                                                  <Input placeholder={u} type="number" {...input} />
                                              )}
                                          />
                                      </Col>
                                  })}
                              </Row>
                              </>}



                              {/*{data?.role !== "dispatcher" &&  <>*/}
                              {/*    <Field name="driverInfo">*/}
                              {/*        {({ input }) => (*/}
                              {/*            <>*/}
                              {/*                <div style={{ marginTop: '10px' }}>*/}
                              {/*                    <Checkbox*/}
                              {/*                        checked={Object.keys(input.value || {}).length === plainOptions.length}*/}
                              {/*                        onChange={(e) => {*/}
                              {/*                            const newValue:any = {};*/}
                              {/*                            if (e.target.checked) {*/}
                              {/*                                plainOptions.forEach((opt:any) => {*/}
                              {/*                                    newValue[opt.value] = true;*/}
                              {/*                                });*/}
                              {/*                            }*/}
                              {/*                            input.onChange(newValue);*/}
                              {/*                        }}*/}
                              {/*                    >*/}
                              {/*                        Check all*/}
                              {/*                    </Checkbox>*/}
                              {/*                </div>*/}
                              {/*                <CheckboxGroup*/}
                              {/*                    options={plainOptions}*/}
                              {/*                    value={Object.keys(input.value || {}).filter((v) => input.value[v])}*/}
                              {/*                    onChange={(value) => {*/}
                              {/*                        const newValue:any = {};*/}
                              {/*                        value.forEach((v:any) => {*/}
                              {/*                            newValue[v] = true;*/}
                              {/*                        });*/}
                              {/*                        input.onChange(newValue);*/}
                              {/*                    }}*/}
                              {/*                />*/}

                              {/*            </>*/}
                              {/*        )}*/}
                              {/*    </Field>*/}

                              {/*</>}*/}
                              <div style={{display:'flex',marginTop: 20}}>
                                  <Button style={{marginRight:15}} type="primary" htmlType="submit" loading={loadings[0]}
                                          onClick={() => enterLoading(0)}>
                                      Submit
                                  </Button>
                                  {data.role === "driver" && <Button loading={loadings[1]} type="primary"
                                           onClick={() => getCommand('update_user_info')}>UPDATE USER INFO</Button>}
                              </div>

                          </Space>
                      </form>
                  )}
            />

        </Card>
    ) : <Spin size="large" spinning={isLoading}></Spin>
};

export default Config;