import React from 'react';
import {Button, Modal, Space, Spin, Table} from "antd";

import {userCompany} from "../../../../API/TT_ELD/userCompany";
import {useQueryClient} from "react-query";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {sendEvent} from "../../../../Utils/analytics";

const {confirm} = Modal;

type numStr = number | string;

interface userDataSource {
    no: number | string;
    role: string;
    company?: string;
    userName?: string | undefined;
    userEmail?: string | undefined;
    id?: number | string;
    userId?: number | string | undefined;
    delete?: { id: numStr, queryClient: any, };
    key: React.Key;

    // actions: number | string;
}


const UserCompanies = ({
                           userCompanies = [],
                           total = 0,
                           onChange,
                           isLoading,
                           isFetching,
                           open,
                           setOpen,
                           setIsModalBulk,
                           refetch,
                           skip,
                           setGetKey,
                       }: { userCompanies: Array<any> | undefined, total: number | undefined, onChange(current: any): void, isLoading: boolean | undefined, isFetching: any, open: boolean, setOpen(open: boolean): void, setIsModalBulk(isModalBulk: boolean): void, refetch(): void, skip: number | string | undefined |any, setGetKey(getKey: any): void}) => {

    const columns: object[] = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: 60
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: 150
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            width: 220

        },
        {
            title: 'UserName',
            dataIndex: 'userName',
            key: 'userName',
            width: 220
        },
        {
            title: 'UserEmail',
            dataIndex: 'userEmail',
            key: 'userEmail',
            width: 250
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 100
        },
        {
            title: 'UserId',
            dataIndex: 'userId',
            key: 'userId',
            width: 120
        },
        {
            title: 'Actions',
            dataIndex: 'delete',
            key: 'delete',
            width: 100,
            fixed: 'right',
            render: ({id}: { id: string | number }) => {

                const showConfirm = () => {
                    confirm({
                        title: 'User',
                        icon: <ExclamationCircleFilled rev={undefined}/>,
                        content: 'Do you want to delete this user ?',
                        onOk: async () => {
                            return new Promise(async (resolve, reject) => {
                                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                                userCompany.deleteUserCompany(id)
                                refetch()
                                sendEvent('UserCompany table', 'UserCompany ,  Removed User', `UserCompany table,  Removed User ` , '1');
                            }).catch(() => refetch());

                        },
                        onCancel() {

                        },
                    });
                };
                return (
                    <Space>
                        <Button onClick={showConfirm}>
                            Delete
                        </Button>
                    </Space>
                )

            }

        },

    ];
    const queryClient = useQueryClient();
    // const [getKey, setGetKey] = useState<React.Key[]>([])
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: userDataSource[]) => {
            if (selectedRows.length > 0) {
                setIsModalBulk(true)
            } else {
                setIsModalBulk(false)
            }
            setGetKey(selectedRowKeys)
        },
    };

    const driversLogs: Array<any> = [];
    userCompanies?.forEach((log: any) => {
        if (log?.user) {
            driversLogs.push(log)
        }
    })



    return (
        <div>
            <Spin size="large" spinning={isLoading || isFetching}>
                {/*<BulkEditUser getKey={getKey} open={open} setOpen={setOpen} refetch={refetch}/>*/}
                <Table onChange={onChange} dataSource={userCompanies?.map((u: any, i: number): userDataSource => {
                    const obj: userDataSource = {
                        no: i + 1,
                        role: u.role,
                        company: u?.company?.name,
                        userName: u?.user?.first_name + ' ' + u?.user?.second_name,
                        userEmail: u?.user?.email,
                        id: u.id,
                        userId: u?.userId,
                        delete: {id: u.id, queryClient},
                        key: u.id
                    }
                    return obj;
                })} columns={columns} pagination={{total: total,showSizeChanger: false, current: skip}}
                    rowSelection={{
                        type:'checkbox',
                        ...rowSelection,
                    }}
                       scroll={{x: 1000}} sticky
                />
            </Spin>
        </div>
    )
};

export default UserCompanies;
