import {supports} from "../../../API/TT_ELD/supports";
import {useQuery} from "react-query";
import {dailies} from "../../../API/TT_ELD/daily";
import {
    getAllLogs
} from "../../../Component/features/TT_ELD_Admin/CompanyErrors/CompanyErrors/SupportHistory/SupportErrors/SupportErrors";
// import {getAllLogs} from "../../../Component/features/TT_ELD_Admin/CompanyErrors/CompanyErrors/SupportHistory/SupportErrors/SupportErrors";

export const useSupportFindUsers = async (name: string) => {
    return await supports.userFinder(name);
}

export const useDriverCompanyFinder = async (name: any) => {
    return await dailies.driverCompanyFinder(name)
}

export const useSupportData = ( id: undefined | number, skip: undefined | string): object => {
    return useQuery([
            `supports/getcompanies${id}`,  id, skip],
        () => supports.readSupportVue( id, skip),
        {refetchOnWindowFocus: false})
}

export const useSupporFiltertData = (companyId: undefined | number, id: undefined | number, skip: undefined | number): object => {
    return useQuery([
            `supportsFiltertData/${companyId}`, companyId, id, skip],
        () => supports.readSupportFilter(companyId, id, skip),
        {refetchOnWindowFocus: false})
}

export const useGetCompanyById = (companyId: undefined | number,): object => {
    return useQuery([
            `companies/${companyId}`, companyId],
        () => supports.getCompanyById(companyId),
        {refetchOnWindowFocus: false})
}

export const useCompanyHistory = (companyId: number): object => {
    return useQuery([
            `supportsHistory/${companyId || 'all'}`, companyId],
        () => supports.getCompanyHistory(companyId),
        {refetchOnWindowFocus: false})
}

export const useGetLogsById = (companyId: number | string, dateStart: string | undefined, tomorrowDate: string | undefined): object => {
    return useQuery([
            `supportsGetLogsById/${companyId || 'all'}`, companyId, dateStart, tomorrowDate],
        () => supports.getLogsById(companyId, dateStart, tomorrowDate),
        {refetchOnWindowFocus: false})
}

export const useFindDriver = ( companyId: number | string , skip:string |number ):object => {
    return useQuery([
            `users/${companyId}`, companyId ,skip],
        () => supports.getActiveDrivers(companyId, skip),
        {refetchOnWindowFocus: false})
}

export const useGetMalfunctions= (startData:any,companyId:string , tz:any): object => {
    return useQuery([
            `supportsGetMalfunctions/${startData}`,startData,companyId , tz],
        () => supports.getMalfunctions(startData, companyId ,tz),
        { refetchOnWindowFocus: false })
}

export const useDispatcher= (dataObj: any): object => {
    return useQuery([
            `supportsGetMalfunctions/${dataObj}`,dataObj],
        () => supports.loginDispatcher(dataObj),
        { refetchOnWindowFocus: false})
}


// export const useGetAllLogs2= (): object => {
//     return useQuery([
//             `getAllLogs/`],
//         () => getAllLogs(),
//         { refetchOnWindowFocus: false})
// }
export const useGetAllLogs= (companyId:string,todayDate:string, cursor:number |string): object => {
    return useQuery([
            `getAllLogs/${companyId}`,companyId, todayDate ,cursor], () => supports.logsList(companyId,todayDate,cursor),
        { refetchOnWindowFocus: false})
}



// export const useFindOne = ( companyId: number | string, dispatcherId :number | string):object => {
//     return useQuery([
//             `users/${companyId}`, companyId, dispatcherId],
//         () => supports.getFindOne(companyId,dispatcherId),
//         {refetchOnWindowFocus: false, staleTime: 5 * 60 * 1000})
// }