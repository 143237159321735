import React, { useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Field, Form} from "react-final-form";
import {Button, Col, Input, Row, Select, Space, Switch} from "antd";
import AddressSearch from "./AddressSearch";
import {SearchAddress} from "../../UtilsTMS/SearchResults";
import {useFindAddress2} from "../../../../../Hooks/cleverTms/CustomerController";
import {customerController} from "../../../../../API/Tms/customerController";

const customerInputs = [
    "name",
    "city",
    "state",
    "notes",
    "placeId",
    "zipCodes",
    "phone",
]
const customerNumber = [
    "companyId"
]
const AddCustomerController = () => {
    const [address, setAddress] = useState<undefined | number | string>('');
    const [address1, setAddress1] = useState<undefined | number | string>('');
    const [address2Options, setAddress2Options] = useState<any>([]);
    const [address2, setAddress2] = useState<undefined | number | string>('');
    const [location, setLocation] = useState<any>([]);


    useFindAddress2(address1).then((res: any) => {
        if(res && res?.address_components.length >= 1) {
            const addresses = res?.address_components.map((el: any) => {
                const category = `${el.long_name}`;
                return {
                    value: category,
                    label: el.long_name
                };
            })

            setAddress2Options(addresses)
            setAddress1('')
        }
        if(res && res?.geometry.location){
            const location = res.geometry.location
            setLocation(location)
        }
    });

    type numStr = number | string;

    interface Fields {
        lat: number,
        lng: number,
        address: numStr | undefined,
        address2: numStr | undefined,
        name: string,
        city: string,
        state: string,
        coordinates: numStr,
        updatedAt: numStr,
        createAt: numStr,
        notes: numStr,
        placeId: numStr,
        zipCodes: numStr,
        phone: numStr,
        isActive: boolean,
        companyId: numStr,
        action: numStr,
    }

    let navigate = useNavigate();
    const onSubmit = async (data: Fields) => {

        data.address = address
        data.address2 = address2
        await customerController.addCustomerController(data)
        navigate(-1)
    };


// render={({handleSubmit, values}) => (
//     <form onSubmit={handleSubmit}>
//         <Space direction="vertical" size="middle" style={{display: 'flex'}}>
//             <h1>Add Location</h1>
//             <Space direction="vertical" size="middle"
//                    style={{display: 'flex',}}>
//                 <Row gutter={[16, 20]}>
//
    const required = (value:any) => (value ? undefined : 'Required')


    return (
        <div className='mainPage'>
            {<Form onSubmit={onSubmit}
                   render={({handleSubmit, submitting}) => (
                       <form onSubmit={handleSubmit}>
                           <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                               <h1>Add Location</h1>
                               <Space direction="vertical" size="middle"
                                      style={{display: 'flex',}}>
                                   <Row gutter={[16, 20]}>
                                       <Col className="gutter-row" span={6}>
                                           <label> address 1:</label>
                                           <AddressSearch
                                               SearchResult={SearchAddress}
                                               onSelect={async (value: any, {valId}: { valId: string | number | undefined }) => {
                                                    setAddress(value);
                                                    setAddress1(valId);
                                               }}
                                               placeholder="address1 Search"
                                           />
                                       </Col>
                                       { <Col className="gutter-row" span={6}>
                                           <label> address 2:</label>
                                           <Select
                                               disabled={!address}
                                               options={address2Options}
                                               style={{ width: "100%" }}
                                               placeholder="choose address2"
                                               onChange={(value: string) => {
                                                    setAddress2(value);
                                               }}
                                           />
                                       </Col>}

                                       {customerInputs.map(u => {
                                           return <Col key={u} className="gutter-row" span={6}>
                                               <label>{u}: </label>
                                               <Field validate={required}
                                                   name={u}
                                                   render={({input, meta}: { input: any , meta:any}) => (
                                                       <div>
                                                           <Input {...input} type='text' placeholder={u}/>
                                                           {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                       </div>
                                                   )}
                                               ></Field>

                                           </Col>
                                       })
                                       }
                                       {customerNumber.map(u => {
                                           return <Col key={u} className="gutter-row" span={6}>
                                               <label>{u}: </label>
                                               <Field validate={required}
                                                   name={u}
                                                      render={({input , meta}: { input: any , meta:any}) => (
                                                          <div>
                                                              <Input {...input} type='number' placeholder={u}/>
                                                              {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                          </div>
                                                      )}
                                               ></Field>
                                           </Col>
                                       })
                                       }
                                       <Col  className="gutter-row" span={6}>
                                           <label>lat: </label>
                                           <Field validate={required}
                                                  name='lat'

                                                  initialValue={location?.lat}
                                                  render={({input , meta}: { input: any , meta:any}) => (
                                                      <div>
                                                          <Input disabled={!address} {...input} type='number' placeholder='lat'/>
                                                          {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                      </div>
                                                  )}
                                           ></Field>
                                       </Col>
                                       <Col  className="gutter-row" span={6}>
                                           <label>lng: </label>
                                           <Field validate={required}
                                                  name='lng'
                                                  initialValue={location?.lng}
                                                  render={({input , meta}: { input: any , meta:any}) => (
                                                      <div>
                                                          <Input disabled={!address} {...input} type='number' placeholder='lng'/>
                                                          {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                      </div>
                                                  )}
                                           ></Field>
                                       </Col>
                                   </Row>
                                   <div>
                                       <label>isActive: </label>
                                       <Field
                                           name="isActive"
                                           render={({input}: { input: any }) => (
                                               <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                           )}
                                       />
                                   </div>
                                   <div className="buttons">
                                       <Button disabled={submitting} type="primary" htmlType="submit">
                                           Submit
                                       </Button>
                                   </div>
                               </Space>
                           </Space>
                       </form>
                   )
                   }
            />
            }
        </div>
    )
}

export default AddCustomerController