import React from 'react';
import {Button, message, Modal, Space, Spin, Table} from "antd";
import {Link} from "react-router-dom";
import {ExclamationCircleFilled, ExclamationCircleOutlined} from "@ant-design/icons";
import {makesList, modelList} from "../../../../../../API/TT_ELD/Users/make_moddel";
import {approval} from "../../../../../../API/TT_ELD/approval";

const {confirm} = Modal;

type numStr = string | number

interface makeSource {
    no: numStr,
    id: numStr
    name: string
    createdAt: string
    make: numStr
    makeId: numStr,
    action: { id: numStr };
    key: React.Key;
}


const MakeTable = ({
                       make = [],
                       total = 0,
                       onChange,
                       isLoading,
                       isFetching,
                       refetch,
                       skip
                   }: { make: Array<any> | undefined, total: number | undefined, onChange(current: any): void, isLoading: boolean | undefined, isFetching: boolean, refetch(): void, skip: string | number | any }) => {
    const column: object[] = [
        {
            title: 'no',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Makes',
            dataIndex: 'name',
            key: 'name',

        },

        {
            title: 'createdAt',
            dataIndex: 'createdAt',
            key: 'createdAt',

        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: ({id}: { id: string | undefined }) => {
                const showConfirm = () => {
                    confirm({
                        title: 'Approval',
                        icon: <ExclamationCircleFilled rev={undefined} />,
                        content: 'Do you want to delete this make ?',
                        onOk: async () => {
                            return new Promise(async (resolve, reject) => {
                                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                                makesList.makePatch(id, {"isActive": false})
                                refetch()

                            }).catch(() => {
                                setTimeout(() => {
                                    refetch()
                                })
                            });

                        },
                        onCancel() {
                        },
                    });
                };
                return (
                    <Space>
                        <Link to={`${id}`}><Button>Edit</Button></Link>
                        {/*<Button  onClick={showConfirm}>*/}
                        {/*    Delete*/}
                        {/*</Button>*/}
                    </Space>)
            }

        },


    ]

    return (
        <div>
            <Spin size='large' spinning={isLoading || isFetching}>
                <Table onChange={onChange} dataSource={make?.map((u: any, i: number): makeSource => {
                    const obj: makeSource = {
                        no: i + 1,
                        id: u.id,
                        name: u?.name,
                        createdAt: u?.createdAt,
                        make: u.make?.name,
                        makeId: u.makeId,
                        action: {id: u.id},
                        key: u.id
                    }
                    return obj
                })} columns={column} pagination={{total: total,showSizeChanger: false, current: skip}}

                />
            </Spin>
        </div>
    );
};

export default MakeTable;
