import React, {ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Input} from "antd";
const { Search } = Input;

const SearchInput = ({onChange, placeholder, value ,onDebounceChange}: {onChange?:Dispatch<SetStateAction<string | number | undefined | any>>, placeholder:undefined | string, value?:string , onDebounceChange?: any}) => {

    const [state, setState] = useState(value);
    useEffect(() => {
        setState(value);
    }, [value]);

    let timeoutId: NodeJS.Timeout | null = null;

    const debounceChange = useCallback((value: string) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            onDebounceChange(value);
        }, 1000);
    }, []);

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        setState(event.target.value);
        if (onChange) {
            onChange(event.target.value);
        }
        debounceChange(event.target.value);
    };

    return (
        <Search style={{width:300}} size='middle' value={state}  onChange={handleInput} placeholder={placeholder} allowClear enterButton />
    );
};

export default SearchInput