import React, {useState} from 'react';
import SearchInput from "../../../../../Utils/SearchInput";
import {Button} from "antd";
import { useApprovalCount, useRejected} from "../../../../../Hooks/TT_ELD/Approval";
import RejectedListTable from "./RejectedListTable";
import useRouteQuery from "../../../../../Utils/useRouterQuery";

const RejectedList = () => {

    const [skip, setSkip] = useState<string | number>(1);
    // const [userName, setUserName] = useState<string >('');
    // const [userEmail, setUserEmail] = useState<string >('');
    // const [company, setCompany] = useState<string | number>('');

    const [userName, setUserName] = useRouteQuery("", 'userName', (v) => String(v));
    const [userEmail, setUserEmail] = useRouteQuery("", 'userEmail', (v) => String(v));
    const [company, setCompany] = useRouteQuery("", 'company', (v) => String(v));

    const handleChange = (event:string, inputName:string) => {
        const updateFunctions: Record<string, (value: string) => void> = {
            userName: setUserName,
            userEmail: setUserEmail,
            company: setCompany,
        };

        if (updateFunctions[inputName]) {
            updateFunctions[inputName](event.trim());
        }
    };
    type Data = {
        data?: {
            data: any
            count: number
        },
        isLoading?: boolean,
        refetch?: any,
        isFetching?: any
    }
    const {data, refetch, isLoading, isFetching}: Data = useRejected(userName, userEmail, company, skip)
    const count = useApprovalCount()

    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)));
    }
    const updates =  ()=>{
        refetch()
        count.refetch()
    }
    return (
        <div className='mainPage'>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <SearchInput value={company} onDebounceChange={(event:string) => handleChange(event, 'company')} placeholder={'Company Search'}/>
                <SearchInput value={userName} onDebounceChange={(event:string) => handleChange(event, 'userName')} placeholder={'User name'}/>
                <SearchInput value={userEmail} onDebounceChange={(event:string) => handleChange(event, 'userEmail')} placeholder={'User email'}/>
                <Button
                    style={{marginLeft:'auto' }}
                    size={'large'} onClick={updates}>Refresh</Button>
            </div>

            <RejectedListTable data={data?.data} onChange={onChange} total={data?.count} isLoading={isLoading} isFetching={isFetching} refetch={updates}/>
        </div>
    );
};

export default RejectedList;