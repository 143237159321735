import {useQuery} from "react-query";
import {dailies} from "../../../API/TT_ELD/daily";

export const useDailiesData = ({ date, filter, countFilter }: {date: string | undefined, filter: object, countFilter: object}): object => {
    return useQuery([
        `dailies/${date}`, date, filter],
        () => dailies.read({filter, countFilter, date}),
        { refetchOnWindowFocus: false })
}

export const useDailiesFindCompany = async ({ name, id }: {name: string | undefined, id: string | number | undefined}) => {
    name = '%' + name + '%';
    return await dailies.companyFinder({name, id})
}
export const useDailiesFind = async (query:string) => {
    return await dailies.usdotFinder(query)
}
export const useDailiesFindDriver = async (name: string) => {
    return await dailies.driverFinderLogs(name);
}

export const useDailiesFindDriverEmail = async ({name,  companyId, role}: {name: string,companyId: number | undefined, role: string | undefined}) => {
    name = '%' + name + '%';
    return await dailies.driverFinderEmail({name, companyId, role});
}
export const useDailiesFindDriverUser = async (name: any, id: string |number) => {
    if (typeof name === 'string') {
        name = name.trim();
    }
    return await dailies.driverFinderUser(name,id)
}
export const useDailiesFindDriverEmails = async (name: any, id: string |number) => {
    if (typeof name === 'string') {
        name = name.trim();
    }
    return await dailies.driverFinderEmails(name, id)
}
export const useDriverCompanyFinder = async (name: any) => {
    if (typeof name === 'string') {
        name = name.trim();
    }
    return await dailies.driverCompanyFinder(name)
}

