import React, { useState} from 'react';
import {useMakes} from "../../../../../../Hooks/TT_ELD/Makes-Models";
import MakeTable from "./MakeTable";
import {Button} from "antd";
import AddMakeList from "./AddMakeList";
import SearchInput from "../../../../../../Utils/SearchInput";
import useRouteQuery from "../../../../../../Utils/useRouterQuery";

type Data = {
    data?: {
        data: Array<any>
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any
}


const Makes = () => {
    const showModal = () => {
        setOpen(true)
    };
    const [open, setOpen] = useState(false);
    const [makeName, setMakeName] = useRouteQuery("", 'makeName', (v) => String(v));
    const [skip, setSkip] = useState<string | number>(1);
    const onChange = (query: any) => {setSkip(1 * (parseInt(query.current)))}
    const {data,isLoading, isFetching, refetch}: Data = useMakes(makeName, skip)

    const handleChange = (event:string, inputName:string) => {
        const updateFunctions: Record<string, (value: string) => void> = {
            makeName: setMakeName,
        };

        if (updateFunctions[inputName]) {
            updateFunctions[inputName](event.trim());
        }
    };

    return (
        <div className='mainPage'>
              <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  {open &&
                      <AddMakeList  open={open} setOpen={setOpen} refetch={refetch}/> }
                  <SearchInput value={makeName} placeholder="Makes Search" onDebounceChange={(event:string) => handleChange(event, 'makeName')}/>
                   <Button type="primary" style={{marginLeft: 'auto'}} size={'large'}
                           onClick={showModal}>ADD Make</Button>
                  <Button size={'large'} style={{marginLeft: '15px'}} onClick={refetch}>Refresh</Button>
            </span>
            <MakeTable make={data?.data} total={data?.count} onChange={onChange} isLoading={isLoading} isFetching={isFetching} refetch={refetch} skip={skip}/>
        </div>
    );
};

export default Makes;
