import {Col, Input, Row, Select, Space, Spin, Switch} from "antd";
import React from "react";
import {Field} from "react-final-form";
import {useCompanyData, useVehicleData} from "../../../../../../../../Hooks/TT_ELD/Companies";
import {useVehicleSearch} from "../../../../../../../../Hooks/TT_ELD/Logs";

const IdFields = ({ data }: { data: any }) => {
    const vehicleData: any = useVehicleSearch('', data?.companyId);

    return !vehicleData.isLoading ? (
        <div>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <Row gutter={16}>
                    <Col span={8}>
                        <div>
                            <label>Id: </label>
                            <Field
                                name="id"
                                render={({input}: { input: any }) => (
                                    <Input disabled type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>Company Id: </label>
                            <Field
                                name="companyId"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>Driver Id: </label>
                            <Field
                                name="driverId"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={8}>
                        <div>
                            <label>VehicleId: </label>
                            <Field
                                name="vehicleId"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>ClientId: </label>
                            <Field
                                name="client_id"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>EldId: </label>
                            <Field
                                name="eldId"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                </Row>

                <Row gutter={16}>
                    {/*<Col span={8}>*/}
                    {/*    <div>*/}
                    {/*        <label>Origin Code: </label>*/}
                    {/*        <Field*/}
                    {/*            name="origin_code"*/}
                    {/*            render={({input}: { input: any }) => (*/}
                    {/*                <Input type="text" {...input} />*/}
                    {/*            )}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</Col>*/}

                </Row>
            </Space>
        </div>
    ) : <Spin size="large" spinning={vehicleData?.isLoading}></Spin>
};

export default IdFields;