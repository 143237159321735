import React, {useEffect, useState} from 'react';
import {Button, Modal, Space, Spin, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {loadController} from "../../../../../API/Tms/loadController";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useLoadsData} from "../../../../../Hooks/cleverTms/LoadController";
import {useQueryClient} from "react-query";

type numStr = number | string;

interface cotrollerSource {
    no: numStr;
    companyId: numStr
    driver: string;
    driverId: number;
    orderNumber: numStr
    isActive: boolean;
    status: string;
    origin: string;
    action: { id: numStr, queryClient: any, };
    key: React.Key;

}


const columns: object[] = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        sorter: {
            compare: (a: any, b: any) => a.no - b.no,
            multiple: 3,
        },

    },
    {
        title: 'CompanyId',
        dataIndex: 'companyId',
        key: 'companyId',
        sorter: {
            compare: (a: any, b: any) => a.no - b.no,
            multiple: 3,
        },

    },
    {
        title: 'DriverName',
        dataIndex: 'driver',
        key: 'driver',
        sorter: (a: any, b: any) => a.driver.length - b.driver.length,

    },
    {
        title: 'Driver Id',
        dataIndex: 'driverId',
        key: 'driverId',
        sorter: {
            compare: (a: any, b: any) => a.driverId - b.driverId,
        },
    },
    {
        title: 'Order Number',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        sorter: {
            compare: (a: any, b: any) => a.orderNumber - b.orderNumber,
            multiple: 3,
        },
    },
    {
        title: 'Is Active',
        dataIndex: 'user',
        key: 'isActive',
        render: (tag: boolean) => (
            <Tag color={tag ? 'geekblue' : 'red'}>
                {tag ? 'True' : 'False'}
            </Tag>
        ),
        filters: [
            {
                text: 'True',
                value: true,
            },
            {
                text: 'False',
                value: false,
            },

        ],
        onFilter: (value: any, record: any) => {
            return record.isActive === value

        },
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a: any, b: any) => a.status.length - b.status.length,
    },
    {
        title: 'Origin',
        dataIndex: 'origin',
        key: 'origin',
        sorter: (a: any, b: any) => a.origin.length - b.origin.length,
    },

    {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        render: ({id, queryClient}: { id: string | number, queryClient: any }) =>
            <Space>
                <Link to={`${id}`}><Button>Edit</Button></Link>
                <Button onClick={() => {
                    Modal.confirm({
                            title: 'LoadController',
                            icon: <ExclamationCircleOutlined rev={undefined}/>,
                            content: 'Do you want to delete this load ?',
                            okText: 'Ok',
                            cancelText: 'cancel',
                            onOk: async () => {
                                await loadController.deleteLoadController(id)
                                queryClient.refetchQueries(useLoadsData);
                            }
                        }
                    );
                }
                }
                >Delete
                </Button>
            </Space>

    },
]
const LoadControllerTable = ({
                                 controller = [],
                                 total = 0,
                                 onChange,
                                 skip
                             }: { controller: Array<any> | undefined, total: number | undefined, onChange(current: any): void , skip: any}) => {

    const queryClient = useQueryClient();
    return (
        <div>
            <Spin size='large' spinning={!controller?.length}>
                <Table onChange={onChange} dataSource={controller?.map((u: any, i: number): cotrollerSource => {
                    const obj: cotrollerSource = {
                        no: i + 1,
                        companyId: u.companyId,
                        driver: `${u.driver?.firstName} ${u.driver?.secondName}`,
                        driverId: u.driverId,
                        orderNumber: u.orderNumber,
                        isActive: u.isActive,
                        status: u.status,
                        origin: u.origin,
                        action: {id: u.id, queryClient},
                        key: u.id
                    }
                    return obj
                })} columns={columns} pagination={{total: total,  current: skip}}

                />
            </Spin>
        </div>
    );
};

export default LoadControllerTable;