import {useQuery} from "react-query";
import {fmcsa} from "../../../API/TT_ELD/fmcsa";
import {approval} from "../../../API/TT_ELD/approval";

export const useFMCSA = ( skip:string | number): object => {
    return useQuery([
            `fmcsaMain/${ skip || 'all'}`,skip],
        () => fmcsa.read(skip),
        { refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000 })
}

export const useFmcsaCount = (): any => {
    return useQuery([
            `fmcsaCount/all`],
        () => fmcsa.fmcsaCount(),
        { refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000 })
}