import React, { useState} from 'react';
import FMCSA_Table from "./FMCSA_Table/FMCSA_Table";
import {useFMCSA, useFmcsaCount} from "../../../../Hooks/TT_ELD/FMCSA";


type Data = {
    data?: {
        data: any
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: boolean
}

const Fmcsa = () => {
    const [skip, setSkip] = useState<string | number>(1);
    const {data, refetch, isLoading, isFetching}: Data = useFMCSA(skip)
    const count = useFmcsaCount()
    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)));
    }

    const updates = () => {
        refetch()
        count.refetch()
    }

    return (
        <div className='mainPage'>
            <FMCSA_Table data={data?.data} onChange={onChange} total={data?.count} isLoading={isLoading}
                         isFetching={isFetching} refetch={updates}/>
        </div>

    );
};

export default Fmcsa;