import React, { useState} from 'react';
import {useUserData} from "../../../../../../Hooks/TT_ELD/Users";
import {useParams} from "react-router-dom";
import {Button, Card, Col, Space, Spin, Switch,} from "antd";
import {Field, Form} from "react-final-form";
import {users} from "../../../../../../API/TT_ELD/Users/user";
import {sendEvent} from "../../../../../../Utils/analytics";

interface Params {
    id: string | undefined;

    [key: string]: string | undefined;
}

type Data = {
    data: any
    isLoading?: boolean,
    refetch(): void,
}
type MyObjectType = {
    [key: string | number]: any;
};


const Settings = () => {
    const [loadings, setLoadings] = useState<boolean[]>([]);
    const enterLoading = (index: number) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });

        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };

    const {id} = useParams<Params>();

    const {data, isLoading, refetch}: Data = useUserData(id);
    const onSubmit = async (values: any) => {
        const body: MyObjectType = {}
        let key: string | number
        let key2: string | number
        for (key in values) {
            if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] != null) {
                for (key2 in values[key]) {
                    if (data[key][key2] != values[key][key2]) {
                        body[key] = values[key]
                        break
                    }
                }
            } else if (data[key] != values[key]) {
                body[key] = values[key]
            }
        }
        await users.userDataPatch(id, body);
        sendEvent('Settings Tab ', `Settings ${values} ` , `Settings Click ` ,1 )

        await users.userDataPatch(id, body)

        refetch()
    };
    return (
        <Card>
            <Spin size="large" spinning={isLoading}>
                {data && <Form onSubmit={onSubmit}
                               initialValues={{...data,}}
                               render={({handleSubmit, values}) => (
                                   <form onSubmit={handleSubmit}>
                                       <Space direction="vertical" size="middle" style={{display: 'flex'}}>

                                           <Col span={16}>
                                               {/*/!*<div>*!/*!/*/}
                                               {/*                                /!*    <label>enable_personal_conveyance: </label>*!/*/}
                                               {/*                                /!*    <Field*!/*/}
                                               {/*                                /!*        name="settings.enable_personal_conveyance"*!/*/}
                                               {/*                                /!*        render={({input}: { input: any }) => (*!/*/}
                                               {/*                                /!*            <Switch defaultChecked={input.value}*!/*/}
                                               {/*                                /!*                    onChange={input.onChange}/>*!/*/}
                                               {/*                                /!*        )}*!/*/}
                                               {/*                                /!*    />*!/*/}
                                               {/*                                /!*</div>*!/*/}
                                               <div>
                                                   <label>enable_personal_conveyance: </label>
                                                   <Field
                                                       name="settings.enable_personal_conveyance"
                                                       render={({input}: { input: any }) => (
                                                           <Switch defaultChecked={input.value}
                                                                   onChange={input.onChange}/>
                                                       )}
                                                   />
                                               </div>
                                           </Col>

                                           <Col span={16}>

                                               <div>
                                                   <label>enable_ssb: </label>
                                                   <Field
                                                       name="settings.enable_ssb"
                                                       render={({input}: { input: any }) => (
                                                           <Switch defaultChecked={input.value}
                                                                   onChange={input.onChange}/>
                                                       )}
                                                   />
                                               </div>
                                           </Col>
                                           <div className="buttons">
                                               <Button type="primary" htmlType="submit" loading={loadings[0]} onClick={() => enterLoading(0)}>
                                                   Submit
                                               </Button>
                                           </div>
                                       </Space>
                                   </form>
                               )}
                />
                }

            </Spin>
        </Card>
    );
};

export default Settings;