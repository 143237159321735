import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Space, Select, Spin, Tabs} from "antd";
import {useUserData} from "../../../../../Hooks/TT_ELD/UserCompanies";
import {Form, Field} from "react-final-form";
import {userCompany} from "../../../../../API/TT_ELD/userCompany";
import {AppleOutlined} from "@ant-design/icons";
import {Option} from "antd/es/mentions";
import {ROLELIST} from "../../../../../Utils/permissionConstants";

const TabPane = Tabs.TabPane;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
    refetch?: any
}
type params = {
    readonly id: any
}

interface Values {
    role?: string;
}


const UserCompaniesEdit = () => {
    let navigate = useNavigate();
    const [companyData, setCompanyData] = useState<any>();
    const {id} = useParams<params>();
    const {data, refetch}: Data = useUserData(id)

    const onSubmit = async (values: Values) => {
        navigate('/user-companies')
        await userCompany.userCompanyPatch(values);
        refetch()
    };
    useEffect(() => {
        if (data) {
            setCompanyData(data)
        }
    }, [data])
    return (
        <div>
            <Spin size="large" spinning={!data}>
                {companyData && <Form onSubmit={onSubmit}
                                      initialValues={{...companyData,}}
                                      render={({handleSubmit, values}) => (
                                          <form onSubmit={handleSubmit}>
                                              <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                                  <Tabs defaultActiveKey="1">
                                                      <TabPane
                                                          tab={
                                                              <span>
                                          <AppleOutlined rev={undefined}/>
                                          MAIN FIELDS
                                           </span>
                                                          }
                                                          key="1"
                                                      >
                                                          <Col span={6}>
                                                              <div>
                                                                  <label>Role</label>
                                                                  <Field
                                                                      name="role"
                                                                      render={({input}: { input: any }) => {
                                                                          return <Select defaultValue={input.value}
                                                                                         onChange={(value, option) => {
                                                                                             input.onChange(value)
                                                                                         }} style={{width: '100%'}}>
                                                                              {ROLELIST.map((u: any, index: any) =>
                                                                                  <Option key={index}
                                                                                          value={u.value}>{u.label}</Option>)}
                                                                          </Select>
                                                                      }}
                                                                  />
                                                              </div>
                                                          </Col>
                                                      </TabPane>
                                                  </Tabs>
                                                  <div className="buttons">
                                                      <Button type="primary" htmlType="submit">
                                                          Submit
                                                      </Button>
                                                  </div>
                                              </Space>
                                          </form>
                                      )}
                />
                }

            </Spin>
        </div>
    );
};

export default UserCompaniesEdit;