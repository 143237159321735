import React, { useState } from 'react'
import { useCustomerData } from '../../../../Hooks/cleverTms/CustomerController'
import CustomerControllerTable from './CustomerControllerTable/CustomerControllerTable'
import SearchOptions from "../../../../Utils/SearchOptions";
import { SearchResultForCustomer} from "../UtilsTMS/SearchResults";
import {SearchResultForCompany} from "../../../../Utils/SearchResults";
import {Button} from "antd";
import {Link} from "react-router-dom";


type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading? :boolean
}
const CustomerController = () => {
    const [skip, setSkip] = useState(0);
    const [city, setCity] = useState<string | number | undefined>('')
    const [companyId, setCompanyId] = useState<undefined | number |string>('');


    const {data,isLoading}: Data = useCustomerData(city,companyId);
    const onChange = (query: any) => {
        setSkip(10 * (parseInt(query.current) - 1));
    }
  return (
        <div className='mainPage'>

        <SearchOptions SearchResult={SearchResultForCompany}
                onSelect={(value: any, {valId}: {valId: number | undefined}) =>
                {setCompanyId(valId);}}
                placeholder={'Company Search'}  value={''}/>
        <SearchOptions SearchResult={SearchResultForCustomer}
                onSelect={(value: any, {valId}: { valId: string | number | undefined }) => {
                    setCity(valId);
                }} placeholder="City Search"  value={''}/>
        <Link to={'add-location'}>
            <Button type="primary" style={{float: "right"}} size={'large'}
            >
                ADD Location
            </Button>
        </Link>
        <CustomerControllerTable customer={data?.data} total={data?.count} onChange={onChange} isLoading={isLoading}/>

    </div>
  )
}

export default CustomerController