import React, { useState} from 'react';
import {Button, Select} from "antd";
import {useMakeSelect, useModel} from "../../../../../../Hooks/TT_ELD/Makes-Models";
import ModelTable from "./ModelTable";
import AddModelList from "./AddModelList";
import SearchOptions from "../../../../../../Utils/SearchOptions";
import {SearchResultForModel} from "../../../../../../Utils/SearchResults";
import useRouteQuery from "../../../../../../Utils/useRouterQuery";


type Data = {
    data?: {
        data: Array<any>
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any
}

const Model = () => {
    const [makeId, setMakeId] = useRouteQuery("", 'makeId', (v) => String(v));
    const [modelName, setModelName] = useRouteQuery("", 'modelName', (v) => String(v));
    // const [makeId, setMakeId] = useState<undefined | number | string>('');
    // const [modelName, setModelName] = useState<string | number | undefined>('');
    const [skip, setSkip] = useState<string | number>(1);
    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)))
    }

    const {data, isLoading, isFetching, refetch}: Data = useModel(modelName, makeId, skip)
    const make: Data = useMakeSelect()

    const showModal = () => {
        setOpen(true)
    };
    const [open, setOpen] = useState(false);


    return (
        <div className='mainPage'>
             <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  {open && <AddModelList open={open} setOpen={setOpen} dataRefetch={refetch}/>}
                 <SearchOptions SearchResult={SearchResultForModel}
                                onSelect={(value: any, {valId}: { valId: any }) => {
                                    setModelName(valId === undefined ? '' : valId);
                                    if (valId) {
                                        setSkip(1)
                                    }
                                }} placeholder='Model Search' value={makeId} />

                 {make?.data?.data && <Select
                     allowClear
                     style={{width: 200}}
                     showSearch
                     placeholder="Select a make"
                     optionFilterProp="children"
                     onChange={(value, option) => {
                         setMakeId(value === 'all' ? undefined : value)
                         if (value) {
                             setSkip(1)
                         }
                     }}
                     filterOption={(input, option: any) =>
                         (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                     }
                     options={make?.data?.data?.map((option) => ({
                         value: option.id,
                         label: option.name,
                     }))}
                 />}
                 <Button type="primary" style={{marginLeft: 'auto'}} size={'large'}
                         onClick={showModal}>ADD Model</Button>
                  <Button size={'large'} style={{marginLeft: '15px'}} onClick={refetch}>Refresh</Button>
             </span>
            <ModelTable model={data?.data} total={data?.count} onChange={onChange} isLoading={isLoading}
                        isFetching={isFetching} refetch={refetch}/>
        </div>
    );
};

export default Model;