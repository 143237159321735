import React, {useState} from 'react';
import {Button, Card, Checkbox, Col, DatePicker, Form as FormAnt, Row, Select, Space} from "antd";
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import {useUserData} from "../../../../../../../Hooks/TT_ELD/Users";
import {useVehicleSearch} from "../../../../../../../Hooks/TT_ELD/Logs";
import {users} from "../../../../../../../API/TT_ELD/Users/user";
import {usStates} from "../../../../../../../Utils/data";
import {Field, Form} from "react-final-form";


interface Params {
    [key: string]: string | undefined;
    id: string | undefined;
}
type Data = {
    data: any
    isLoading?: boolean,
    refetch(): void,
}
const dateFormat = 'YYYY-MM-DD';
const Ifta = () => {
    const [form] = FormAnt.useForm();
    const {id} = useParams<Params>();
    const { RangePicker } = DatePicker;
    const [loadings, setLoadings] = useState<boolean[]>([]);
    const [date, setDate] = useState(['2023-01-01','2023-03-31']);
    const [checked, setChecked] = useState<boolean | undefined>();
    const [checkedState, setCheckedState] = useState<boolean>();
    const [zeroVehicle, setZeroVehicle] = useState<boolean>(true);

    const enterLoading = (index: number) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });

        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };

    const vehicleData: any = useVehicleSearch("",  id);

    const onSubmit = async (values: any) => {
      let state = [...usStates].map((state:any) => state.id)
      let all_vehicles = [...vehicleData?.data?.data].map((state:any) => state.id)
      await users.newReportV3({vehicles: !checked ? values.vehicles : all_vehicles, state:!checkedState?  values.states: state, start_date:date[0], end_date: date[1]},zeroVehicle,  id)
        enterLoading(0)
    };

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked( e.target.checked);
    };
    const onChangeState = (e: CheckboxChangeEvent) => {
        setCheckedState( e.target.checked);
    };
    const onChangeZero = (e: CheckboxChangeEvent) => {
        setZeroVehicle( e.target.checked);
    };


    const handleChange = (event: boolean | CheckboxChangeEvent, inputName: string) => {
        const updateFunctions: Record<string, (value: boolean) => void> = {
            checked: setChecked,
            checkedState: setCheckedState,
            zeroVehicle: setZeroVehicle,
        };

        if (updateFunctions[inputName]) {
            // @ts-ignore
            updateFunctions(event.target.checked);
            // setQueryValue(`${inputName}-` +event  );
        }
    };
    return (
            <Card>
                <FormAnt
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ modifier: 'public' }}
                    onFinish={onSubmit}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{display: "flex"}}
                    >
                        <Row  gutter={16}>
                            <Col span={8}>
                                <FormAnt.Item
                                    name="date"
                                    label="Date range"
                                >
                                    <RangePicker clearIcon
                                        defaultValue={[dayjs('2023/01/01', dateFormat), dayjs('2023/03/31', dateFormat)]}
                                        onChange={(value:any) => {setDate(value.map((item:any) => {
                                            return dayjs(item).format(dateFormat)
                                        }))}}/>
                                </FormAnt.Item>
                            </Col>
                        </Row>
                        <Row  gutter={16}>
                            <Col span={8}>
                                <FormAnt.Item name="all_vehicles" valuePropName="checked" noStyle>
                                    <Checkbox onChange={onChange}>All Vehicles</Checkbox>
                                </FormAnt.Item>
                            </Col>
                        </Row>
                        {!checked && <Row  gutter={16}>
                            <Col span={8}>
                                <FormAnt.Item
                                    name="vehicles"
                                    label="Vehicles"
                                    rules={[{required: true, message: 'Please enter your vehicles!'}]}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%'}}
                                        placeholder="Please select"
                                        filterOption={(input, option: any) =>
                                            (option?.label ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={vehicleData?.data?.data?.map(
                                            (option: any) => ({
                                                value: option.id,
                                                label: option.truck_number,
                                            })
                                        )}
                                    />
                                </FormAnt.Item>
                                <Col span={8}>
                                    <FormAnt.Item name="zeroVehicle" valuePropName="checked" noStyle>
                                        <Checkbox defaultChecked={true} onChange={onChangeZero}>Skip Inactive Vehicles</Checkbox>
                                    </FormAnt.Item>
                                </Col>
                            </Col>
                        </Row>}

                        <Row style={{marginTop:'24px'}} gutter={16}>
                            <Col span={8}>
                                <FormAnt.Item name="all_states" valuePropName="checked" noStyle>
                                    <Checkbox onChange={onChangeState}>All States</Checkbox>
                                </FormAnt.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            { !checkedState && <Col span={8}>
                                <FormAnt.Item
                                    name="states"
                                    label="States"
                                    rules={[{required: true, message: 'Please enter your States!'}]}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%'}}
                                        placeholder="Please select"
                                        options={usStates.map(
                                            (option: any) => ({
                                                value: option.id,
                                                label: option.truck_number,
                                            })
                                        )}
                                    />
                                </FormAnt.Item>
                            </Col>}
                        </Row>


                        <FormAnt.Item>
                            <Button onClick={() => enterLoading(1)}
                                    loading={loadings[1]} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </FormAnt.Item>
                    </Space>
                </FormAnt>
            </Card>

    );
};

export default Ifta;