import React, {useContext, useState} from 'react';
import {Avatar, Button, Modal, Space, Spin, Table, Tag, Tooltip} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {fmcsa} from "../../../../../API/TT_ELD/fmcsa";
import FmcsaAllInfo from "./FMCSA_AllInfo";
import RefreshContext from "../../../../utils/context";
import {sendEvent} from "../../../../../Utils/analytics";
import LogoDark from "../../../../assets/logo/logoDark.svg";
import LogoLight from "../../../../assets/logo/logoZeelog.svg";
import EVO from "../../../../assets/logo/evodark.svg";
import {useSelector} from "react-redux";

const {confirm} = Modal;
type numStr = string | number

interface approvalSource {
    no: numStr,
    id: numStr
    img: {whichservice: string } ;
    driver: { first_name: number, second_name: numStr, id: numStr }
    company: { name: numStr, id: numStr }
    name: string
    origin: string
    type: string
    link: string
    filecode: string
    start_date: numStr
    end_date: numStr,
    createdAt: numStr,
    status: numStr,
    processedTime: numStr,
    submissionId: numStr,
    companyId: numStr,
    driverId: numStr,
    action: { id: numStr ,whichservice: string  };
    key: React.Key;
}

const FmcsaTable = ({data = [], onChange, total = 0, isLoading, isFetching, refetch}: {
    data: Array<any>, onChange(current: any): void, total: number | undefined, isLoading: any, isFetching: any, refetch(): void
}) => {

    const { refetchBtn, setRefetchBtn } = useContext(RefreshContext);
    const RefToggler = () => {
        setRefetchBtn(refetchBtn + 1);
        refetch()
    };
    const getRowClassName = (record: approvalSource, index: number) => {
        if (record?.type === 'output') {
            return 'fmcsa-color-ro'; // применить класс 'highlight-row' к строкам с возрастом более 30
        }
        return ''; // не применять класс для остальных строк
    };
    

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [getOneData, setGetOneData] = useState<approvalSource>();
    const { theme } = useSelector((state: any) => state.global);

    const handleCancel = (record: any) => {
        setGetOneData(record)
    }


    const fmcsaCount = localStorage.getItem('fmcsa_count');
    const columns: object[] = [
        {
            title: 'no',
            dataIndex: 'no',
            key: 'no',
            sorter: {
                compare: (a: any, b: any) => a.no - b.no,
                multiple: 1,
            },
        },
        // {
        //     title: 'Logo',
        //     dataIndex: 'img',
        //     key: 'img',
        //     width:50,
        //     render: (val :{whichservice: string | null}) => (
        //         <>
        //             {val.whichservice && <Tooltip title={val.whichservice === 'tteld' ? 'TT ELD' : 'EVO ELD'} placement="top">
        //                 <Avatar shape="square" size='small'
        //                         src={val.whichservice === 'tteld' ? (theme === 'light' ? LogoDark : LogoLight) : EVO}/>
        //             </Tooltip>}
        //         </>
        //
        //     ),
        // },
        {
            title: 'driver',
            dataIndex: 'driver',
            key: 'driver',
            render: ({first_name, second_name, id}: { first_name: numStr, second_name: numStr, id: numStr }) => {
                return (
                    <div key={id} style={{cursor: 'pointer'}}>{first_name + ' ' + second_name}</div>
                )
            }
        },
        {
            title: 'comment',
            dataIndex: 'filecode',
            key: 'filecode',


        }, {
            title: 'start_date',
            dataIndex: 'start_date',
            key: 'start_date',

        },
        {
            title: 'end_date',
            dataIndex: 'end_date',
            key: 'end_date',

        },
        {
            title: 'createdAt',
            dataIndex: 'createdAt',
            key: 'createdAt',

        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',

        }, {
            title: 'processedTime',
            dataIndex: 'processedTime',
            key: 'processedTime',

        },
        {
            title: 'submissionId',
            dataIndex: 'submissionId',
            key: 'submissionId',
        },
        {
            title: 'type',
            dataIndex: 'type',
            key: 'type',
            render: (tag: string) => (

                <Tag color={tag === 'outputfile' ? 'green' : 'geekblue'}>
                    {tag.toUpperCase()}
                </Tag>
            ),
            filters: [
                {
                    text: 'Outputfile',
                    value: 'outputfile',
                },
                {
                    text: 'Output',
                    value: 'output',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.type === value
            },
        },
        {
            title: 'view',
            dataIndex: 'view',
            key: 'view',
            render: () => {
                return (
                    <Button onClick={() => setIsModalVisible(true)}>View</Button>
                )
            }
        },
        {
            title: 'Verify',
            dataIndex: 'action',
            key: 'action',
            width: 200,
            fixed: 'right',
            render: ({id , whichservice}: { id: string | number , whichservice:string}) => {
                const showConfirm = async (status: 'accepted' | 'rejected') => {
                    confirm({
                        title: 'FMCSA',
                        icon: <ExclamationCircleFilled rev={undefined} />,
                        content: `Do you want to ${status === 'accepted' ? 'approve' : 'reject'} this FMCSA report ?`,
                        onOk: async () => {
                            try {
                                await new Promise(resolve => setTimeout(resolve, 1000));
                                localStorage.setItem('fmcsa_count', `${Number(fmcsaCount) - 1}`);
                                const patchData = { "status": status };
                                await fmcsa.fmcsaPatch(id, patchData);
                                // if(whichservice === 'zeelog'){
                                //     await fmcsa.fmcsaPatch(id, patchData);
                                // }else if(whichservice === 'evoeld'){
                                //     await fmcsa.fmcsaPatchEVO(id, patchData);
                                // }else {
                                //     await fmcsa.fmcsaPatch(id, patchData);
                                // }
                                sendEvent('Approval', `User ${status === 'accepted' ? 'approval' : 'rejected'}`, '', 1);
                                refetch();
                                RefToggler();
                            } catch (error) {
                                refetch();
                            }
                        },
                        onCancel() { },
                    });
                };
                return (
                    <Space>
                        <>
                            <Button type="primary" onClick={() => showConfirm('accepted')}>
                                Accept
                            </Button>
                            <Button type="primary" danger onClick={() => showConfirm('rejected')}>
                                Reject
                            </Button>
                        </>
                    </Space>
                )
            }

        },
    ]

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Button
                    style={{margin: '0 0 6px auto'}}
                    size={'large'} onClick={RefToggler}>Refresh</Button>
            </div>
            <Spin size='large' spinning={isLoading || isFetching}>
                <FmcsaAllInfo data={getOneData} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}/>
                <Table rowClassName={getRowClassName} onRow={(record: any) => {
                    return {
                        onClick: () => {
                            handleCancel(record)
                        },
                    };
                }} onChange={onChange} dataSource={data?.map((u: any, i: number): approvalSource => {
                    let create = u?.createdAt
                    const obj: approvalSource = {
                        no: i + 1,
                        img: {whichservice: u?.company?.whichservice} ,
                        id: u.id,
                        driver: {
                            first_name: u.driver?.first_name,
                            second_name: u.driver?.second_name,
                            id: u.driver?.id
                        },
                        company: {name: u.company.name, id: u.company?.id},
                        name: u.name,
                        origin: u.origin,
                        type: u.type,
                        link: u.link,
                        filecode: u?.filecode,
                        start_date: u.start_date,
                        end_date: u.end_date,
                        createdAt: u.createdAt,
                        driverId: u.driverId,
                        status: u.status,
                        processedTime: u.processedTime,
                        submissionId: u.submissionId,
                        companyId: u.companyId,
                        action: {id: u.id ,whichservice: u?.company?.whichservice },
                        key: u.id
                    }
                    return obj
                })} columns={columns} pagination={{total: total, showSizeChanger: false,}}

                />
            </Spin>
        </div>
    );
};

export default FmcsaTable;