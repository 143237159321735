import React, {useState} from 'react';
import {useCompaniesData} from "../../../../../Hooks/TT_ELD/Companies";
import CompaniesTable from "./CompaniesTable";
import {Button} from "antd";
import SearchInput from "../../../../../Utils/SearchInput";
import useRouteQuery from "../../../../../Utils/useRouterQuery";

type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any
}

const Companies = () => {
    const [companyId, setCompanyId] = useRouteQuery("", 'companyId', (v) => String(v));
    const [usdotId, setUsdotId] = useRouteQuery("", 'usdotId', (v) => String(v));

    const handleChange = (event:string, inputName:string) => {
        const updateFunctions: Record<string, (value: string) => void> = {
            companyId: setCompanyId,
            usdotId: setUsdotId,
        };

        if (updateFunctions[inputName]) {
            updateFunctions[inputName](event.trim());
        }
    };
    const [skip, setSkip] = useState<number>(1);
    const {data, refetch, isLoading, isFetching}: Data = useCompaniesData(companyId, usdotId, undefined, skip, '');
    const onChange = (query: any) => {setSkip(1 * (parseInt(query.current)))}
    return (
        <div className='mainPage'>
            <SearchInput value={companyId} placeholder="Company name Search"  onDebounceChange={(event:string) => handleChange(event, 'companyId')}  />
            <SearchInput value={usdotId} placeholder="Usdot Search" onDebounceChange={(event:string) => handleChange(event, 'usdotId')} />
            <Button style={{float: "right", marginLeft: '15px'}} size={'large'} onClick={refetch}>Refresh</Button>
            <CompaniesTable companiesData={data?.data} total={data?.count} onChange={onChange} isLoading={isLoading} isFetching={isFetching} skip={skip} refetch={refetch}/>
        </div>
    );
};

export default Companies;
