import React, {useEffect, useState} from 'react';
// @ts-ignore
import {Button, DatePicker, Form, GetProps, Input, Modal} from "antd";
import dayjs from "dayjs";
import {companies} from "../../../../../API/TT_ELD/Users/company";
import customParseFormat from "dayjs/plugin/customParseFormat";
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
dayjs.extend(customParseFormat);

const disabledDate: RangePickerProps['disabledDate'] = (current:any) => {
    return current && current < dayjs().endOf('day');
};
const ModalExpireCompanyDate : React.FC<any> = ({id ,  isModalOpen, setIsModalOpen, refetch}: { id:  any,  isModalOpen: boolean | undefined, setIsModalOpen(isModalOpen: boolean): void,refetch:any }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() =>{
        form.setFieldsValue({
            note: id?.note,
        });
    },[isModalOpen])
    const onReset = () => {
        form.resetFields();
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };
    const handleOk = async () => {
        try {
            setConfirmLoading(true);
            setTimeout(() => {
                setIsModalOpen(false);
                setConfirmLoading(false);
            }, 2000);
            const values = await form.validateFields();
            let dateString = values.expireCompanyDate.format('YYYY-MM-DD HH:mm:ss');
            await companies.companyPatchData({note : values.note, expireCompanyDate :dateString}, id.id)
            setIsModalOpen(false);
            refetch()
            onReset()
        }catch (error){}
    };
    return (
                <>
                     <Modal  title=" Select expiration date"
                            open={isModalOpen} onOk={handleOk}  confirmLoading={confirmLoading} onCancel={handleCancel}>
                        <Form
                            form={form}
                            layout='vertical'
                        >
                            <Form.Item<any>
                                label="Note"
                                name="note"
                                initialValue={id.note}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your note!',
                                        validator: async (_, value) => {
                                            const trimmedValue = value.trim();
                                            if (trimmedValue === '') {
                                                throw new Error('Please enter your note!');
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item label='Expire Company Date' name="expireCompanyDate"
                                       rules={[{required: true, message: 'Please select expiration date!'}]}>
                                <DatePicker
                                    showNow={false}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    disabledDate={disabledDate}
                                    showTime={{defaultValue: dayjs('00:00:00', 'HH:mm:ss')}}
                                    onChange={(date, dateString) => {}}
                                />
                            </Form.Item>

                        </Form>
                    </Modal>
                </>
    );
};

export default ModalExpireCompanyDate;