import React from 'react';
import {Button, Card, Input, Select, Space, Spin, Switch, Row, Col} from "antd";
import {Field} from "react-final-form";
import SelectTimezones from "../SelectTimezones";
import {useLocation, useParams} from "react-router-dom";
import {useCompanyData} from "../../../../../../../Hooks/TT_ELD/Companies";
import {Form} from "react-final-form";
import {sendEvent} from "../../../../../../../Utils/analytics";
import {companies} from "../../../../../../../API/TT_ELD/Users/company";

type params = {
    readonly id: any
}
type MyObjectType = {
    [key: string | number]: any;
};
const {Option} = Select;

const validate = (values:any) => {
    const errors:any = {};
    if (!values.host) {
    } else if (!values.host.startsWith('http://') && !values.host.startsWith('https://')) {
        errors.host = 'URL must start with http:// or https://';
    }
    if(values.expireCompanyDate?.trim() === ''){
        errors.expireCompanyDate = 'Please enter your  expireCompanyDate '
    }
    return errors;
};

const MainField = () => {
    const {id} = useParams<params>();
    const {data, refetch, isLoading, isFetching}: MyObjectType = useCompanyData(id);
    const location = useLocation();
    let disable = location.pathname === `/disabled-companies/${id}`

    const onSubmit = async (values: any) => {
        values = {
            ...values,
            chatId: values.chatId ? values.chatId : null,
            terminal_address: values.terminal_address ? values.terminal_address : null,
            address: values.address ? values.address : null,
            state: values.state ? values.state : null,
            post_code: values.post_code ? values.post_code : null,
            host: values.host ? values.host : null,
            disableCompanyDate: values.disableCompanyDate ? values.disableCompanyDate : null,
            ...(disable ? { expireCompanyDate: values.expireCompanyDate ? values.expireCompanyDate?.trim() : null } : {}),
            ...(disable ? { note: values.note ? values.note?.trim() : null } : {})
        }
        delete values.paymentMethodId;
        delete values.price;
        delete values.subscriptionId;
        delete values.quantity;
        const body: MyObjectType = {}
        let key: string | number
        let key2: string | number
        for (key in values) {
            if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] != null) {
                for (key2 in values[key]) {
                    if (typeof values[key][key2] === 'object' && !Array.isArray(values[key][key2]) && values[key][key2] != null) {
                        if (JSON.stringify(values[key][key2]) != JSON.stringify(data[key][key2])) {
                            body[key] = values[key]
                            break
                        }
                    } else if (data[key][key2] != values[key][key2]) {
                        body[key] = values[key]
                        break
                    }
                }
            } else if (data[key] != values[key]) {
                body[key] = values[key]
            }
        }
        sendEvent(`Company page`, `Company  Changed `, `Company  page Submit`, 1);
        companies.companyPatchData(body, id);
        refetch()
    };
    return (
        <Card>
            <Spin size="large" spinning={!data}>
                {data && <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={{
                        ...data
                    }}
                    render={({handleSubmit, values}) => (
                        <form onSubmit={handleSubmit}>
                            <Space direction="vertical" size="middle" style={{display: 'flex',}}>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <label>Name</label>
                                        <Field
                                            name="name"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="Name" type="text" {...input} />
                                            )}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <label>Usdot</label>
                                        <Field
                                            name="usdot"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="Usdot" type="text" {...input} />
                                            )}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <label>stripeCustomerId2</label>
                                        <Field
                                            name="stripeCustomerId2"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="stripeCustomerId2" type="text" {...input} />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <label>chatId</label>
                                        <Field
                                            name="chatId"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="chatId" type="text" {...input} />
                                            )}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <label>tz</label>
                                        <Field
                                            name="tz"
                                            initialValue={data?.tz}
                                            render={({input}: { input: any }) => (
                                                <SelectTimezones input={input}/>
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <label>phone</label>
                                        <Field
                                            name="phone"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="phone" type="text" {...input} />
                                            )}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <label>whichservice</label>
                                        <Field
                                            initialValue={data.whichservice}
                                            name="whichservice"
                                            render={({input}: { input: any }) => (
                                                <Select
                                                    disabled={true}
                                                    defaultValue={input.value}
                                                    onChange={(value, option) => {
                                                        input.onChange(value);
                                                    }}
                                                    style={{width: "100%"}}
                                                >
                                                    <Option value='zeelog'>Zeelog</Option>
                                                    <Option value='evoeld'>EVO ELD</Option>
                                                </Select>
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <label>terminal_address</label>
                                        <Field
                                            name="terminal_address"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="terminal_address" type="text" {...input} />
                                            )}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <label>address</label>
                                        <Field
                                            name="address"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="address" type="text" {...input} />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <label>state</label>
                                        <Field
                                            name="state"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="state" type="text" {...input} />
                                            )}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <label>post_code</label>
                                        <Field
                                            name="post_code"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="post_code" type="text" {...input} />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <label>ownerId</label>
                                        <Field
                                            name="ownerId"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="ownerId" type="text" {...input} />
                                            )}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <label>Host</label>
                                        <Field
                                            name="host"
                                            render={({input , meta}: { input: any , meta:any}) => (
                                                <div>
                                                    <Input placeholder="host" type="text" {...input} />
                                                    {(meta.error || meta.submitError) && meta.touched && (
                                                        <span style={{color: 'red'}}>{meta.error || meta.submitError}</span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    {disable && <Col span={8}>
                                        <label>disableCompanyDate</label>
                                        <Field
                                            name="disableCompanyDate"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="disableCompanyDate" type="text" {...input} />
                                            )}
                                        />
                                    </Col>}
                                    {disable && <Col span={8}>
                                        <label>expireCompanyDate</label>
                                        <Field
                                            name="expireCompanyDate"
                                            render={({input, meta}: { input: any , meta:any}) => (
                                                <>
                                                    <Input placeholder="expireCompanyDate" type="text" {...input} />
                                                    {(meta.error || meta.submitError) && meta.touched && (
                                                        <span style={{color: 'red'}}>{meta.error || meta.submitError}</span>
                                                    )}
                                                </>

                                            )}
                                        />
                                    </Col>}
                                </Row>
                                <Row gutter={[16, 16]}>
                                    {disable && <Col span={8}><label>note</label>
                                        <Field
                                            name="note"
                                            render={({input}: { input: any }) => (
                                                <Input placeholder="note" type="text" {...input} />
                                            )}
                                        />
                                    </Col>}
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col style={{display:'flex'}} span={8}>
                                        {/*<label>is_active</label>*/}
                                        <Field
                                            name="is_active"
                                            render={({input}: { input: any }) => (
                                                <div>
                                                    <Switch checked={input.value}  onChange={input.onChange}/>
                                                </div>
                                            )}
                                        />
                                        <label style={{marginLeft: '15px'}}>is_active</label>

                                    </Col>
                                    <Col style={{display:'flex'}} span={8}>
                                        <Field
                                            name="payForDriver"
                                            render={({input}: { input: any }) => (
                                                <div>
                                                    <Switch checked={input.value} onChange={input.onChange}/>
                                                </div>
                                            )}
                                        />
                                        <label style={{marginLeft: '15px'}}>payForDriver</label>

                                    </Col>
                                </Row>
                                    <Button style={{marginTop:'20px'}} type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                            </Space>
                        </form>
                    )}
                />}
            </Spin>
        </Card>
    );
};

export default MainField;