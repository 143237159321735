import React, { useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useFindOneLog} from "../../../../../../../Hooks/TT_ELD/Logs";
import {Button, Space, Tabs} from "antd";
import {Spin} from "antd/es";
import { Form} from "react-final-form";
import MainFields from "./MainFields";
import {getEvCodeType} from "../../../../../../../Utils/data";
import IdFields from "./IdFields";
import {logs} from "../../../../../../../API/TT_ELD/logs";
import Coordinates from "./Coordinates";

const { TabPane } = Tabs;
type MyObjectType = {
    [key: string | number]: any;
};

type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any
}



const LogEdit = () => {
    const [loading, setLoading] = useState<boolean>();
    let navigate = useNavigate();
    const { logId ,id } = useParams<{logId?: string , id?:string | any}>();
    const {pathname} = useLocation();
    const { data, refetch }: MyObjectType = useFindOneLog(logId ? parseInt(logId) : undefined, parseInt(id), pathname?.includes('logzeros') ? 'logzeros' : 'log');

    const [loadings, setLoadings] = useState<boolean[]>([]);
    const enterLoading = (index: number) => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = true;
        return newLoadings;
      });

      setTimeout(() => {
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[index] = false;
          return newLoadings;
        });
      }, 6000);
         
    };

    const onSubmit = async (values: any) => {
        setLoading(true)
        const status = getEvCodeType(values?.status)
        if(status) {
            // delete data.status;
            values.event_code = status?.event_code;
            values.event_type = status?.event_type;
        }
        values ={
            ...values,
            end_date: values.end_date ? values.end_date : null,

        }

        const body: MyObjectType = {}
        let key: string | number
        let key2: string | number
        for (key in values) {
            if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] != null) {
                for (key2 in values[key]) {
                    if (typeof values[key][key2] === 'object' && !Array.isArray(values[key][key2]) && values[key][key2] != null) {
                        if (JSON.stringify(values[key][key2]) != JSON.stringify(data[key][key2])) {
                            body[key] = values[key]
                            break
                        }
                    } else if (data[key][key2] != values[key][key2]) {
                        body[key] = values[key]
                        break
                    }
                }
            } else if (data[key] != values[key]) {
                body[key] = values[key]
            }
        }
        await logs.updateLogsEdit(body,logId , id, pathname?.includes('logzeros') ? 'logzeros' : 'log');
        setLoading(false)
        navigate(-1)
        refetch()
    };
    return (
        <div className='mainPage'>
            <Spin size="large" spinning={!data}>
                {data && <Form onSubmit={onSubmit}
                    initialValues={data}
                    render={({handleSubmit, values,form}) => (
                        <form onSubmit={handleSubmit}>
                            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                <Tabs defaultActiveKey="1">
                                    <TabPane
                                        tab={
                                            <span>
                                                MAIN FIELDS
                                            </span>
                                        }
                                        key="1"
                                    >
                                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                            <MainFields data={data} values={values} form={form}/>
                                        </Space>
                                    </TabPane>
                                    <TabPane
                                        tab={
                                            <span>
                                                ID FIELDS
                                            </span>
                                        }
                                        key="2"
                                    >
                                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                            <IdFields data={data}/>
                                        </Space>
                                    </TabPane>
                                    <TabPane
                                        tab={
                                            <span>
                                                COORDINATES
                                            </span>
                                        }
                                        key="3"
                                    >
                                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                            <Coordinates data={data}/>
                                        </Space>
                                    </TabPane>
                                </Tabs>
                            </Space>
                            <div className="buttons" style={{marginTop: '20px'}}>
                                <Button type="primary" htmlType="submit" loading={loadings[0]} onClick={() => enterLoading(0)}>
                                    Submit
                                </Button>
                            </div>
                            {/*<pre>{JSON.stringify(values, num, num2)}</pre>*/}

                        </form>
                    )}
                />}
            </Spin>
        </div>
    );
}

export default LogEdit;