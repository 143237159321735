import React, {useState} from 'react';
import {Button, Modal, Space, Spin, Table} from "antd";
import {Link} from "react-router-dom";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {modelList} from "../../../../../../API/TT_ELD/Users/make_moddel";


type numStr = string | number
const { confirm } = Modal;

interface modelSource {
    no: numStr,
    name: string
    port: string
    make: numStr
    makeId: numStr,
    action: { id: numStr };
    key: React.Key;
}

const ModelTable = ({
                        model = [],
                        total = 0,
                        onChange,
                        isLoading,
                        isFetching,
                        refetch
                    }: { model: Array<any> | undefined, total: number | undefined, onChange(current: any): void, isLoading: boolean | undefined, isFetching: boolean, refetch(): void }) => {
    const column: object[] = [
        {
            title: 'no',
            dataIndex: 'no',
            key: 'no',
        },

        {
            title: 'Model',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Make',
            dataIndex: 'make',
            key: 'make',

        },
        {
            title: 'port',
            dataIndex: 'port',
            key: 'port',

        },
        {
            title: 'makeId',
            dataIndex: 'makeId',
            key: 'makeId',

        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: ({ id }: { id: string | number }) => {
                const showConfirm = () => {
                    confirm({
                        title: 'Approval',
                        icon: <ExclamationCircleFilled rev={undefined} />,
                        content: 'Do you want to delete this model ?',
                        onOk: async () => {
                            return new Promise(async (resolve, reject) => {
                                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                                modelList.modelPatch(id, {"isActive": false})
                                refetch()

                            }).catch(() => {{refetch()}
                            });
                            },

                    });
                };
                return (
                    <Space>
                        <Link to={`${id}`}><Button>Edit</Button></Link>
                        <Button  onClick={showConfirm}>
                            Delete
                        </Button>
                    </Space>)
            }

        },

    ]
    return (
        <div>
            <Spin size='large' spinning={isLoading || isFetching}>
                <Table onChange={onChange} dataSource={model?.map((u: any, i: number): modelSource => {
                    const obj: modelSource = {
                        no: i + 1,
                        name: u?.name,
                        port: u?.port,
                        make: u.make?.name,
                        makeId: u.makeId,
                        action: {id: u.id},
                        key: u.id
                    }
                    return obj
                })} columns={column} pagination={{total: total, showSizeChanger: false,}}/>
            </Spin>
        </div>
    );
};

export default ModelTable;