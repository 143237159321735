import React, {useState} from 'react';
import {Button, Modal, Space, Spin, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {cityController} from "../../../../../API/Tms/cityController";
import {useQueryClient} from "react-query";
import {useCityData} from "../../../../../Hooks/cleverTms/CitiyController";

type numStr = number | string;

interface cotrollerSource {
    no: numStr;
    city: string;
    state: string;
    zips: string;
    timezone:string;
    updatedAt: string,
    createdAt: string,
    isActive: boolean;
    action: { id: numStr, queryClient: any,};
    key: React.Key;
}


const CityControllerTable = ({
                                 city = [],
                                 total = 0,
                                 onChange
                             }: { city: Array<any> | undefined, total: number | undefined, onChange(current: any): void }) => {
    const queryClient = useQueryClient();
    const filterData = (city:any) =>
        city?.map((item:any) => ({
            key: item,
            value: item,
            text: item
        }));
    const columns: object[] = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            sorter: {
                compare: (a:any, b:any) => a.no - b.no,
                multiple: 3,
            },
        },
        {
            title: 'city',
            dataIndex: 'city',
            key: 'city',
            sorter: (a:any, b:any) => a.city.length - b.city.length,

        }, {
            title: 'state',
            dataIndex: 'state',
            key: 'state',
            filters: filterData(
                city?.map((item) => item.state)
                    .filter((value, index, self) => self.indexOf(value) === index)
            ),
            onFilter: (value:any, record:any) => record.state.indexOf(value) === 0,
            sorter: (a:any, b:any) => a.state.length - b.state.length

        },{
            title: 'timezone',
            dataIndex: 'timezone',
            key: 'timezone',

        },{
            title: 'zips',
            dataIndex: 'zips',
            key: 'zips',
            sorter: (a:any, b:any) => a.zips.length - b.zips.length,

        },{
            title: 'updatedAt',
            dataIndex: 'updatedAt',
            key: 'updatedAt',

        },{
            title: 'createdAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a:any, b:any) => a.createdAt.length - b.createdAt.length,

        },
        {
            title: 'Is Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (tag: boolean) => (
                <Tag color={tag ? 'geekblue' : 'red'}>
                    {tag ? 'True' : 'False'}
                </Tag>
            ),
            filters: [
                {
                    text: 'True',
                    value: true,
                },
                {
                    text: 'False',
                    value: false,
                },

            ],
            onFilter: (value: any, record: any) => {
                return record.isActive === value
            },
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: ({id, queryClient} : {id: string | number,queryClient:any }) =>
                <Space>
                    <Link to={`${id}`}><Button>Edit</Button></Link>
                    <Button  onClick={() => {
                        Modal.confirm({
                                title: 'LoadController',
                                icon: <ExclamationCircleOutlined rev={undefined}/>,
                                content: 'Do you want to delete this load ?',
                                okText: 'Ok',
                                cancelText: 'cancel',
                                onOk: async () => {
                                    await cityController.deleteCityController(id)
                                    queryClient.refetchQueries(useCityData);
                                }
                            }
                        );
                    }
                    }
                    >Delete
                    </Button>
                </Space>

        },
    ]
    return (
        <div>
            <Spin size='large' spinning={!city?.length}>


                <Table onChange={onChange} dataSource={city?.map((u: any, i: number): cotrollerSource => {
                    const obj: cotrollerSource = {
                        no: i + 1,
                        city: u.city,
                        state: u.state,
                        timezone :u.timezone,
                        zips: u.zips,
                        updatedAt: u.updatedAt,
                        createdAt: u.createdAt,
                        isActive: u.isActive,
                        action: {id: u.id, queryClient},
                        key: u.id
                    }
                    return obj
                })} columns={columns} pagination={{total: total, pageSizeOptions: [10,50,100]}}

                />
            </Spin>
        </div>
    );
};

export default CityControllerTable;