import React from 'react'
import {Button, Modal, Space, Spin, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {categoryController} from '../../../../../API/Tms/category';
import {useCategoryData} from '../../../../../Hooks/cleverTms/Categoty';
import {useQueryClient} from 'react-query';
import moment from 'moment';


type numStr = string | number

interface categorySource {
    no: numStr,
    name: numStr,
    type: numStr,
    updatedAt: numStr,
    createdAt: numStr,
    isActive: boolean;
    companyId: numStr
    action: { id: numStr, queryClient: any, };
    key: React.Key;

}

const columns: object[] = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        sorter: {
            compare: (a: any, b: any) => a.no - b.no,
            multiple: 3,
        },
    },
    {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name.length - b.name.length,

    }, {
        title: 'type',
        dataIndex: 'type',
        key: 'type',
        filters: [
            {text: 'driver', value: 'driver',}, {text: 'broker', value: 'broker',},
        ],
        onFilter: (value: any, record: any) => record.type.indexOf(value) === 0,
        sorter: (a: any, b: any) => a.type.length - b.type.length,

    }, {
        title: 'updatedAt',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: (a: any, b: any) => a.updatedAt.length - b.updatedAt.length,

    }, {
        title: 'createdAt',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: 'companyId',
        dataIndex: 'companyId',
        key: 'companyId',
        sorter: {
            compare: (a: any, b: any) => a.companyId - b.companyId,
            multiple: 3,
        },
    },
    {
        title: 'Is Active',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (tag: boolean) => (
            <Tag color={tag ? 'geekblue' : 'red'}>
                {tag ? 'True' : 'False'}
            </Tag>
        ),
        filters: [
            {
                text: 'True',
                value: true,
            },
            {
                text: 'False',
                value: false,
            },

        ],
        onFilter: (value: any, record: any) => {
            return record.isActive === value

        },
    },
    {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        render: ({id, queryClient}: { id: string | number, queryClient: any }) =>
            <Space>
                <Link to={`${id}`}><Button>Edit</Button></Link>
                <Button  onClick={() => {
                    Modal.confirm({
                            title: 'LoadController',
                            icon: <ExclamationCircleOutlined rev={undefined}/>,
                            content: 'Do you want to delete this load ?',
                            okText: 'Ok',
                            cancelText: 'cancel',
                            onOk: async () => {
                                await categoryController.deleteCategoryController(id)
                                queryClient.refetchQueries(useCategoryData);

                            }
                        }
                    );
                }
                }
                >Delete
                </Button>
            </Space>

    },

]
const CategoryTable = ({
                           category = [],
                           total = 0,
                           onChange,
                           isLoading,
                           isFetching
                       }: { category: Array<any> | undefined, total: number | undefined, onChange(current: any): void, isLoading: boolean | undefined, isFetching: any }) => {
    const queryClient = useQueryClient();
    return (
        <div>

            <Spin size='large' spinning={isLoading || isFetching}>
                <Table onChange={onChange} dataSource={category?.map((u: any, i: number): categorySource => {
                    let create = u.updatedAt
                    const obj: categorySource = {
                        no: i + 1,
                        name: u.name,
                        type: u.type,
                        updatedAt: create ? moment(create).format("YYYY-MM-DD, h:mm:ss a") : "",
                        createdAt: u.creatdeAt,
                        companyId: u.companyId,
                        isActive: u.isActive,
                        action: {id: u.id, queryClient},
                        key: u.id
                    }
                    return obj
                })} columns={columns} pagination={{total: total, pageSizeOptions: [10, 50, 100]}}

                />
            </Spin>
        </div>
    )
}

export default CategoryTable